import React from 'react'
import { Link } from 'react-router-dom';
import {  withApollo } from 'react-apollo';
import { Button,Spin, Table,Modal, Tabs } from 'antd';
import App from '../../App'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import ChargesModal from './editCharges'
import OneTimeSerList from './oneTimeServiceList'

const { TabPane } = Tabs;

class ServiceList extends React.Component{
    constructor(props) {
    super(props)
    this.state={
        serviceData: [],
        loading: true,
        visible: false,
        modalData: {}
    }
    }
    componentDidMount(){
        this.runQuery()
    }
    runQuery=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              this.setState({
                serviceData: data.getSiteConfigServices,
                  loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    handleCancel=()=>{
        this.setState({
            visible: false
        })
    }
    edit=(data)=>{
        console.log("Data InEdit ::",data)
        this.setState({
            modalData: data,
            visible: true
        })
    }
    render(){
        const columns = [
            {
              title: 'Service Name',
              dataIndex: 'serviceName',
              key: 'typeName',
            },{
                title: 'Action',
                render: (data) => {
                    // console.log("data ::",data)
                      return (
                          <span >
                            <Button  type= "danger" onClick={()=>{this.edit(data)}} style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000 ", color:"#fff"}}>Edit</Button>
                          </span>
                      )
              }
            }
          ];
        return(
            <App header={'Service list'}>
                <p style={{ textAlign: 'end' }}>
                            <Link to="/create-service">
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                Recurring Service
                                </Button>
                            </Link>
                        {/* </p> */}
                {/* <p style={{ textAlign: 'end' }}> */}
                            <Link to="/create-extra-service">
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                One Time Service
                                </Button>
                            </Link>
                        </p>
                        {this.state.loading ? <>
                            <div style={{textAlign:"center"}}>
                                <Spin size="large"/>
                            </div>
                        </>:
                        <>
                        <Tabs onChange={this.callback} type="card">
                        <TabPane tab="Recurring Service" key="Recurring Service">
                        <Table
                        bordered
                        dataSource={this.state.serviceData}
                        columns={columns}
                        />
                        </TabPane>
                        <TabPane tab="One Time Service" key="One Time Service">
                        <OneTimeSerList />

                        </TabPane>
                        </Tabs>

                        <Modal
                            title="Charges Details"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={false}
                            width={800}
                        >
                        <ChargesModal data={this.state.modalData} onCancel={this.handleCancel}/>
                        </Modal>
                        </>
                }
            </App>
        )
    }
}
export default withApollo(ServiceList)