import getSiteActionEventsFilter from '../queries/getSiteActionEventsFilter'

const SiteActionEventQuery=(props,variable)=>{
    return new Promise((resolve,reject)=>{
            props.client.query({
                query: getSiteActionEventsFilter,
                variables: variable,
                fetchPolicy: 'no-cache'
            }).then(({ data }) => {
                resolve(data)
            })
        })
}
export default SiteActionEventQuery