import gql from 'graphql-tag'

export default gql`
query getSitesBySiteTypeAndOption($siteType: String! $siteOption: String! $limit: Int $nextToken: String){
    getSitesBySiteTypeAndOption(siteType: $siteType siteOption: $siteOption limit: $limit nextToken: $nextToken){
      items{
          id
        siteId
        typeValue
        domain
        fqdn
        subdomain
        internalName
        enabled
        siteType
        siteOptions
        title
  
        no_of_products
        no_of_photos
        custName
        companyName
        gstNo
        fromDate
        tillDate
        createdAt 
        courierOption
        courierOptions
        loggedInAt
        lastAccessAt
        userName
        groupId
        isGroupAdmin
        partnerType
        salesPerson
        supportAccManager
        channelPartner
        channelPartnerId
        stockistName
        isDemoSite
        customerLang
        basicSiteSetting{
          contactNumber
          contactEmail
          curr
          footerAddress {
            addressLineOne
            addressLineTwo
            city
            state
            zip
            country
          }
        }
        planDetails{
          planName
        }
        siteRecurringConfig{
          domain
          typeValue
          siteId
          siteServices{
            serviceName
            chargingModel
            charges
            status
            masterStatus
            startedAt
            updatedAt
            startDate
            endDate
          }
          enabled
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`