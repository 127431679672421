import gql from 'graphql-tag'

export default gql`
query getSiteExtraServicesBySiteId( $siteId: String! $limit: Int $nextToken: String){
    getSiteExtraServicesBySiteId(siteId: $siteId limit: $limit nextToken: $nextToken){
      items{
        id
        siteId
        sId
        amt
        currency
        serviceName
        note
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }`

//    {
//        "siteId": "5a50475a-ee0e-4582-a2d1-fdec2112f303",
//        "limit": 20,
//        "nextToken": null
//     }