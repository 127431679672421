import gql from 'graphql-tag'
export default gql`
query {
    getGroupAdmins{
      id
      groupId
      typeValue
      email
      mobile
      userName
      custName
      companyName
      enabled
      address{
        city
        country
      }
    }
  }
  `