import gql from 'graphql-tag';

export default gql`
mutation updateSiteVersion( $domain: String! $appVersion: String!) {
    updateSiteVersion(domain: $domain appVersion: $appVersion)
  }
  
`;
//  {
    //    "domain": "ajay2606.recaho.com",
    //    "appVersion": "v1"
    //  }
    
    