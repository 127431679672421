import gql from 'graphql-tag';

export default gql`
query getSitePagesBySiteId{
    getSitePagesBySiteId{
      items{
        id
        siteId
        pageName
        value
        onFooter
        onHome
        pageFor
        pageType
        tags
        image{
          key
          bucket
          region
        }
      }
      nextToken
    }
  }
`