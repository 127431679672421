import React from 'react'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import {Button, Col, Form, Input, Row, Select, message} from 'antd'
import { compose, graphql } from 'react-apollo';

import App from '../../App'
import CampaignEmail from '../../../src/mutation/sendEmailCampaign'
import CampaignEmailTemplate from '../../../src/mutation/createEmailTemp'
import FroalaEditor from 'react-froala-wysiwyg';
import UserTab from '../EmailCampaign/index'
import getEmailTemplate from '../../../src/queries/getEmailCamp'
import { withApollo } from 'react-apollo';

let userData = []
const FormItem = Form.Item
class CustomerEmail extends React.Component{
    constructor(props){
        super(props)
        this.state={
            modelNew: '',
            showComp:false,
            subject:'',
            customerData:[],
            loading:false,
            enquiryData:[],
            tempInput:'',
            saveTemp:false,
            tempOptions:[],
            emailData:[],
            disabledInput:false,
            error:false,
            keys:[],
            enqKey:[],
            check:false,
            selectVal:"",
        }
    }

    handleModelChange = modelNew => {
        this.setState({ modelNew: modelNew });
        // this.props.updateState(this.state.modelNew);
    };

    handleClick = ()=>{
        this.setState({showComp:true})
    }

    enterLoading = ()=>{
        this.setState({loading:true})
    }

    componentDidMount(){
        this.props.client.query({
            query:getEmailTemplate,
            fetchPolicy:"network-only"
        }).then(({data})=>{
            let tempName = data.getEmailCampaignTemplates.map((val)=>val.tempName)
            this.setState({tempOptions:tempName,emailData:data.getEmailCampaignTemplates})
        })
    }

    message = ()=>{
        this.props.history.push('/customer-list')
    }

    handleEmail = () =>{
        this.props.form.validateFields((err,values)=>{
          
             userData= this.state.customerData
            //  return false
            if(userData.length != 0){
                for(let i=0 ;i<=userData.length-1; i++){
                    this.enterLoading()
                    this.props.campaignEmail({
                        variables:{
                            email:userData[i].contactEmail,
                            subject:this.state.subject,
                            htmlText:`<p>Hello ${userData[i].custName != null ? userData[i].custName : ''},</p>${this.state.modelNew}`,
                        }
                    }).then(({data})=>{
                        if(data.sendCampaignEmailToUserFromRecaho){
                            message.success("Mail has been sent successfully",this.message);
                            this.setState({loading:false})
                        }
                    }).catch(res=>{
                        console.log('Error',res)
                    })
                }
            }
            // return false
        })
    }

    handleCustomerData = (key,data)=>{
        this.setState({keys:key,customerData:data})
    }

    handleEnquiryData = (key,data) =>{
        this.setState({enqKey:key,enquiryData:data})
    }

    previous = () =>{
        this.setState({showComp:false})
    }

    handleChange = (e)=>{
        this.setState({subject:e.target.value})
    }

    handleSave = ()=>{
        this.setState({saveTemp:true})
    }

    // handleTemp = (e)=>{
    //     this.setState({tempName:e.target.value})
    // }

    saveTemplate = ()=>{
        this.props.form.validateFields((err,values)=>{
                if((this.state.check || this.state.selectVal == "" ) && this.state.tempOptions.includes(values.templateName)){
                this.props.form.setFields({
                    templateName:{
                        value:values.templateName,
                        errors:[new Error("Template name already exists.")]
                    }
                })
                return true
                }else{
                    this.props.form.setFields({
                        templateName:{
                            value:values.templateName,
                            errors:''
                        }   
                    })
                }
        // return false
                if(!err){
                    this.enterLoading()
                    this.props.campaignEmailTemplate({
                        variables:{
                            tempName:values.templateName,
                            tempSubject:this.state.subject,
                            text:this.state.modelNew,
                        }
                    }).then(({data})=>{
                        if(data.createEmailCampaignTemplate.id){
                            message.success("Template Saved");
                            this.getData()
                            this.setState({loading:false,error:false})
                        }
                    }).catch(res=>{
                        console.log('Error',res)
                    })
                }
        })
    }

    getData = async() =>{
        await this.props.client.query({
            query:getEmailTemplate,
            fetchPolicy:"network-only"
        }).then(({data})=>{
            let tempName = data.getEmailCampaignTemplates.map((val)=>val.tempName)
            this.setState({tempOptions:tempName,emailData:data.getEmailCampaignTemplates})
        })
    }

    selectOption = (val)=>{
        let tempData =[]
        if(val == 'none'){
            this.setState({subject:'',modelNew:'',saveTemp:false,tempInput:'',disabledInput:false,check:true})
        }else{
            this.state.emailData.forEach((v)=>{
                if(v.tempName == val){
                    tempData.push(v)
                }
            })
            this.setState({selectVal:val,subject:tempData[0].tempSubject,modelNew:tempData[0].text,saveTemp:true,tempInput:tempData[0].tempName,disabledInput:true})
            this.props.form.setFieldsValue({templateName:tempData[0].tempName})
        }
    }

    config = {
        key:process.env.REACT_APP_froalaKey,
        height:200,
        imageUploadToS3:this.props.location.state.modalData,
        // toolbarButtonsXS: ['undo', 'redo' , 'bold', 'italic', 'underline','strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html','insertImage','insertLink','insertVideo','uploadFile','Emoticons','special characters','code view','Print','Help','Quote','Font Family','Font Size','colors','Inline class','inline style','paragraph style','Line Height','paragraph format']
        toolbarButtons: {
            'moreText': {
              'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
              'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
            },
            'moreRich': {
              'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
            },
            'moreMisc': {
              'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help']
            }
          },
    };

    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        if(this.state.showComp){
            return (
                <App header={'Email Campaign'}>
                    <UserTab custData={this.state.customerData} enqData={this.state.enquiryData} keys={this.state.keys} enqKey={this.state.enqKey} showComp={this.state.showComp} loading={this.state.loading} handleEmail={this.handleEmail} previous={this.previous} customerData={this.handleCustomerData} />
                </App>
            )
        }else{
            return(
                <App header={'Email Campaign'}>
                <Form>
                    <Row>
                        <Col span={8}>
                            <FormItem label="Template Name">
                            <Select 
                            placeholder="Select Template"
                            style={{ width: '70%' }}
                            onChange={this.selectOption}
                            >
                            {this.state.tempOptions.map((val)=>{
                                return(
                                    <Select.Option value={val} key='opt1'>{val}</Select.Option>
                                )
                            })}
                            <Select.Option value='none' key='opt2'>New</Select.Option>
                                {/* <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="disabled" disabled>Disabled</Option>
                                <Option value="Yiminghe">yiminghe</Option> */}
                            </Select>
                            </FormItem>
                        </Col>
                        <Col span={1} offset={15}>
                            <Button style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}} disabled={this.state.subject == "" || this.state.modelNew == "" ? true : false} onClick={this.handleClick}>Next</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14}>
                            <FormItem label="Subject">
                                <Input  placeholder="Subject"  value={this.state.subject} onChange={this.handleChange} style={{ width: '100%' }} />
                            </FormItem>
                        </Col>
                       
                    </Row>
                    <Row>
                       <FroalaEditor
                            tag="textarea"
                            config={this.config}
                            videoResponsive={true}
                            onModelChange={this.handleModelChange}
                            model={this.state.modelNew}
                        />
                      </Row>
                    <Row>
                      {this.state.saveTemp && 
                     <Col span={8}>
                        <FormItem label="Enter Template Name">
                        {getFieldDecorator('templateName', {
                          validateTrigger: ['onChange', 'onBlur'],
                          initialValue:this.state.tempInput,
                          //  rules: [{
                          //     // whitespace:true
                          //      required: true,
                          //      message: "Please input Page Content ",
                          //  }],
                        })(
                            <Input  placeholder="Template Name" disabled={this.state.disabledInput} style={{ width: '100%' }} />
                         )} 
                        </FormItem>
                    </Col>
                        }
                      {this.state.saveTemp ?
                        <Col span={8}>
                            <Button style={{ marginLeft:"8px",marginTop:"40px",  background:"#389e0d", color:"#fff"}} loading={this.state.loading} disabled={this.state.error ? true : false} onClick={this.saveTemplate}>Save</Button>
                        </Col>:
                        <Col span={8}>
                            <Button style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}} disabled={this.state.subject == "" || this.state.modelNew == "" ? true : false} onClick={this.handleSave}>Save Template</Button>
                        </Col>}
                    </Row>
                </Form>
                </App>
            )
        }
    }
}
const wrapCustomerEmail = Form.create()(CustomerEmail)
const wrapEmail = compose(
    graphql(CampaignEmail,{
        name:'campaignEmail'
    }),
    graphql(CampaignEmailTemplate,{
        name:'campaignEmailTemplate'
    }),
)(wrapCustomerEmail)
export default withApollo(wrapEmail)