import gql from 'graphql-tag'

export default gql`

mutation deleteSitePage($pageName: String!){
    deleteSitePage(pageName: $pageName){
      id
      siteId
      pageName
      value
    }
  }
  

`

  