import gql from 'graphql-tag'

export default gql`
mutation createInternalUser(
    $siteId: String!
    $userName: String!
    $password: String!
    $role: String!
    $emailId: String!
    $mobile: String!
    $name: String
    $enabled: Boolean
    $permissions: [UserPermissionsInput]
    $address: GenericAddressInput
    $companyName: String
    $gstNo: String
    $panNo: String
    $onboardedBy: String
    $supportNumber: String
    $empsDetails: [EmpsDetailInput!]
){
  createInternalUser(input:{
    siteId: $siteId
    userName: $userName
    password: $password
    role: $role
    emailId: $emailId
    mobile: $mobile
    name: $name
    enabled: $enabled
    permissions: $permissions
    address: $address
    companyName: $companyName
    gstNo: $gstNo
    panNo: $panNo
    onboardedBy: $onboardedBy
    supportNumber: $supportNumber
    empsDetails: $empsDetails
  }){
    siteId
    username
    userRole
    emailId
    mobile
    name
    enabled
    createdAt
    permissions{
      name
      operations
    }
    supportNumber
    empsDetails{
      name 
      mobile
      email
      designated
    }
  }
}


`
//  {
//        "siteId": "master",
//        "userName": "internal1",
//        "password": "Temp@123",
//        "role": "ACCOUNTANT",
//        "emailId": "sohelkhan@amonex.in",
//        "mobile": "8459832342",
//        "name": "Sohel",
//        "enabled": true,
//        "permissions":[{
//          "name": "test",
//          "operations": ["fs","fdsf"]
//        }]
//      }

