import gql from 'graphql-tag'

export default gql`
query getInvoiceFormatV2($domain: String! $typeValue: String!){
    getInvoiceFormatV2(domain: $domain typeValue: $typeValue){
      domain
      typeValue
      companyName
      companyLogo{
        bucket
        region
        key
      }
      address
      email
      mobile
      gst
      custKOTPrint
      printOperatorInfo
      custName
      custMobile
      editCustDetails
      siteURL
      footerMessage
      token
      custAddress
      isPayQR
      upiId
      invoiceOptionOnBill
      paytmQrOptions
      itemInstructionOnBill
      qrcode{
        bucket
        region
        key
      }
      paymentMode
      fieldDetails{
        fieldName
        value
        isDisplay
        font
        fontSize
        image{
          bucket
          key
          region
        }
      }
    }
  }`
//   {
//       "typeValue": "invoiceformat::dine-in"
//     }
