import React, {useEffect, useState} from "react";
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  message,
  Select,
} from "antd";

import updateCustomWhatsappConfig from '../../mutation/updateCustomWhatsappConfig'
import getCustomWhatsappConfig from '../../queries/getCustomWhatsappConfig'

const FormItem = Form.Item;

function WhatsAppConfigModal(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  // const [config, setConfig] = useState({});
  const [payload, setPayload] = useState({})

  const handleSubmit = event => {
    event.preventDefault();
    props.form.validateFields(
      ["system", "phoneId", "productId", "token", "ApiKey"],
      (err, values) => {
        if (!err) {
          // console.log("values:::", values);
          let payload = {
            domain: props.domain
          };
          setLoading(true)

          if (selectedOption == "MAYTAPI") {
            payload = {
              productId: values.productId,
              phoneId: values.phoneId,
              token: values.token,
              apiKey: null,
              system: "MAYTAPI",
              ...payload
            };
          } else {
            payload = {
              apiKey: values.ApiKey,
              system: "BHARATTEXT",
              productId: null,
              phoneId: null,
              token: null,
              ...payload
            };
          }

          try {
            props.client.mutate({
              mutation: updateCustomWhatsappConfig,
              variables: payload
            }).then(({ data }) => {
              // console.log("payload:::", payload);
              message.success('Settings Successfully Updated');
              setPayload(payload)
              props.handleWhatsAppConfigClose(false)
              setLoading(false);
            })
              .catch(res => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
              });
          } catch (e) {
            console.log('Catch Error', e)
          }

          // console.log("PayLoad:::", payload);

        } else {
          console.log("Err :::", err);
        }
      }
    );
  };

  const handleMenuClick = e => {
    setSelectedOption(e);
  };

  const getWhatsAppConfig = () => {
    // console.log("props.domain ::;", props.domain)
    props.client.query({
      query: getCustomWhatsappConfig,
      fetchPolicy: 'network-only',
      variables: {
        domain: props.domain
      }
    })
      .then(({ data }) => {
        // console.log("DataWhatsApp:::",data)
        if(data.getCustomWhatsappConfig){
          setPayload(data.getCustomWhatsappConfig)
          setSelectedOption(data.getCustomWhatsappConfig.system)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(`Error : ${JSON.stringify(err)}`)
      })
  }

  useEffect(() => {
    getWhatsAppConfig()
  }, [])

  const {getFieldDecorator} = props.form;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label="Select System">
              {getFieldDecorator("system", {
                initialValue: payload.system ? payload.system : undefined,
                rules: [
                  {
                    required: true,
                    message: "Please select system",
                  },
                ],
              })(
                <Select
                  style={{width: "100%"}}
                  placeholder="Select System"
                  onChange={handleMenuClick}
                >
                  <Select.Option key="MAYTAPI" value="MAYTAPI">
                    MAYTAPI
                  </Select.Option>
                  <Select.Option key="BHARATTEXT" value="BHARATTEXT">
                    BHARATTEXT
                  </Select.Option>
                  <Select.Option key="RECAHO" value="RECAHO">
                    RECAHO
                  </Select.Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        {selectedOption == "MAYTAPI" && (
          <Row gutter={16}>
            <Col spam={24}>
              <FormItem label="Phone Id">
                {getFieldDecorator("phoneId", {
                  initialValue: payload ? payload.phoneId : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter phoneId",
                    },
                  ],
                })(<Input placeholder="Phone Id" style={{width: "100%"}} />)}
              </FormItem>
            </Col>
            <Col spam={24}>
              <FormItem label="Product Id">
                {getFieldDecorator("productId", {
                  initialValue: payload ? payload.productId : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter Product Id",
                    },
                  ],
                })(<Input placeholder="Product Id" style={{width: "100%"}} />)}
              </FormItem>
            </Col>
            <Col spam={24}>
              <FormItem label="Token">
                {getFieldDecorator("token", {
                  initialValue: payload ? payload.token : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter Token",
                    },
                  ],
                })(<Input placeholder="Token" style={{width: "100%"}} />)}
              </FormItem>
            </Col>
          </Row>
        )}
        {(selectedOption == "BHARATTEXT" || selectedOption == "RECAHO")&& (
          <Row gutter={16}>
            <Col spam={24}>
              <FormItem label="Api Key">
                {getFieldDecorator("ApiKey", {
                  initialValue: payload ? payload.apiKey : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter ApiKey",
                    },
                  ],
                })(<Input placeholder="Api Key" style={{width: "100%"}} />)}
              </FormItem>
            </Col>
          </Row>
        )}
        <Button
          type="primary"
          style={{
            background: "#389e0d",
            color: "#fff",
            display: "block",
            marginLeft: "auto",
          }}
          loading={loading}
          htmlType="submit"
        >
          SAVE
        </Button>
      </Form>
    </>
  );
}

const WrapWhatsAppConfigModal = Form.create()(WhatsAppConfigModal);

export default WrapWhatsAppConfigModal;
