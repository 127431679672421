import gql from 'graphql-tag'
export default gql`
query {
    getInternalUsers{
      id
      siteId
      username
      userRole
      emailId
      mobile
      name
      enabled
      createdAt
      gstNo
      panNo
      onboardedBy
      companyName
      address{
        addressLineOne 
        addressLineTwo
        city
        state
        zip
        country
      }
      empsDetails{
        name 
        mobile
        email
        designated
      }
      supportNumber
      permissions{
        name
        operations
      }
    }
  }
  `