import gql from "graphql-tag";
export default gql`
  mutation storeRegisterONDC($payload: String!) {
    storeRegisterONDC(payload: $payload)
  }
`;

//  {
//    "siteId": "43b035b0-0aa0-4a17-a8d8-0c51069f2b74",
//    "amt": 300,
//    "currency": "INR",
//    "rechargeBy": "ADMIN",
//    "paymentState": "PAID_OFFLINE"
//  }
