const  externalPermissions={
    'site':[{
        label: "View Site List", 
        value: "view_site_list"
    },
    {
        label: "Create New Site", 
        value: "create_new_site"
    },
    {
        label: "Delete Site", 
        value: "delete_site"
    },
    {
        label: "Wallet Recharge", 
        value: "wallet_recharge"
    },
    {
        label: "Edit Site(Basic Details)", 
        value: "edit_site"
    }],
    'editSite':[{
        label: "Online Payment", 
        value: "online_Payment"
    },
    {
        label: "Payment Details", 
        value: "wallet_settings" 
    },
    {
        label: "Payment Modes", 
        value: "payment_modes" 
    },
    {
        label: "Premium Feature", 
        value: "premium_feature" 
    }],
    'staticPage':[{
        label: "Static Pages", 
        value: "static_pages" 
    },
    { 
        label:'Create New Page',
        value:'create_new_page'
    },
    { 
        label:'Edit Page',
        value:'edit_page'
    },
    { 
        label:'Delete Page',
        value:'delete_page'
    }],
    'manageMenu':[{
        label: "View Menu", 
        value: "view_Menu"
        },
        {
        label:'Add Menu',
        value:'add_menu'
        },
        { 
        label:'Add Labels',
        value:'add_labels'
        },
        {
        label:'Delete Service & Category',
        value:'deleteServiceCategory'  
        }],
    'globalTemplates':[{
        label: 'View Global Templates',
        value: 'view_global_templates'
        },
        {
        label: 'Create Global Templates',
        value: 'create_global_templates'  
        },
        {
        label: 'Edit Global Templates',
        value: 'edit_global_templates'  
        },
        {
        label: 'Delete Global Templates',
        value: 'delete_global_templates'  
        }],
    'campaigns':[{
        label: 'Run Campaigns',
        value: 'run_campaigns'
        },
        {
        label: 'Campaigns Templates',
        value: 'campaigns_templates'  
        }],
    'onboarding_dashboard':[{
        label: 'View Onboarding Dashboard',
        value: 'onboarding_dashboard'  
    }] ,
    'renewal_dashboard':[{
        label: 'View Renewal Dashboard',
        value: 'renewal_dashboard' 
    }],
    'nonusage_dashboard':[{
        label: 'View Non Usage Dashboard',
        value: 'nonusage_dashboard' 
    }],
    'cp_dashboard':[{
        label: 'View CP Dashboard',
        value: 'cp_dashboard' 
    }],
    'outstanding_dashboard':[{
        label: 'View Outstanding Dashboard',
        value: 'outstanding_dashboard' 
    }]

}
const mainPermissions=[
    { 
        label:'Site',
        value:'site'
    },
    { 
        label:'Edit Site',
        value:'editSite'
    },
    { 
        label:'Static Page',
        value:'staticPage'
    },
    { 
        label:'Manage Menu',
        value:'manageMenu'
    },
    {
        label:'Global Templates',
        value:'globalTemplates' 
    },
    {
        label:'Campaigns',
        value:'campaigns' 
    },
    {
        label:'Onboarding Dashboard',
        value:'onboarding_dashboard' 
    },
    {
        label:'Renewal Dashboard',
        value:'renewal_dashboard' 
    },
    {
        label:'Non Usage Dashboard',
        value:'nonusage_dashboard' 
    },
    {
        label:'CP Dashboard',
        value:'cp_dashboard' 
    },
    {
        label:'Outstanding Dashboard',
        value:'outstanding_dashboard' 
    }

]
const userRole=[
    {
        label:'Customer Support Lead',
        value:'CUSTOMER_SUPPORT_LEAD'
    },
    {
        label:'Site Creator',
        value:'SITE_CREATOR'
    },
    {
        label:'Accounts',
        value:'ACCOUNTS'
    },
    {
        label:'Support Executive',
        value:'SUPPORT_EXECUTIVE'
    },
    {
        label:'CP',
        value:'CP'
    },
    {
        label:'Reseller',
        value:'RESELLER'
    },
    {
        label:'Referral',
        value:'REFERRAL'
    },
    {
        label:'Stockist',
        value:'STOCKIST'
    },
    {
        label:'Sales Person',
        value:'SALES_PERSON'
    },
    {
        label:'Support Account Manager',
        value:'SUPPORT_ACCOUNT_MANAGER'
    },
].sort((a,b)=>a.value.localeCompare(b.value))
export {
    externalPermissions,
    mainPermissions,
    userRole
};