import React, { Component } from 'react';
import { Button,   Form, Input, Row,  message,} from 'antd'
import siteWalletRechargeByAdmin from '../../mutation/siteWalletRechargeByAdmin'
import { withApollo } from 'react-apollo';
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const FormItem = Form.Item

class SiteRecharge extends Component {
    constructor(props){
        super(props)
        this.state={
            paymentLoading: false
        }
    }
    handleOk=(e)=>{
        // console.log("on ok")  
        e.preventDefault();
          this.props.form.validateFields(["amount","note"],(err, values) => {
            // console.log("values",values,this.props.siteResp)
            // return
              if(!err){
                this.setState({
                  paymentLoading: true
                })
        this.props.client.mutate({
          mutation: siteWalletRechargeByAdmin,
          variables: {
            siteId: this.props.siteData.siteId,
            amt: parseInt(values.amount*100),
            currency: this.props.siteData.curr,
            rechargeBy: "Admin",
            note: values.note ? values.note : undefined,
            paymentState: "PAID_OFFLINE"
          }
      }).then(({ data }) => {
          // console.log("data response", data)
          message.success("Recharge Successfull");
          this.createLogs(this.props.siteData.siteId,values.note)
          this.setState({
            paymentLoading: false
          })
          if(this.props.handleCancel){
            this.props.handleCancel()
          }
      })
    }
    })
      }
      createLogs=async(siteId,note)=>{
        let obj = {
            siteId: siteId,
            eventName: 'Billing',
            eventType: 'MANUAL',
            note: note
        }
        let resp = await SiteActionEvent(this.props,obj)
        if(resp){
          this.setState({
            notes: undefined
          })
        }
    }
    render() {
       const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Form>
                    <FormItem
                                label="Recharge Amount"
                            >
                                {getFieldDecorator(`amount`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Recharge Amount between 100 To 100000",
                                        // min:3,max:6
                                    },
                                    ,
                                    {
                                      validator: (rule, value, cb) => {
                                          if (value <1) {

                                              // if (value <100) {
                                                  cb('Please enter Recharge Amount Grater than 100')
                                              // }
                                          }
                                          cb()
                                      }
                                  }
                                   ]
                                })(
                                  <Input   type= "number" placeholder="Enter Recharge Amount" style={{width:"50%"}} onChange={this.rAmount}></Input>
                                )}
                            </FormItem>
                            <FormItem label='Notes' >
                                    {getFieldDecorator('note', {
                                       
                                        rules: [{
                                            required: true,
                                            message: "note",
                                        }],
                                    })(
                                        <Input placeholder="Note" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                    </Form>
                    <Row>
                      <Button type="primary" onClick={this.handleOk} style={{marginTop: "20px"}} loading={this.state.paymentLoading}>Submit</Button>
                    </Row> 
            </>
        );
    }
}
const WSiteRecharge = Form.create()(SiteRecharge);

export default withApollo(WSiteRecharge);