import gql from 'graphql-tag';

export default gql`
query getSitePagesByAdmin($nextToken: String $limit: Int) {
    getSitePagesByAdmin(nextToken: $nextToken, limit: $limit) {
      items {
        id
        siteId
        displayOrder
        pageName
        value
        onFooter
        displayName
        onHome
        pageFor
        pageType
        tags
        image{
          key
          bucket
          region
        }
      }
      nextToken
    }
  }
  `