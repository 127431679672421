import gql from 'graphql-tag';

export default gql`
mutation updateCustomLabels(
    $domain: String!
      $typeValue: String!
      $customLabels: [CustomLabelsInput!]
  ){
    updateCustomLabels(input:{
      domain:$domain
      typeValue: $typeValue
      customLabels: $customLabels
    }){
      domain
      typeValue
      customLabels{
        defaultName
        displayName
      }
    }
  }
  
`