import React from 'react'
import App from '../../App'
import { Form, Input, Button, Col,Row, message} from 'antd';
import {  withApollo } from 'react-apollo';
import slugify from 'slugify';
import createSiteConfigServices from '../../mutation/createSiteConfigServices'

const FormItem = Form.Item;
class ExtraService extends React.Component{
    constructor(props){
        super(props)
            this.state={
                data:[]
            }
    }
    handleSubmit = () =>{
        this.props.form.validateFields(async(err, values) => {
          console.log(" values ::",values)
            if(!err){
              console.log(" values ::",values)
              const slugServiceName = slugify(values.serviceName, {
                lower: true
              })

              this.props.client.mutate({
                mutation: createSiteConfigServices,
                variables: {
                  domain:'extraService',
                  typeValue: slugServiceName,
                  serviceName: values.serviceName,
                  charges: parseInt(values.charges)*100
                }
            }).then(({ data }) => {
              console.log("Resp Service ::",data)
              message.success("Service Created Successfully");
            //   this.props.form.resetFields(['serviceName'])
            //   this.props.form.resetFields(['charges'])
            })


            }

        })
  

    }
    handleclick=()=>{
        this.props.history.push('/service-list');
    }
    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return(
            <App header={'Extra Service'}>
                 <Form onSubmit={this.handleSubmit} style={{marginTop: '10px'}}>
                    <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   normalize: (input) => input.toUpperCase(),
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" type="text" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="One Time Charge"
                            >
                                {getFieldDecorator(`charges`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter One Time Charge",
                                    }]
                                })(
                                    <Input placeholder="One Time Charge" type="number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>

                    </Row> 
                    <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:18}} xl={{span:18}} >
                    </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        )
    }
}
const WrappedExtraService = Form.create({ name: "ExtraServices_form" })(
    ExtraService
  );
export default withApollo(WrappedExtraService)