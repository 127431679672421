import React, { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo';
import axios from 'axios';
import App from '../../App';
import SiteDetails from './siteDetails';
import { returnSymbolCurrFormate } from '../../utils/returnSymbol';
import {  Table } from 'antd';

function outstandingDashboard() {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [siteDetails,setSiteDetails] = useState()


    useEffect(() => {
        callQuries()
    }, [])

    const callQuries = async () => {
        setLoading(true)
        let variables = {
            "type": "salesPerson",
        }
        let data = await getData(variables)
        data = JSON.parse(data.result)
        const totals = data.reduce((acc, record) => {
            acc['LESS_30'] += record['LESS_30'];
            acc['30_TO_60'] += record['30_TO_60'];
            acc['MORE_60'] += record['MORE_60'];
            acc['balance'] += record['balance'];
            return acc;
        }, {
            'LESS_30': 0,
            '30_TO_60': 0,
            'MORE_60': 0,
            'balance': 0
        });

        // Add total record to the end
        data.push({
            "name": "TOTAL",
            "balance": totals['balance'],
            "sites": [],
            "LESS_30": totals['LESS_30'],
            "30_TO_60": totals['30_TO_60'],
            "MORE_60": totals['MORE_60']
        });
        setDataSource(data)
        setLoading(false)

    }

    const getData = async (param) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "GET",
                url: `https://analyser.mysnaptrade.com/outstanding_dashboard`,
                params: param,
            })
                .then(function ({ data }) {
                    // console.log("data",data)
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("In Error :", error);
                    resolve([])
                });
        })
    }

    const handleClick = (data) => {
        setSiteDetails(data)
    }

    const columns = [
        {
            title: 'Sales Person',
            dataIndex: 'name',
            align: 'left',
            key: 'name',
            width: 100,
            render: (data, record, index) => {
                if (index == dataSource.length - 1) {
                    return (<p style={{ fontWeight: "bold" }}>{data}</p>)
                } else {
                    return (<p style={{cursor:"pointer"}} onClick={()=>{handleClick(record)}} >{data}</p>)
                }
            }
        },
        {
            title: '< 30 days',
            dataIndex: 'LESS_30',
            key: 'LESS_30',
            align: 'center',
            width: 100,
            render: (data, i, index) => {
                if (index == dataSource.length - 1) {
                    return (
                        <p style={{ textAlign: "right", fontWeight: "bold", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                } else {
                    return (
                        <p style={{ textAlign: "right", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                }
            }
        },
        {
            title: '30-60 days',
            dataIndex: '30_TO_60',
            key: '30_TO_60',
            align: 'center',
            width: 100,
            render: (data, i, index) => {
                if (index == dataSource.length - 1) {
                    return (
                        <p style={{ textAlign: "right", fontWeight: "bold", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                } else {
                    return (
                        <p style={{ textAlign: "right", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                }
            }
        },
        {
            title: '>60 days',
            dataIndex: 'MORE_60',
            key: 'MORE_60',
            align: 'center',
            width: 100,
            render: (data, i, index) => {
                if (index == dataSource.length - 1) {
                    return (
                        <p style={{ textAlign: "right", fontWeight: "bold", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                } else {
                    return (
                        <p style={{ textAlign: "right", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                    )
                }
            }
        },
        {
            title: 'Total balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'center',
            width: 100,
            render: (data) => {
                return (
                    <p style={{ fontWeight: "bold", textAlign: "right", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                )
            }
        }
    ]

    return (
        <App header={"Outstanding Dashboard"}>
            <div >
                <Table
                    columns={columns}
                    className='sitetable'
                    bordered
                    dataSource={dataSource}
                    loading={loading}
                    size="middle"
                    rowKey="id"
                    scroll={{ y: 800 }}
                    pagination={{ pageSize: 100 }}
                />
            </div>
            
            <div>
             { siteDetails && <> <SiteDetails siteDetails={siteDetails}/> </> }
            </div>
        </App>
    )
}

export default withApollo(outstandingDashboard)
