import gql from 'graphql-tag'

export default gql`
query getSiteActionEventsFilter($searchValue: String! $skips: Int $limits: Int){
    getSiteActionEventsFilter(searchValue: $searchValue limits: $limits skips: $skips) {
      id
     siteId
      eventName
      eventType
      reason
      note
      createdAt
      createdBy
    }
  }`

//   { 
//       "searchValue": "{\"siteId\": \"32323\"}", 
//       "skips": 0,
//       "limits": 100
//     }