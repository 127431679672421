import gql from 'graphql-tag'
export default gql`
query getPlatformServicesBySiteId( $siteId: String!){
    getPlatformServicesBySiteId(siteId: $siteId){
      id
      siteId
      serviceId
      serviceType
      slugServiceName
      serviceName
      status
      masterService
      platform
      siteType
      createdAt
    }
  }
`
//  {
//      	"siteId": "master"   
//      }
    