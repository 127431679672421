import gql from 'graphql-tag'

export default gql`
mutation updateInvoiceFormatV2(
    $domain: String
    $typeValue: String!
  $companyName: String
  $companyLogo: S3ObjectInput
  $address: String
  $email: String
  $mobile: String
  $gst: Boolean
  $custName: Boolean
  $custMobile: Boolean
  $editCustDetails: Boolean
  $token: Boolean
  $siteURL: String
  $footerMessage: String
  $qrcode: S3ObjectInput
  $paymentMode: Boolean
  $custKOTPrint: Boolean
  $custAddress: Boolean
  $fieldDetails: [InvoiceFormatDetailsInput!]
  $invoiceOptionOnBill: [String]
  $paytmQrOptions: [String]
  $isPayQR: Boolean
  $upiId: String
  $printOperatorInfo: Boolean
  $itemInstructionOnBill: Boolean
){
updateInvoiceFormatV2(input:{
  domain: $domain
  typeValue: $typeValue
  companyName: $companyName
  companyLogo: $companyLogo
  address: $address
  email: $email
  mobile: $mobile
  gst: $gst
  custName: $custName
  custMobile: $custMobile
  editCustDetails: $editCustDetails
  token: $token
  siteURL: $siteURL
  footerMessage: $footerMessage
  qrcode: $qrcode
  paymentMode: $paymentMode
  custKOTPrint:$custKOTPrint
  custAddress:$custAddress
  fieldDetails: $fieldDetails
  invoiceOptionOnBill: $invoiceOptionOnBill
  paytmQrOptions: $paytmQrOptions
  isPayQR: $isPayQR
  upiId: $upiId
  printOperatorInfo: $printOperatorInfo
  itemInstructionOnBill: $itemInstructionOnBill
}){
  domain
  typeValue
  companyName
  companyLogo{
    bucket
    region
    key
  }
  address
  email
  mobile
  gst
  custName
  custKOTPrint
  printOperatorInfo
  editCustDetails
  siteURL
  footerMessage
  custAddress
  isPayQR
  upiId
  itemInstructionOnBill
  qrcode{
    bucket
    region
    key
  }
  paymentMode
  invoiceOptionOnBill
  paytmQrOptions
  fieldDetails{
    fieldName
    value
    isDisplay
    font
    fontSize
    image{
      bucket
      key
      region
    }
  }
}
}`

//  {
//    "typeValue": "invoiceformat::dine-in",
//    "companyName": "Recaho Digital",
//    "companyLogo": {
//      "bucket": "fds",
//      "region": "fsd",
//      "key": "dsf"
//    },
//    "address": "Pune",
//    "email": "khan.sohel005@gmail.com",
//    "mobile": "9028887875",
//    "gst": true,
//    "custName": true,
//    "siteURL": "dinetest.recaho.com",
//    "footerMessage": "Thank you",
//    "paymentMode": true,
//    "qrcode": {
//      "bucket": "fdf",
//      "region": "fdf",
//      "key": "fsd"
//    }
//  }


