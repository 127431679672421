import React,{ useState } from 'react';
import profile from '../../images/User-Profile.svg'
import { Menu, Dropdown, Icon } from 'antd';
import { Link } from "react-router-dom";
export default function myProfile(props) { 
       
    const [visible, setVisible] = useState(false);

    const handleVisibleChange = (flag) => {
      setVisible(flag);
    };
  

    const menu = (
        <Menu>
            <Menu.Item>
                <span  style={{fontWeight:"bold",color:"black", fontSize:"14px",}}>Hi! {props.userData.name}</span>
            </Menu.Item>
            <Menu.Item>
            <Link to="/my-profile">
                    <span > <img src='https://admin-studio-test.s3.amazonaws.com/HeaderIcon/Call-Support.svg'  style={{ width: "20px", height: "20px" }}  /> My Profile </span>
            </Link>
    
            </Menu.Item>
           
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href={`https://setupdetails.inmyarea.in/booking/tickets`}>
                    <img src='https://admin-studio-test.s3.amazonaws.com/HeaderIcon/Raise-Ticket.svg' style={{ width: "20px", height: "20px" }} /> Raise Ticket
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href={`https://anydesk.com/en/downloads/thank-you?dv=win_exe`}>
                    <img src='https://s3.ap-south-1.amazonaws.com/btoc-ecom-prod/9e45ba4b-7099-4bf1-9a32-1635c0c0ba6e/HTMLPage/265db0e0-061c-11ef-86f9-6127934dbcbe/AnyDesk.png' style={{ width: "20px", height: "20px" }} /> Download Anydesk
                </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <div  >

            <Dropdown 
             overlayClassName= { props.platform != "mobile" && 'userProfile'}
            overlay={menu} 
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            >
                <span>
                <img src={"https://admin-studio-test.s3.amazonaws.com/HeaderIcon/User-Profile.svg"} alt='img'  style={{width:"37px",height:"45px",marginRight:"10px",marginTop:"5px"}} />
                </span>
            </Dropdown>

        </div>
    )
}
