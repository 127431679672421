import React, { Component } from 'react';
import { Table} from 'antd';

class DetailsView extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    render() {
        // console.log("props ::",this.props.data.serviceDetails)
        const columns = [
            {
              title: 'Service Name',
              dataIndex: 'serviceName',
              key: 'serviceName',
            },
            {
              title: 'Master Service',
              dataIndex: 'masterService',
              key: 'masterService',
            },
            {
              title: 'Platform',
              dataIndex: 'platform',
              key: 'platform',
            },
            {
              title: 'Status',
              dataIndex: 'status',
        //       render: (data) => {
        //           return (
        //               <span >
        //                 {data ? data: ''}
        //               </span>
        //           )
        //   }
            }
          ];
        return (
            <div>
               <Table
                     bordered
                     dataSource={this.props.data.serviceDetails}
                     columns={columns}
                     /> 
            </div>
        );
    }
}

export default DetailsView;