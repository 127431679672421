import React from 'react'
import {  Button, Col, Row } from 'antd';

import CustomerList from '../EmailCampaign/userList'
import { Link } from 'react-router-dom';

class UserTabs extends React.Component{

    constructor(props){
        super(props)
        this.state={
            customerData:[],
            keys:''
        }
    }
 

    handleData = (key,data)=>{
        this.props.customerData(key,data)
    }


    render(){
        return(
            <div>
              
                    {this.props.showComp ? 
                    <Row>
                        <Col span={2}>
                            <Button style={{background:"#389e0d", color:"#fff"}} onClick={this.props.previous}>Previous</Button>
                        </Col>
                        <Col span={4}>
                            <Button style={{float:"left",background:"#f44336", color:"#fff"}} loading={this.props.loading} disabled={(this.props.custData.length != 0 || this.props.enqData.length != 0) ? false : true}  onClick={this.props.handleEmail}>Send Email</Button>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col span={4} offset={20}>
                            <Link to={{pathname:'/email-Customer',state:{modalData:this.props.modalData}}}>
                                <Button style={{background:"#389e0d", color:"#fff",float:"left"}}>Create Email Campaign</Button>
                            </Link>
                        </Col>
                    </Row>}<br/>
                        <CustomerList showComp={this.props.showComp} custData={this.props.custData} keys={this.props.keys} onSelectData={this.handleData}/>
            </div>
        )
    }
}
export default UserTabs 