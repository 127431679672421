import React from 'react';
import { Form, Button, Row,Col ,Icon, Input, message,Radio,Select,Upload} from 'antd';
// import Editor from "@monaco-editor/react";
import App from '../../App'
import FroalaEditor from '../../funComponent/froalaEditor'
import CreateSitePage from '../../mutation/updateSitePage';
import { graphql, compose, withApollo } from 'react-apollo';
import Resizer from "react-image-file-resizer";
import uuidv1 from 'uuid/v1';


const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const bucket = process.env.REACT_APP_BUCKET
const S3_REGION = process.env.REACT_APP_S3_REGION
const S3_Url = process.env.REACT_APP_S3_URL;
  
class CreateStaticPage extends React.Component {
    constructor(props) {
        super(props);
      this.state = {
        iconImg:this.props.location.state.currentPage.image!=null  ? {
          key: this.props.location.state.currentPage.image.key,
          uid:this.props.location.state.currentPage.image.key ,
          bucket: this.props.location.state.currentPage.image.bucket,
          region: this.props.location.state.currentPage.image.region ,
          url:  `${S3_Url}${this.props.location.state.currentPage.image.key}`
      } : {},
        removedIconImg: false,
        resizedIcon: [],
        loading: false,
        showOnFooter: this.props.location.state.currentPage.onFooter,
        finalVal: this.props.location.state.currentPage.value,
        onHome: this.props.location.state.currentPage.onHome,
        modelNew: this.props.location.state.currentPage.value,
        value: this.props.location.state.currentPage.pageFor == null ? "BOTH" : this.props.location.state.currentPage.pageFor,
        pageType: this.props.location.state.currentPage.pageType == null ? "FROALA" : this.props.location.state.currentPage.pageType,
        tags: this.props.location.state.currentPage.tags ? this.props.location.state.currentPage.tags : [],
        tagsArray:[
          { value:'CONTACTS', label:'Contacts'},
          { value:'CONTENTS', label:'Contents'},
          { value:'CUSTOM', label:'Custom'},
          { value:'FEATURES', label:'Features'},
          { value:'FOOTERS', label:'Footers'},
          { value:'FORM', label:'Form'},
          { value:'HEADERS', label:'Headers'},
          { value:'PRICING', label:'Pricing'},
          { value:'TEAMS', label:'Teams'},
          { value:'TESTIMONIALS', label:'Testimonials'},
          { value:'CallToAction', label:'Call to Action'},
          { value:'COVERS', label:'Covers'},
          { value:'BLOG', label:'Blog'},
          { value:'ECOMMERCE', label:'E-commerce'},
          { value:'MAGAZINE', label:'Magazine'},
          { value:'PROJECTS', label:'Projects'},
          { value:'COUNTERS', label:'Counters'},
          { value:'DOWNLOAD', label:'Download'},
          { value:'EXPLORE', label:'Explore'},
          { value:'FAQ', label:'FAQ'},
          { value:'GALLERY', label:'Gallery'},
          { value:'MAPS', label:'Maps'},
          { value:'CUSTOMERS', label:'Customers'},
          { value:'PORTFOLIO', label:'Portfolio'},
          { value:'STEPS', label:'Steps'},
          { value:'SERVICE', label:'Service'},
          { value:'SIGNUP', label:'Sign up'},
          { value:'SUBSCRIBE', label:'Subscribe'},
          { value:'VIDEO', label:'Video'},
          { value:'ADMIN', label:'Admin'},
          { value:'INDUSTRY', label:'Industry'},
          { value:'POLICIES', label:'Policies'},
          { value:'LANDING_PAGE', label:'Landing Page'},
          { value:'TEMPLATES', label:'Templates'},
          { value:'PLUGINS', label:'Plugins'},
          { value:'COMPONENTS', label:'Component'},
          { value:'SINGLE_PAGE_SITE', label:'Single Page Site'},
          { value:'DATA', label:'Data'},


        ]
      }
    }

  componentDidMount(){
    let arr = this.state.tagsArray
    arr.sort(function (a, b) {
        var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
        if (nameA < nameB)
            return -1;
        if (nameA > nameB)
            return 1;
        return 0;
    });
    this.setState({
        tagsArray: arr
    })

  //   const currentPage = this.props.location.state.currentPage;
  // // console.log(htmlToDraft(currentPage))
  //   const value = currentPage.value;
  //   console.log(value)
  //   const contentBlock = convertFromHTML(value)
  //   const contentState = ContentState.createFromBlockArray(contentBlock);
  //   const editorState = EditorState.createWithContent(contentState);
  //   console.log(contentBlock)
  //   console.log(contentState)

  //   this.setState({
  //     editorState:editorState
  //   },
  //   ()=>{
  //     console.log('done')
  //   });
  //   console.log(this.state.editorState)
  }

  toggle = (value) => {
    this.setState({ loading: value });
  }

  messageClose = () => {
    this.props.history.push('/site-pages');
  }

  enterLoading=()=>{
    let loading = this.state.loading;
    this.setState({
      loading:!loading
    })
  }

  message = ()=>{
    this.props.history.push('/site-pages')
  }
 

  handleSubmit=(e)=>{
    e.preventDefault();
    // this.check()
    this.props.form.validateFields((err, values) => {

      values.description = this.state.pageType == "FROALA" ? this.state.modelNew : values.descriptionHTML
     
      // return false
      if (!err) {

        if (values.description == '') {
          this.props.form.setFields({
            description: {
              value: values.description,
              errors: [new Error('Please input content ')]
            }
          });
          return true;
        } else {
          this.props.form.setFields({
            description: {
              value: values.description,
              errors: ''
            }
          });
        }
      // values.description = contentState
      // console.log("Tags",this.props.location.state.currentPage)
      // console.log("Tags",this.props.location.state.currentPage.image)
      // return false

      let imgobj=null
     
        if (this.state.iconImg != null && Object.keys(this.state.iconImg).length !=0 ) {

          if (Object.keys(this.state.iconImg).length !== 0 &&!this.state.iconImg.hasOwnProperty("key")) {
            imgobj = {
              bucket: bucket,
              region: S3_REGION,
              localUri: this.state.resizedIcon[0].url,
              key: `internalAdmin1/${uuidv1()}`,
              mimeType: this.state.resizedIcon[0].type
              
            };

          }
           else {
            imgobj={
              key:this.state.iconImg.key,
              bucket:this.state.iconImg.bucket,
              region:this.state.iconImg.region
            }
           
          }
        }
        this.enterLoading()
        this.props.createSitePage({
          variables:{
            siteId:this.props.customSiteId,
            value:values.description,
            pageName:values.pageTitle,
            displayName: values.pageTitle,
            onFooter:this.state.showOnFooter,
            onHome:this.state.onHome,
            pageFor:values.pagefor,
            pageType:this.props.location.state.currentPage.pageType,
            tags:this.state.tags,
            image: imgobj
          },
         
        }).then((res)=>{
          message.success('Page saved successfully',this.message)
        }).catch((err)=>{
          console.log("err,err",err)
        })
      }
    });

  }


  handleMainImageRemove = (file) => {
    this.setState({ iconImg: {}, removedIconImg: true })
    return;
}

  handleMainImage = ({ file }) => {
    this.setState((prevState, props) => {
        if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
            return{
                iconImg: file,
                removedIconImg: false
            }
        }
        else{
            return{
                // iconImg: {},
                removedIconImg: false
            }
        }
        
    },this.imageResize1(file));
    
}

imageResize1 = (img) => {
  let obj = {};
  let arr = []
  // console.log("Inresize",img)
  // return
  try{
    Resizer.imageFileResizer(
      img.originFileObj,
      1000,
      1000,
      "WEBP",
      80,
      0,
      (uri) => {
        // console.log("Aft resize",uri)
        obj={
          name: img.name,
          size: uri.size,
          type: uri.type,
          uid: img.uid,
          url: uri,
        }
       // console.log("After Resize", uri,obj);
        arr.push(obj)

        this.setState({
        
          resizedIcon: arr,
        });
      },
      "blob"
    );
    //  }
  }
  catch(err){
    console.log("err",err)
  }
 
};

  

  showOnFooter = (e)=>{
    this.setState({showOnFooter:e.target.value})
  }

  showOnHome = (e)=>{
    this.setState({onHome:e.target.value})
  }

  handleChangetags=(value)=>{
    this.setState({
      tags:value
    })
  }

  config = {
    key:process.env.froalaKey,
    // placeholder: 'Edit sdfdfMe',
    height:270,
    // width: 950,
    events: {
      // 'froalaEditor.focus' : function(e, editor) {
      //   console.log(editor.selection.get());
      // },
      // 'froalaEditor.image.uploadedToS3' : function(e, editor, link, key, response){
      //   console.log(e,response,'in')
      // },
      // 'froalaEditor.image.beforeUpload' : function(e, editor, images){
      //   console.log(e,editor,'in')
      // },
    },
    imageUploadToS3: this.props.location.state.imgData,
    toolbarButtons: {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
        'align': 'right',
        'buttonsVisible': 2
      }
    },
  
    // Change buttons for XS screen.
    toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']]
    // toolbarButtonsXS: ['undo', 'redo' , 'bold', 'italic', 'underline','strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html','insertImage','insertLink','insertVideo','uploadFile','Emoticons','special characters','code view','Print','Help','Quote','Font Family','Font Size','colors','Inline class','inline style','paragraph style','Line Height','paragraph format']
  };
  handleModelChange = modelNew => {
    this.setState({ modelNew: modelNew });
    // this.props.updateState(this.state.modelNew);
  };
  onPreviewChange =(value)=>{
    // console.log("f val",value)
    this.setState({
      finalVal:value
    })
  }
  handleEditorChange=(val)=>{
    this.setState({
      prevVal:val
    })
  }
  render (){
    // console.log("final..",this.state.finalVal)
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 18 },
    }
    const currentPage = this.props.location.state.currentPage;
    const loading = this.state.loading;
    const uploadButton = (
      <div>
          <Icon type="plus" />
          <div>Upload</div>
      </div>
      
      );
    return (
      <App header={"Static Pages"}>
      <Row style={{marginTop:'20px'}}>
        <Col>
        <Form layout='vertical' onSubmit={this.handleSubmit}>
        <Row>
          <Row gutter={24}>
          <Col  xs={{span:24}} sm={{span:24}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
              <FormItem label="Page Name">
                          {/* <Cascader options={this.state.menus} placeholder="Menu category" changeOnSelect={true} onChange={this.onChange} style={{width:"100%"}} /> */}
                          {getFieldDecorator('pageTitle', {
                              initialValue:currentPage.pageName,
                              rules: [{
                                  required: true,
                                  message: "Please input Page Title ",
                              }],
                          })(
                            <Input placeholder="Page Title" disabled/>
                          )}

              </FormItem>
          </Col>

          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:4}} xl={{span:4}}>
          <FormItem label='Show on Footer' >
            <RadioGroup onChange={this.showOnFooter} value={this.state.showOnFooter}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
            <FormItem label='Add to Home Page' >
              <RadioGroup onChange={this.showOnHome} value={this.state.onHome}>
               <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </RadioGroup>
            </FormItem>
          </Col>

          <Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

                  <FormItem label='Page For'>
                  {getFieldDecorator('pagefor', {
                              initialValue:this.state.value,
                              rules: [{
                                  required: false,
                                  message: "Please input Page For ",
                              }],
                          })(
                  <RadioGroup  onChange={this.onChange} value={this.state.value}>
                  <Radio value={"MOB"}>Mobile</Radio>
                  <Radio value={"WEB"}>Web</Radio>
                  <Radio value={"BOTH"}>Both</Radio>
                    </RadioGroup>
                          )}
                  </FormItem>
          </Col>
          </Row>

          <Row gutter={24}>

          <Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

                    <FormItem label='Page Editor' >
                    {getFieldDecorator('pageType', {
                              trigger: 'onBlur',
                              valuePropName: 'defaultValue',
                              rules: [{
                                  required: false,
                                  message: "Please Selete Page Type ",
                              }],
                          })(
                    <RadioGroup  onChange={this.onChangePageType} value={this.state.pageType} disabled={true}>
                    <Radio value={"FROALA"}>Froala</Radio>
                    <Radio value={"HTML"}>HTML</Radio>
                    {/* <Radio value={"BOTH"}>Both</Radio> */}
                      </RadioGroup>
                      )}
                    </FormItem>
                    
            </Col>
            <Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

        <FormItem label='Select Tag' >
        {getFieldDecorator('tags', {
                  initialValue:this.state.tags,
                  rules: [{
                      required: false,
                      message: "Please Selete Select Tag ",
                  }],
              })(
                <Select  
                optionFilterProp="children"
                mode="multiple"
                onChange={this.handleChangetags}
                placeholder="Please select">
                {this.state.tagsArray.map((c)=>{
                    return(
                        <Select.Option  key={c.value} value={c.value} >{c.label}</Select.Option>
                    )
                })}
                </Select>
           )}
        </FormItem>
        
      </Col>
      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Photo"  extra="Add square image.">
                            {getFieldDecorator('image', {
                                // validateTrigger: ['onChange','onBlur'],
                                // initialValue:this.state.iconImg,
                                rules: [{
                                    required: false,
                                    message: "Photo is required",
                                }],
                            })(
                                <Upload
                                    listType="picture-card"
                                    defaultFileList={Object.keys(this.state.iconImg).length != 0  ?[this.state.iconImg]:null}
                                    data={this.state.iconImg}
                                    // onPreview={this.handlePreview}
                                    onChange={this.handleMainImage}
                                    onRemove={this.handleMainImageRemove}
                                >
                                    {Object.keys(this.state.iconImg).length != 0 ? null : uploadButton}
                                </Upload>
                            )}
                          
                        </FormItem>
                      </Col>

          </Row> 
          <Row gutter={16}> 
          {this.state.pageType == "FROALA" ? 
          <Col span={24}>
                    <FormItem label="Content" {...formItemLayout}>
                    {getFieldDecorator('description', {
                      validateTrigger: ['onChange', 'onBlur']
                      // initialValue:"",
                      //  rules: [{
                      //     // whitespace:true
                      //      required: true,
                      //      message: "Please input Page Content ",
                      //  }],
                    })(
                      <FroalaEditor
                        //  heightMin= "400px"
                        //  heightMax= "600px"
                        config={this.config}
                        onModelChange={this.handleModelChange}
                        model={this.state.modelNew}
                      />
                    )}
                  </FormItem>
                  </Col>
                  :
                  <>
                  <Col md={{span:20}}>
                    <FormItem label="Content" {...formItemLayout}>
                    {getFieldDecorator('descriptionHTML', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue:this.state.modelNew ? this.state.modelNew : "",
                      //  rules: [{
                      //     // whitespace:true
                      //      required: true,
                      //      message: "Please input Page Content ",
                      //  }],
                    })(
                      // <TextArea rows={12} />
                      <FroalaEditor
                      height="70vh"
                      width="130vh"
                      defaultLanguage="html"
                      defaultValue={this.state.modelNew ? this.state.modelNew : ""}
                      onChange={this.handleEditorChange}
                      theme="vs-dark"
                    />
                    )}
                  </FormItem>
                  </Col>
                  <Col md={{span:1}} >
     
                  <Button type="danger" onClick={()=>this.onPreviewChange(this.state.prevVal)}>Preview</Button>
               
                  </Col>
                  {this.state.pageType == "HTML" ? 
              <Col md={{span:20}}>
                    <FormItem label="Preview:" {...formItemLayout} >
                    {getFieldDecorator('preview', {
                    validateTrigger: ['onChange', 'onBlur']
                  })(
                    // <TextArea rows={12} />
                    <iframe srcdoc={this.state.finalVal} style={{ height:"70vh", width: "130vh", border: '1px solid black' ,overflow:'hidden'}} >
                    </iframe>
                    
                  )}
                    </FormItem>
                  </Col>
                     :''}
                  </>
                  }
                  </Row>
          <Col span={24} offset={2}>
         
            <Button type="primary" style={{color:"#fff"}} htmlType="submit" loading={loading}>Save</Button>
            <Button type="default" style={{background:"#f44336", color:"#fff",marginLeft:"20px"}} onClick={this.messageClose}>Cancel</Button>
          </Col>
        </Row>                         
         </Form>
        </Col>
      </Row>
      </App>
    );
  }

}

const WrappedCreateStaticPage = Form.create()(CreateStaticPage);
const wrapsite = compose(
    withApollo,
    graphql(CreateSitePage,{
        name:'createSitePage'
    }),
)(WrappedCreateStaticPage)
export default wrapsite
