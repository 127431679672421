import gql from 'graphql-tag'
export default gql`
query getInternalUserByUserName($userName: String!) {
    getInternalUserByUserName(userName: $userName) {
        id
        siteId
        username
        userRole
        emailId
        mobile
        name
        enabled
        createdAt
        permissions{
          name
          operations
        }
        gstNo
        panNo
        onboardedBy
        companyName
        address{
          addressLineOne 
          addressLineTwo
          city
          state
          zip
          country
        }
        empsDetails{
          name 
          mobile
          email
          designated
        }
    }
}
`