import React, { Component } from 'react';
import {  Row, Col, Form, Button,message,Input,Upload,Icon  } from 'antd';
import uuidv1 from 'uuid/v1';
import { withApollo } from 'react-apollo';
import App from '../../App'
import updateTempleteByAdmin from '../../mutation/updateTempleteByAdmin'

const FormItem = Form.Item;
const { TextArea } = Input;

const bucket = process.env.REACT_APP_BUCKET
const S3_REGION = process.env.REACT_APP_S3_REGION
const S3_Url = process.env.REACT_APP_S3_URL;

class EditMTemplate extends Component {
    constructor(props){
        super(props)
        this.state={
            img:this.props.location && this.props.location.state.currentTemp && this.props.location.state.currentTemp.image != null ? {
                bucket: this.props.location.state.currentTemp.image.bucket,
                region: this.props.location.state.currentTemp.image.region,
                key: this.props.location.state.currentTemp.image.key,
                url: `${S3_Url}${this.props.location.state.currentTemp.image.key}`,
                uid: this.props.location.state.currentTemp.image.key,
            }: {},
            loading: false,
            content: this.props.location && this.props.location.state.currentTemp && this.props.location.state.currentTemp.text ? this.props.location.state.currentTemp.text : '',
            removedimage: false
        }
    }
    handleimage = ({fileList }) => {
        if(fileList.length >0){
          this.setState({
            img:fileList[0],
            removedimage:false
          })
        }
        
    }
    handleRemoveImg=({fileList})=>{
        // console.log('remove')
        this.setState({
           img:[],
          removedimage:true
        })
      }
    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err){
                this.enterLoading()
                console.log("values ::",values,this.state.img)
                if (Object.keys(this.state.img).length != 0) {
                    if (!this.state.img.hasOwnProperty("key")) {
                        values.img = {
                            bucket: bucket,
                            region: S3_REGION,
                            localUri: this.state.img.originFileObj,
                            key: `master/templates/whatsapp${uuidv1()}`,
                            mimeType: this.state.img.type
                        };

                    } else {
                        const { uid, url, bucket, key, region } = this.state.img
                        values.img = {
                            bucket,
                            key,
                            region
                        }
                    }
                } else {
                    values.img = null
                }
                console.log("values ::",values)
                // return

                this.props.client.mutate({
                    mutation: updateTempleteByAdmin,
                    variables: {
                        siteId: 'master',
                        slugName: this.props.location.state.currentTemp.slugName,
                        tempName: values.tempName,
                        tempSubject: values.tempSubject ? values.tempSubject : values.tempName,
                        text: this.state.content,
                        // type: this.props.type,
                        image: values.img
                    }
                }).then(({ data }) => {
                    console.log("data response",data)
                    message.success("Template saved successfully")
                    this.setState({
                        loading: true
                    },()=>{
                        this.props.history.push('/template-list')
                    })
                })
            }
        })
    }
    contentChange=(e)=>{
        this.setState({
            content: e.target.value
        })
      }
      addName=()=>{
        let text = this.state.content
        let ftext = text +'_companyName_'
        this.setState({
            content: ftext
        })
    }
    addCust=()=>{
        let text = this.state.content
        let ftext = text +'_custName_'
        this.setState({
            content: ftext
        })
    }
    addDomain=()=>{
        let text = this.state.content
        let ftext = text +'_fqdn_'
        this.setState({
            content: ftext
        })
    }
   addSitePlan=()=>{
        let text = this.state.content
        let ftext = text +'_sitePlan_'
        this.setState({
            content: ftext
        })
    }
    addBalance=()=>{
        let text = this.state.content
        let ftext = text +'_Balance_Amount_'
        this.setState({
            content: ftext
        })
    }
    addLastlogin=()=>{
        let text = this.state.content
        let ftext = text +'_Last_Login_'
        this.setState({
            content: ftext
        })
    }
    addUserName=()=>{
        let text = this.state.content
        let ftext = text +'_user_name_'
        this.setState({
            content: ftext
        })
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let tempData = this.props.location.state.currentTemp
        let img = this.state.img
        // console.log("tempData ::",tempData)
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
    
            </div>
          );
        return (
            <App header={'Edit Template'}>
                 <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16} >
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Template Name' className="booking-label">
                                {getFieldDecorator('tempName', {
                                    initialValue: tempData.tempName ? tempData.tempName : '',
                                    rules: [{
                                        required: true,
                                        message: "Please Enter Template Name",
                                    }],
                                })(
                                    <Input disabled placeholder="Template Name" style={{ width: '80%', marginRight: 8 }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Image' className="booking-label">
                            {getFieldDecorator('img',{
                                rules: [
                                {
                                    required: false,
                                    message: 'Please Upload Image!',
                                }],
                            })
                            (<Upload
                            listType="picture-card"
                            data={img ? img : ''}
                            defaultFileList={Object.keys(img).length ? [img] : null}
                            onChange={this.handleimage}
                            onRemove={this.handleRemoveImg}>
                            {Object.keys(this.state.img).length!=0 ? null : uploadButton}
                            </Upload>)}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Content' className="booking-label">
                                {getFieldDecorator('text', {
                                    //  initialValue: tempData.text,
                                    rules: [{
                                        required: false,
                                        message: "Please Enter Content",
                                    }],
                                })(
                                    <>
                                    <Button type='dashed' onClick={this.addCust} style={{marginRight: '10px'}}>Customer Name</Button>
                                    <Button type='dashed' onClick={this.addName} style={{marginRight: '10px'}}>Company Name</Button>
                                    <Button type='dashed' onClick={this.addDomain} style={{marginRight: '10px'}}>Domain</Button>
                                    <Button type='dashed' onClick={this.addSitePlan} style={{marginRight: '10px'}}>Site Plan</Button>
                                    <Button type='dashed' onClick={this.addBalance} style={{marginRight: '10px'}}>Balance Amount</Button>
                                    <Button type='dashed' onClick={this.addLastlogin} style={{marginRight: '10px'}}>Last Visit</Button>
                                    <Button type='dashed' onClick={this.addUserName} style={{marginRight: '10px'}}>User Name</Button>
                                    <br/>
                                    <TextArea style={{width:'80%',marginTop: '10px'}} rows={20} value={this.state.content} placeholder="Content" onChange={this.contentChange} />
                                    </>
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16} style={{width: '60%'}}>
                            <Button type="primary" loading={this.state.loading} style={{ background: "#389e0d", color: "#fff", marginBottom: 16 }} htmlType="submit" >Save</Button>
                            <Button loading={this.state.loading} style={{ marginBottom: 16,marginLeft: 10 }} onClick={()=>{ this.props.history.push('/template-list')}} >Cancel</Button>

                        </Row>
                        </Form>
            </App>
        );
    }
}
const WrappEditMTemplate= Form.create()(EditMTemplate);
export default withApollo(WrappEditMTemplate);