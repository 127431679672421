import gql from 'graphql-tag';
export default gql`
query getSubscriptionPlansBySiteId( $siteId: String!){
    getSubscriptionPlansBySiteId(siteId: $siteId){
      id
      siteId
      planId
      planName
      status
      masterService
      platform
      amount
      description
      services
      siteType
      createdAt
      curr
      chargingModel
      priceList{
        chargingModel
        rate
      }
      paymentGateway
      subscriptionId
      
      serviceDetails{
        id
        siteId
        serviceId
        slugServiceName
        serviceType
        serviceName
        status
        masterService
        platform
        createdAt
      }
    }
  }
`
//  {
    //    "siteId": "master"
    //  }
    