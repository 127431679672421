import React, { Component } from 'react';
import { Button, Row, Col, Form, Input, Icon } from 'antd'
let id = 0
class AddEmployee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        // employee:null

    }
}

  

    remove = k => {
      const { form } = this.props;
      const keys = form.getFieldValue('keys');
       id--;
      form.setFieldsValue({
        keys: keys.filter(key => key !== k),
      });
    };
  
    add = () => {
      const { form } = this.props;
      const keys = form.getFieldValue('keys');
      const nextKeys = keys.concat(id++);
      form.setFieldsValue({
        keys: nextKeys,
      });
    };
  
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { keys, names } = values;
      
          // console.log('Merged values:', keys.map(key => names[key]));
        }
      });
    };
  
    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      getFieldDecorator('keys', { initialValue: [] });
      const keys = getFieldValue('keys');
      const formItems = keys.map((k, index) => (
        <Row gutter={16} key={k}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item label={'Name'} required={false}>
              {getFieldDecorator(`employee[${k}].name`, {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please Enter name or delete this field.",
                  },
                ],
              })(<Input placeholder="Enter Name" />)}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item label="Contact Number" required={false}>
              {getFieldDecorator(`employee[${k}].mobile`, {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please Enter mobile number or delete this field.",
                  },
                ],
              })(<Input placeholder="Enter Contact Number" />)}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item label="Email" required={false}>
              {getFieldDecorator(`employee[${k}].email`, {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    type: 'email',
                    whitespace: true,
                    message: "Please Enter email or delete this field.",
                  },
                ],
              })(<Input placeholder="Enter Email" />)}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item label="Designation" required={false}>
              {getFieldDecorator(`employee[${k}].designated`, {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please Enter designation or delete this field.",
                  },
                ],
              })(<Input placeholder="Enter Designation" />)}
              {keys.length > 0 ? (
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => this.remove(k)}
                />
              ) : null}
            </Form.Item>
          </Col>
        </Row>
      ));
      
      return (
        <div>
          {formItems}
          <Form.Item>
            <Button type="dashed" onClick={this.add} style={{ width: '30%' }}>
              <Icon type="plus" /> Add Employee
            </Button>
          </Form.Item>
        </div>
      );
    }
  }
  
  export default AddEmployee;