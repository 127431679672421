import gql from 'graphql-tag'

export default gql`
mutation createSiteConfigServices(
    $domain: String!
    $typeValue: String!
    $serviceName: String!
    $charges: Int
  ){
    createSiteConfigServices(input:{
      domain:$domain
      typeValue: $typeValue
      serviceName: $serviceName
      charges: $charges
    }){
      domain
      typeValue
      serviceName
      charges
    }
  }
`
// {
    //   "domain": "recaho",
    //   "typeValue": "whatsapp",
    //   "serviceName": "whatsapp",
    //   "charges": 10
    // }
    
    // {
    //   "domain": "site",
    //   "typeValue": "yearly",
    //   "serviceName": "YEARLY",
    //   "charges": 500
    // } 