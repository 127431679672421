import React, { Component } from 'react';
import App from '../App'
class ErrorCom extends Component {
    render() {
        return (
            <App>
                <h5>Dear User, You don't have access to this page.</h5>
            </App>
        );
    }
}

export default ErrorCom;