import gql from 'graphql-tag'
export default gql`
mutation updatePlatformService(
    $siteId: String!
    $serviceId: String!
  $slugServiceName: String!
  $serviceName: String!
  $status: SERVICE_STATUS
  $masterService: String!
  $platform: SITE_OPTIONS
  $serviceType: String
){
updatePlatformService(input: {
  siteId: $siteId
  serviceId: $serviceId
  serviceName: $serviceName
  slugServiceName: $slugServiceName
  status: $status
  masterService: $masterService
  platform: $platform
  serviceType:$serviceType
}){
  siteId
  serviceId
  serviceName
  slugServiceName
  status
  serviceType
  masterService
  platform
  createdAt
}
}
`
//  {
//        "siteId": "master",
//        "serviceId": "0d4be8b3-54f3-4911-828a-87895df71259",
//        "slugServiceName": "test",
//        "serviceName": "test",
//        "status": "ACTIVE",
//        "masterService": "SITE",
//        "platform": "BOOKING_SALOON"
//      }
    