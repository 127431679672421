import gql from 'graphql-tag'
export default gql`
mutation siteWalletRechargeByAdmin(
    $siteId: String!
      $amt: Int! 
      $currency: CURRENCY_CODE
      $rechargeBy: String!
      $paymentState: PAYMENT_STATE
      $note: String
  ) {
    siteWalletRechargeByAdmin(input:{
      siteId: $siteId
      amt: $amt
      currency: $currency
      rechargeBy: $rechargeBy
      note: $note
      paymentState: $paymentState
    })
  }
`

//  {
    //    "siteId": "43b035b0-0aa0-4a17-a8d8-0c51069f2b74",
    //    "amt": 300,
    //    "currency": "INR",
    //    "rechargeBy": "ADMIN",
    //    "paymentState": "PAID_OFFLINE"
    //  }