import gql from 'graphql-tag';

export default gql`
mutation createSubscriptionPlan(
  $siteId: String!
  $planName: String!
  $status: SERVICE_STATUS
  $masterService: String!
  $platform: SITE_OPTIONS
  $siteType: SITE_TYPE
  $services: [String!]!
  $description: String
  $curr: CURRENCY_CODE
  $priceList: [PlanPriceListInput!]
){
createSubscriptionPlan(input:{
  siteId: $siteId
  planName: $planName
  status: $status
  masterService: $masterService
  platform: $platform
  siteType: $siteType
  description: $description
  services: $services
  curr: $curr
  priceList: $priceList
}){
  siteId
  planId
  planName
  status
  masterService
  platform
  siteType
  amount
  chargingModel
  description
  services
  createdAt
  paymentGateway
  subscriptionId
  curr
  priceList{
    chargingModel
    rate
  }
  
}
}
`
// {
//     "siteId": "master",
//     "planName": "Restaurant Beginner",
//     "status": "ACTIVE",
//     "masterService": "SITE",
//     "siteType": "O2OCommerce",
//     "platform": "BOOKING_RESTAURANTS",
//     "description": "THis package for beginer",
//     "services": [
//       "18ee369d-4281-4f3d-beac-72da7c014ca2",
//       "9bc503ac-11d5-4790-91d7-9e12ab2bf9bb"
//     ],
//     "curr": "INR",
//     "priceList": [
//       {
//         "chargingModel": "PAY_PER_USE",
//         "rate": 5
//       },
//        {
//         "chargingModel": "MONTHLY",
//         "rate": 500
//       },
//        {
//         "chargingModel": "YEARLY",
//         "rate": 6000
//       }
//     ]
//   }
  
    
