import React, { Component}from 'react';
import {UserContext} from "../components/context/userConfigContext";
import getInternalUserByUserName from '../queries/getInternalUserByUserName'
import Routes from "../routes";
import {  withApollo } from 'react-apollo';
import Spin from "antd/es/spin";

import  { Auth } from "aws-amplify";
class MainApp extends Component {
    constructor(props){
        super(props)
        this.state={
            loading: true,
            userDataC: {}
        }
    }
    async componentDidMount(){
        // this.setExpiryTime()
       await Auth.currentUserInfo()
        .then(async(data) => {
            await this.getUserDetails(data.username)
          if(data.attributes['custom:role'] == undefined){
            localStorage.setItem('role','ADMIN')
          }else{
            localStorage.setItem('role',data.attributes['custom:role'])
          }
        })
      }
      getUserDetails=(userName)=>{
        return new Promise((resolve,reject)=>{
        this.props.client.query({
          query: getInternalUserByUserName,
          variables: {
            userName: userName
          },
          fetchPolicy: 'no-cache'
      })
          .then(({ data }) => {
              this.setState({
                userDataC: data.getInternalUserByUserName,
                loading: false
              })
              resolve(true)
          })
        })
      }
    render() {
        return (
            <>
            {this.state.loading  ? 
                <div style={{textAlign:'center'}}>
                <Spin/>
                </div> :
                <UserContext.Provider
                value={this.state.userDataC}>
                    <Routes />
            </UserContext.Provider>
            }
           </>
        );
    }
}

export default withApollo(MainApp);