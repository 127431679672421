// Require Editor JS files.
import React from 'react'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'
// Require Font Awesome.

import FroalaEditor from 'react-froala-wysiwyg';
// Require Font Awesome.


const Froala = (props) =>{
    return(
        <FroalaEditor
            tag="textarea"
                // height={props.height}
            //  heightMin={props.heightMin}
            //  heightMax={props.heightMax}
            config={props.config}
            videoResponsive={true}
            onModelChange={props.onModelChange}
            model={props.model}
        />
    )
}
export default Froala
