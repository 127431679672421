import React, { useEffect,useState} from 'react'
import {  withApollo } from 'react-apollo';
import App from '../../App'
import { withRouter } from 'react-router-dom';
import { Select,Form,Button } from 'antd';
import getInternalUsers from '../../queries/getInternalUsers';
const FormItem = Form.Item;
function ViewDashboard(props) {
    const { getFieldDecorator, getFieldValue} = props.form;
    const [userData,setUserData] = useState([])

    useEffect(() => {
        getUsers()
      }, [])


     const getUsers=()=>{
        props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
               if(data.getInternalUsers){
                console.log("data.getInternalUsers::",data.getInternalUsers)
                let filterData = data.getInternalUsers && data.getInternalUsers.filter((item)=>{
                       if(item.userRole == "CP" && item.companyName){
                          return item
                       }
                })

                 console.log("filterData::",filterData)

                 filterData && filterData.sort((a, b) => {
                    if (a.companyName < b.companyName) {
                      return -1;
                    }
                    if (a.companyName > b.companyName) {
                      return 1;
                    }
                    return 0;
                  });

                setUserData(filterData)
               }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields(async(err, values) => {
                   
            if(!err && values){
                 if(values.channelPartner){
                    let partnerName = values.channelPartner
                    props.history.push({
                        pathname: '/cp-dashboard',
                        state: {partnerName}
                      });
                 }
            }

        })
    }
    return (
        <App header={"CP Dashboard"}>
            <div>
                <h1 style={{ textAlign: "center", fontSize: "22px" }}>Select Channel Partner</h1>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Form onSubmit={handleSubmit} style={{ textAlign: 'center', width: '50%' }}>
                 
                    <FormItem label='' >
                        {getFieldDecorator('channelPartner', {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [{
                                required: true,
                                message: "Please enter Partner Name.",
                            }],
                        })(
                            <Select
                                optionFilterProp="children"
                                showSearch
                                allowClear
                                style={{ width: "100%",textAlign:"center" }}
                                // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Please Select Partner Name">
                                {userData && userData.length > 0 &&
                                    userData.map((ele) => {
                                        if (ele.userRole == 'CP' && ele.companyName) {
                                            return (
                                                <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                                            )
                                        }
                                    })}
                            </Select>
                        )}
                    </FormItem>

                    <Button id="submit" type="primary"  htmlType="submit" style={{ cursor: "pointer", color: "#fff" }}>
                            View Dashboard
                 </Button>
                </Form>
                </div>
            </div>
        </App>
    )
}

const WrapCreateSite = Form.create()(ViewDashboard);
export default withRouter(withApollo(WrapCreateSite));
