import gql from 'graphql-tag';

export default gql`

mutation createCampaignHistorybyAdmin(
  $siteId: String
  $language: String
  $ProductType: String
  $sendingStatus: SENDING_STATUS!
  $template: String
  $type: String
  $queType: String
  $total: Int
){
createCampaignHistorybyAdmin(input:{
  siteId: $siteId
  language: $language
  ProductType: $ProductType
  sendingStatus: $sendingStatus
  template: $template
  type: $type
  queType: $queType
  total: $total
}){
  id
  siteId
  campaignId
  language
  ProductType
  sendingStatus
  template
  type
  queType
  total
  sent
  failed
  createdAt
}
}`

//  {
    //    "language": "eng",
    //    "ProductType": "BOOKING_RESTAURANT",
    //    "sendingStatus": "PENDING",
    //    "template": "3fdsfd",
    //    "type": "TEXT",
    //    "queType": "WHATSAPP",
    //    "total": 1
    //  }



