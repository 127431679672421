import React from 'react'
import App from '../../App'
import { Card } from 'antd';
export default function profileDetails(props) {
   

    const hasData = (field) => field !== null && field !== undefined && field !== '';
  const {address} = props.userData
// Construct the address string
const addressString = [
    hasData(address.addressLineOne) ? address.addressLineOne : null,
    hasData(address.addressLineTwo) ? address.addressLineTwo : null,
    hasData(address.city) ? address.city : null,
    hasData(address.state) ? address.state : null,
    hasData(address.zip) ? address.zip : null,
    hasData(address.country) ? address.country : null
].filter(Boolean).join(", ");

console.log("addressString::",addressString)

    return (
        <App header={"My Profile"}>
            <Card style={{
                height: "500px",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
                // fontFamily: "Arial, sans-serif"
            }}>
                <div style={{
                    backgroundColor: "#CFE0FC",
                    color: "white",
                    padding: "5px",
                    // borderBottom: "2px solid #2a6496",
                }}>
                    <h2 style={{
                        textAlign: "center",
                        margin: "0",
                        fontSize: "22px",
                        textTransform: "uppercase",
                        // letterSpacing: "1px"
                    }}>
                        Profile Details
                    </h2>
                </div>
                <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <h3 style={{ margin: "0", fontSize: "20px", fontWeight: "bold" }}>Company Information</h3>
                        <hr style={{ margin: "5px 0", border: "none", borderBottom: "1px solid #ccc" }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Company Name:</strong> { props.userData.companyName ? props.userData.companyName : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Name:</strong> { props.userData.name ? props.userData.name : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Contact Number:</strong> { props.userData.mobile ? props.userData.mobile : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Email ID:</strong> { props.userData.emailId ? props.userData.emailId : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Address:</strong> { addressString != '' ? addressString : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Onboarded By:</strong> { props.userData.onboardedBy ? props.userData.onboardedBy : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>GST No:</strong> { props.userData.gstNo ? props.userData.gstNo : "-"}</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>PAN No:</strong> { props.userData.panNo ? props.userData.panNo : "-"}</p>
                    </div>
                   
                    {/* <div style={{ marginBottom: "20px" }}>
                        <h3 style={{ margin: "0", fontSize: "20px", fontWeight: "bold" }}>Employee Details</h3>
                        <hr style={{ margin: "5px 0", border: "none", borderBottom: "1px solid #ccc" }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>Company Name:</strong> XYZ Corporation</p>
                        <p style={{ margin: "0", fontSize: "16px" }}><strong>GST No:</strong> GST123456789</p>
                      
                    </div> */}
                </div>

            </Card>
        </App>
    )
}
