import gql from 'graphql-tag'

export default gql`
mutation createEmailCampaignTemplate(
    $tempName: String!
  $tempSubject: String!
  $text: String!
){
createEmailCampaignTemplate(input:{
  tempName: $tempName
  tempSubject: $tempSubject
  text: $text
}){
  id
  siteId
  domain
  typeValue
  tempName
  tempSubject
  text
  createdAt
}
}
`