import React, { Component } from 'react';
import { Tabs,Button,Table,Popconfirm,message  } from 'antd';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import App from '../../App'
import getTempleteByUpdatedAtBySiteId from '../../queries/getTempleteByUpdatedAtBySiteId'
import deleteTemplete from '../../mutation/deleteTemplete'
const { TabPane } = Tabs;

class TemplateList extends Component {
    constructor(props){
        super(props)
        this.state={
            data: [],
            allData: [],
            loading: false
        }
    }
    callback=(key)=> {
        console.log(key);
      }
    componentDidMount(){
        this.getTemplate()
      }

      getTemplate=()=>{
        this.props.client.query({
            query: getTempleteByUpdatedAtBySiteId,
            variables:{
                siteId: 'master'
            },
            fetchPolicy: 'network-only',
        })
            .then(({ data }) => {
                console.log("data ::",data)
                this.setState({
                    allData:data.getTempleteByUpdatedAtBySiteId.items
                })
            })
      }

      handleDelete=(record)=>{
        // console.log("siteId",siteId)
        // return
        this.props.client.mutate({
            mutation: deleteTemplete,
            variables: {
                siteId: "master", 
                slugName: record.slugName
            }
        }).then(({ data }) => {
            console.log("data response",data)
            if(data.deleteTemplete){
                let arr = this.state.allData
                let index= arr.findIndex(p => p.slugName === record.slugName);
                arr.splice(index, 1)
                this.setState({
                    allData: arr
                })
            message.success("Template Deleted successfully")

            }
        })
      } 
    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'tempName',
                key: 'tempName',
                width: '10%',
            },
            {
                title: 'Image',
                render: (data)=>{
                    // console.log("Data::",data)
                    return(
                            data.image != null ?
    
                                <img src={process.env.REACT_APP_S3_URL + '' + data.image.key} 
                                style={{
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    maxWidth: "90%",
                                    maxHeight: 150,
                                    cursor:"pointer"}} 
                                // onClick={()=>{this.setState({fullImg:true,fullImage: process.env.S3_URL + '' + data.image.key})}}
                                />
                                :"-"
        
                    )
                },
                width: '10%',
            },
            {
                title: 'Content',
                dataIndex: 'text',
                key: 'text',
                width: '35%',
            },
            {
                title: 'Action',
                width: '10%',
                render: (data) => {
                    return (
                        <span>
                            <Link to={{pathname:"/editm-templates",state:{currentTemp: data}}}>
                                <Button type="default" icon="edit" style={{ cursor: "pointer", background:"#389e0d", color:"#fff" }}/>
                            </Link>
                            <Popconfirm  title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                            <Button icon="delete" type= "danger"    style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000", color:"#fff" }}/>
                            </Popconfirm>
                            
                        </span>
                    )
                }
            },
        ]
        return (
            <App header={"Template List"}>
                <Tabs onChange={this.callback} type="card">
                    <TabPane tab="Whatsapp Templates" key="1">
                    <p style={{textAlign: 'end'}}> 
                    <Link to={{ pathname: "/addm-templates",type:'WHATSAPP' }}>
                        <Button type='primary'>Add Whatsapp Template</Button>
                        </Link>
                    </p>
                    <Table
                        dataSource={this.state.allData}
                        columns={columns}
                        loading={this.state.loading}
                    />
                    </TabPane>
                    <TabPane tab="Email Templates" key="2">
                    <p style={{textAlign: 'end'}}>
                    <Link to={{ pathname: "/addm-templates",type:'EMAIL'  }}>
                        <Button type='primary'>Add Email Template</Button>
                        </Link>
                    </p>
                    </TabPane>
                </Tabs> 
            </App>
        );
    }
}

export default withApollo(TemplateList);