import React, {Fragment} from 'react'
import { Button, Col, Icon, Input, Row, Spin, Table } from 'antd'
import { compose, graphql, withApollo } from 'react-apollo';

import GetSiteList from '../../queries/getSitesAdmin';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

class CustomerList extends React.Component{
    constructor(props){
      super(props)
      this.state={
        data:[],
        parsedCSV: '',
        show:false,
        selectedRowKeys:this.props.keys,
      }
    }
    
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  }


  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node; }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  

  onChangeHandle = (selectedRowKeys, selectedRows) => {
    console.log(selectedRowKeys, selectedRows)
    this.setState({selectedRowKeys:selectedRowKeys})
    this.props.onSelectData(selectedRowKeys,selectedRows)
  } 


    render(){
      const {selectedRowKeys} = this.state
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onChangeHandle
      };
      const columns = [{
        title:"URL",
        dataIndex: 'fqdn',
        key: 'fqdn',
        width: 200,
        fixed: 'left',
        ...this.getColumnSearchProps('fqdn'),
    
      },
      {
        title: 'Customer Email',
        dataIndex: 'contactEmail',
        key: 'contactEmail',
      },
      {
        title: 'Customer Name',
        dataIndex: 'custName',
        key: 'custName',
      },
      
      {
        title: 'Type',
        dataIndex: 'siteType',
        key: 'siteType',
        ...this.getColumnSearchProps('siteType'),
      },
      {
        title: 'Site Option',
        dataIndex: 'siteOptions',
        key: 'siteOptions',
        render: (data)=>{
          return(
            <div>
           {data != null && <span>{data}</span>}
           {data == null && <span>--</span>}
           </div>
          )
        }
      },
     
      {
        title: 'Date',
        children: [{
          title: 'Start Date',
          dataIndex: 'fromDate',
          key: 'fromDate',
          render: (date) => {
            var dateString = moment.unix(date).format("DD/MM/YYYY");
            return(
                <span>{dateString}</span>
            )
          }
        }, {
          title: 'Ends In',
          dataIndex: 'tillDate',
          key: 'tillDate',
          render: (date) => {
            var dateString = moment.unix(date).format("DD/MM/YYYY");
            return(
                <span>{dateString}</span>
            )
          }
        }],
      },
      
       {
        title: 'Status',
        // dataIndex: 'enabled',
        key: 'enabled',
        fixed: 'right',
        width: 200,
        render:(data)=>{
          return(
            <span>
              <Row>
                <Col span={6}>
                  {data.enabled ? "Active" : "InActive"}
                </Col>
              </Row>
            </span>
          )
        }
      }
    ];
            
    let display='';
    this.state.data.length != 0 ? display='inline-block' : display='none';
    const antIcon = <Icon type="loading" style={{ fontSize: 34,textAlign: "center" }} spin />;
        return(
            <Fragment>
             
                {this.props.data.loading ?
                 <div style={{textAlign:"center"}}>

                   <Spin indicator={antIcon} /> 
                 </div>:
              this.props.showComp ? <Table dataSource={this.props.data.getSitesAdmin} 
              // onRow={(record) => ({
              //   onClick: () => {
              //     this.selectRow(record);
              //   },
              // })}
                    size="middle"
                    columns={columns} rowKey="id"  bordered rowSelection={rowSelection}
                    pagination={false}
                    />: 
                <Table
                    columns={columns}
                    bordered
                    dataSource={this.props.data.getSitesAdmin}
                    size="middle"
                    pagination={false}
                    rowKey="id"
                    scroll={{ x: '130%' }}
                />}     
              
            </Fragment>
        )
    }

}

const WrapCustomer =  compose(
    graphql(GetSiteList,{
        options: props => ({ 
            fetchPolicy:"cache-and-network"
        }),
    })
   
 
)(CustomerList)
export default withApollo(WrapCustomer)