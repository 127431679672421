import gql from 'graphql-tag'

export default gql`
query getServiceTypeSettingBySite($domain: String! $typeValue: String!){
  getServiceTypeSettingBySite(domain: $domain typeValue: $typeValue){
    domain
    typeValue
    siteId
    showOnlyMenu
    showMenu
    quantityReq
    orderText
    themeDesign
    fixfooter
    footerFields
    gallery
    bookingStaticPageWeb
    bookingStaticPageMob
    staticPageAtTop
    slotReq
     qrcode{
       bucket
       region
       key
     }
    paymentLink
    bookingDelay
    formTitle
    taxMethod
    orderButtonColor
    minOrderValue
    orderResp
    whatsAppText
    feedbackMsg
    reasonReject
    createdAt
    regardsName
    orderMessageTemplate
    showPaymentOption
    hoursOfOperation{
          day
          status
          start
          end
    }
    customCharges{
      customChargeName
      customChargeList{
        name
        rate
        chargeType
        defaultSelcted
        tax
        chargeFor
      }
    }
    customFeilds{
      customType
      customName
      customValue
      customListValue
      menuAttached
      required
      length
    }
    availableFeilds{
      availableType
      displayName
      display
      menuAttached
      required
    }
    formSchemas{
      id
      type
      customType
      customName
      customValue
      customListValue
      required
      display
      length
      isVerification
      verificationFormat
      displayOrder
      groupBy
      action
      min
      max
      availability
    }
  }
}




`


// # {
  // #   "typeValue": "dine-in::setting"
    
  // # }
  