import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Amplify } from '@aws-amplify/core';
import AWSAppSyncClient from "aws-appsync";

import AWS from 'aws-sdk/global';
import { ApolloProvider } from "react-apollo";
import AppSyncConfig from "../src/configs/aws-app-sync-config";
import AwsAmplifyConfig from "../src/configs/aws-amplify-config";

import MainApp from "./components/mainApp";


Amplify.configure(AwsAmplifyConfig);
class ApolloIndex extends Component {

  constructor(props){
    super(props)
   this.state={
    userData: null
   }

  }
  
  componentDidMount(){
    // this.setExpiryTime()
  }

  setExpiryTime = () => {
    // localStorage.setItem('expiryTime', 1800000)
    localStorage.setItem('expiryTime', 3000000)
  }

  render() {
    const signInUserSession = this.props.authData.signInUserSession;
    const EndPoint = process.env.REACT_APP_ENDPOINT
    const Region = process.env.REACT_APP_REGION
   
    // if (signInUserSession) {
    //   let accessToken = signInUserSession.accessToken.jwtToken;
    // }

    const client = new AWSAppSyncClient({
      url: EndPoint,
      region:Region,
      auth: {
        type: AppSyncConfig.authenticationType,
        jwtToken: signInUserSession.idToken.jwtToken
        // jwtToken: async () =>(await Auth.currentSession()).getIdToken().getJwtToken()
        // apiKey: AppSyncConfig.apiKey

      },
      disableOffline: true,
      complexObjectsCredentials: () => {
        return new AWS.Credentials({
          accessKeyId: process.env.REACT_APP_accessKeyId,
          secretAccessKey: process.env.REACT_APP_secretAccessKey
        });
      }
    });
    return (        
        <ApolloProvider client={client}>
            <MainApp  />
        </ApolloProvider>
    )
  }
};


export default withAuthenticator(ApolloIndex, false);
// export default ApolloIndex;