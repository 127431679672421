import React from "react";
import { Button, Col,Form, Input,  Row, Select,message, Icon,Table } from 'antd'
import {  withApollo } from 'react-apollo';
import moment from 'moment'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import createSiteExtraService from '../../mutation/createSiteExtraService'
import getSiteExtraServicesBySiteId from '../../queries/getSiteExtraServicesBySiteId'
const FormItem = Form.Item
const { Option } = Select;
let dispVal = []
let id = 0;
class AddExtraService extends React.Component{
    constructor(props){
        super(props)
        this.state={
            oneTimeService: [],
            specTypeDisplay:[],
            currentSer: [],
            loading: false
        }
    }
    componentDidMount(){
        this.getOneTimeService()
        if(this.props.siteData.fqdn){
          this.getCurrentSer()
        }
    }
    getCurrentSer=()=>{
      this.props.client.query({
        query: getSiteExtraServicesBySiteId,
        variables: {
            siteId: this.props.siteData.siteId,
            limit: 30,
            nextToken: ''
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          console.log('getSiteExtraServicesBySiteId', data)
          let trData = data.getSiteExtraServicesBySiteId.items 
          let sorted = trData.sort(function(a, b) {
            return b.createdAt - a.createdAt;
        });
          this.createData(sorted)
          // this.setState({
          //       currentSer:data.getSiteExtraServicesBySiteId.items,
          //       loading: false
          // })
        })
        .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
        })
    }
    createData=(data)=>{
      let newArr = []
      let obj ={}
     data.map((val)=>{
        obj={
          serviceName: val.serviceName,
          charges: val.amt,
          note: val.note,
          createdAt: val.createdAt
        }
        newArr.push(obj)
      })
      this.setState({
            currentSer:newArr,
            loading: false
      })
    }
    getOneTimeService=()=>{
      // console.log("props :: from edit",this.props.siteData.fqdn)
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "extraService"
            },
            fetchPolicy: 'network-only'
          })
            .then(({ data }) => {
              console.log('oneTimeService', data)
              this.setState({
                oneTimeService: data.getSiteConfigServices,
                loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    enterLoading=()=>{
      this.setState({
        loading: true
      })
    }
    handleSubmit=(e)=>{
      e.preventDefault();
      this.props.form.validateFields(async(err, values) => {
       console.log("Ser",values)
        // return
        if(!err && values.charges.length > 0){
          this.enterLoading()
          for(let i =0; i<values.charges.length; i++){
           await this.createExtraSer(values.charges[i])
        }
        message.success("Extra Service Created Successfully");
        if(this.props.getRecharge){
          this.props.getRecharge()
        }
        this.props.form.resetFields(['charges'])
        this.props.form.resetFields(['keys'])
        this.setState({
          loading: false
        },()=>{
          this.getCurrentSer()
        })
      }
      })
    }
    createExtraSer=(data)=>{
      return new Promise((resolve, reject) => {
       console.log("Data",data)
       this.props.client.mutate({
        mutation: createSiteExtraService,
        variables: {
          siteId: this.props.siteData.siteId,
          amt:parseInt(data.charges)*100,
          currency: this.props.siteData.curr,
          serviceName: data.serviceName,
          note: data.notes,
        }
    }).then(({ data }) => {
      console.log("Resp Charges ::",data)
      resolve(true)
      })
      })
    }
    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
          return;
        }
    
        // can use data-binding to set
        form.setFieldsValue({
          keys: keys.filter(key => key !== k),
        });
      };
      handleclick=()=>{
        this.props.history.push('/');
    }
      add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
          keys: nextKeys,
        });
      };
      handleTypeChange = (param, type) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        if (!('value' in this.props)) {
          this.setState({ type });
          dispVal[param] = type
          this.setState({ 
            specTypeDisplay:dispVal,
          });
        }    
        this.triggerChange({ type });
      }
      triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
          onChange(Object.assign({}, this.state, changedValue));
        }
      }
      chargeInt=(index)=>{
        let value = this.state.oneTimeService.find(o=> o.serviceName == this.state.specTypeDisplay[index])
        // console.log("value",value)
        if(value && value.charges){
          return value.charges/100
        }
      }
      columns = [
        {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: '15%',
          render:(data)=>{
            var dateString = moment.unix(data).format("DD/MM/YYYY HH:mm");
           
            return(
                <span>{dateString}</span>
            )
        }
      },
        {
          title: 'Service Name',
          dataIndex: 'serviceName',
          key: 'service',
          width: '25%',
        },
        {
            title: 'Service Rate',
            dataIndex: 'charges',
            key: 'Service Rate',
            width: '15%',
            render:(data)=>{
              let rate = data ? data/100 : ''
              return(
                <span>{rate}</span>
              )
            }
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',

          }
    ];
    render(){
      // console.log("oneTimeService",this.getRecharge)
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 24 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 20 },
            },
          };
          const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 20 },
            },
          };
          getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
        <Row key={k} gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}}>
      <Form.Item
      className="customF" 
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ?  <span style={{fontSize:'15px',fontWeight:500,}}>Service</span>  : ''}
        required={false}
        key={index}
      >
        {getFieldDecorator(`charges[${index}]serviceName`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: k.serviceName,
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input select Service or delete this field.",
            },
          ],
        })( <Select style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
            {this.state.oneTimeService.map((val,i)=>{
                return(
                <Option value={val.serviceName}>{val.serviceName}</Option>
                )
            })}
      </Select>)}
      </Form.Item>
      </Col>
     

    <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}}>
      <FormItem
      className="customF" 
      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
      label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Price</span> : ''}
      required={false}
      key={index}
      >
        {getFieldDecorator(`charges[${index}]charges`, {
          validateTrigger: ['onChange', 'onBlur'],
          // initialValue: k.charges > 0  ? k.charges/100 : null,
          initialValue: this.chargeInt(index),
          rules: [
            {
              required: true,
              message: "Please input select Service or delete this field.",
            },
          ],
        })(<Input placeholder="Price" type="Number" ></Input> )}
      </FormItem>
      </Col>

      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}}>
      <FormItem
      className="customF" 
      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
      label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Notes</span> : ''}
      required={false}
      key={index}
      >
        {getFieldDecorator(`charges[${index}]notes`, {
          validateTrigger: ['onChange', 'onBlur'],
          // initialValue: k.note ? k.note : null,
          rules: [
            {
              required: false,
              message: "Please input select Service or delete this field.",
            },
          ],
        })(<Input placeholder="Note" type="text" ></Input> )}
      </FormItem>
      </Col>


    <FormItem
      className="customF" 
      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
      label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Action</span> : ''}
      required={false}
      >
    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
        {keys.length > 1 ? (
          <Icon
            // className="dynamic-delete-button"
            // className="customF"
            type="minus-circle-o"
            // style={{position: 'absolute',top: 0,bottom: 0}}
            onClick={() => this.remove(k)}
          />
        ) : null}
        </Col>
        </FormItem>
      
        </Row>
   
    ));
        return(
            <>
                <Table 
                columns={this.columns} 
                dataSource={this.state.currentSer}
                loading={this.state.loading}
                />
             <Form  >
            {/* {this.state.currentSer && this.state.currentSer.length > 0 ? this.state.currentSer.map((val)=>{
                  return (
                    <Row style={{marginBottom: '15px'}}>
                  <span>Service Name : <strong>{val.serviceName}</strong></span><br/>
                  <span>Service Rate : <strong>{val.charges/100}</strong></span><br/>
                  <span>Date : <strong>{ moment.unix(val.createdAt).format("DD/MM/YYYY HH:mm")}</strong></span><br/>
                  <span>Note : <strong>{val.note}</strong></span><br/>

                  </Row>
                  )
                })
                  : ''
                } */}
             {formItems}
             <FormItem {...formItemLayoutWithOutLabel}>
                    <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                        <Icon type="plus" /> Add Service
                    </Button>
            </FormItem>
            <p>
            <Button type="primary"  onClick={this.handleSubmit} loading={this.state.loading}  style={{ float: "left", marginBottom: 16, cursor: "pointer" }}>
              Save
            </Button>
            {/* <Button type="defalt"  onClick={this.props.getRecharge} style={{ float: "left", marginBottom: 16, cursor: "pointer", background:"#f44336", color:"#fff", marginLeft: '10px' }}>
                            Cancel
            </Button> */}
            </p>
            </Form>
             </>
        )
    }
}
const wrapAddExtraService = Form.create()(AddExtraService);
export default withApollo(wrapAddExtraService)