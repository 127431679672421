import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Button,Select, Input,Radio  } from 'antd';
import getTempleteByUpdatedAtBySiteId from '../../queries/getTempleteByUpdatedAtBySiteId'
import App from '../../App'
import MarketingIndex from './index'
const S3_Url = process.env.REACT_APP_S3_URL;

const { Option } = Select;
const { TextArea } = Input;


class SelectTemplate extends Component {
    constructor(props){
        super(props)
        this.state={
            tempData: [],
            selectedTemp: null,
            image: null,
            content: null,
            value: 1,
            show: false,
        }
    }
    componentDidMount(){
        this.getTemplate()
    }
    getTemplate=()=>{
        this.props.client.query({
            query: getTempleteByUpdatedAtBySiteId,
            variables:{
                siteId: 'master'
            },
            fetchPolicy: 'network-only',
        })
            .then(({ data }) => {
                console.log("data ::",data)
                this.setState({
                    tempData:data.getTempleteByUpdatedAtBySiteId ?data.getTempleteByUpdatedAtBySiteId.items : []
                })
            })
      }
      onChangeTemplate=(value)=>{
        let temp = this.state.tempData
        let curt = temp.find((x)=> x.slugName == value)
        // console.log("curt",curt)
        let image = curt && curt.image && Object.keys(curt.image).length != 0 ? {
            bucket: curt.image.bucket,
                region: curt.image.region,
                key: curt.image.key,
                url: `${S3_Url}${curt.image.key}`,
                uid: curt.image.key,
        } : null
        // console.log("image",image)
        this.setState({
            image: image,
            selectedTemp: curt,
            content: curt.text,
        })
      }
      show=()=>{
        this.setState({
            show: true
        })
      }
      contentChange=(e)=>{
        this.setState({
            content: e.target.value
        })
      }
      addName=()=>{
        let text = this.state.content
        let ftext = text +'_companyName_'
        this.setState({
            content: ftext
        })
    }
    addCust=()=>{
        let text = this.state.content
        let ftext = text +'_custName_'
        this.setState({
            content: ftext
        })
    }
    addDomain=()=>{
        let text = this.state.content
        let ftext = text +'_fqdn_'
        this.setState({
            content: ftext
        })
    }
   addSitePlan=()=>{
        let text = this.state.content
        let ftext = text +'_sitePlan_'
        this.setState({
            content: ftext
        })
    }
    addBalance=()=>{
        let text = this.state.content
        let ftext = text +'_Balance_Amount_'
        this.setState({
            content: ftext
        })
    }
    addLastlogin=()=>{
        let text = this.state.content
        let ftext = text +'_Last_Login_'
        this.setState({
            content: ftext
        })
    }
    addUserName=()=>{
        let text = this.state.content
        let ftext = text +'_user_name_'
        this.setState({
            content: ftext
        })
    }
    render() {
        const { image,show } = this.state;
        return (
            <App header={"Campaigns"}>
                {(show && show == true) ?
                <MarketingIndex 
                selectedTemp={this.state.selectedTemp}
                content= {this.state.content}
                />
            :
            <>
                  <Radio.Group disabled onChange={this.onChange} value={this.state.value}>
                        <Radio value={1}>Whatsapp Campaign</Radio>
                        <Radio value={2}>Email Campaign</Radio>
                    </Radio.Group><br/>
                <Select
                    showSearch
                    style={{ width: 150,marginRight: '10px',marginTop: '20px',marginBottom: '10px' }}
                    placeholder="Select Template"
                    optionFilterProp="children"
                    onChange={this.onChangeTemplate}>
                    {this.state.tempData != [] && this.state.tempData.length > 0 ? this.state.tempData.map((ele)=>{
                    return(
                        <Option value={ele.slugName}>{ele.tempName}</Option>
                    )
                }): ''}
                </Select>
                <br></br>
                {image &&(
                    <div style={{marginTop: '15px'}}>
                  
                    <img src={image.url} height='150px' width='150px' />
                    </div>
                )}
                {this.state.selectedTemp &&(
                    <>
                        <label style={{marginTop: '10px',marginRight: '10px'}}>Content:</label>
                        <Button type='dashed' onClick={this.addCust} style={{marginRight: '10px'}}>Customer Name</Button>
                                    <Button type='dashed' onClick={this.addName} style={{marginRight: '10px'}}>Company Name</Button>
                                    <Button type='dashed' onClick={this.addDomain} style={{marginRight: '10px'}}>Domain</Button>
                                    <Button type='dashed' onClick={this.addSitePlan} style={{marginRight: '10px'}}>Site Plan</Button>
                                    <Button type='dashed' onClick={this.addBalance} style={{marginRight: '10px'}}>Balance Amount</Button>
                                    <Button type='dashed' onClick={this.addLastlogin} style={{marginRight: '10px'}}>Last Visit</Button>
                                    <Button type='dashed' onClick={this.addUserName} style={{marginRight: '10px'}}>User Name</Button>
                                    <br/>
                        <TextArea rows={10} style={{ marginTop: '15px'}} value={this.state.content} onChange={this.contentChange}/>
                        <Button type='primary' style={{ marginTop: '15px'}} onClick={this.show}>Next</Button>
                    </>
                )}
                </>
                }
            </App>
        );
    }
}

export default withApollo(SelectTemplate);