import gql from 'graphql-tag'
export default gql`
query getPlatformServicesByPlatform( $siteId: String! $platform: SITE_OPTIONS! ){
    getPlatformServicesByPlatform(siteId: $siteId platform: $platform){
      id
      siteId
      serviceId
      serviceType
      slugServiceName
      serviceName
      status
      masterService
      platform
      siteType
      createdAt
    }
  }
`  