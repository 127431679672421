import gql from "graphql-tag";
export default gql`
  mutation updateFalconStore(
    $domain: String!
    $typeValue: String!
    $siteId: String
    $name: String!
    $description: String!
    $logo: S3ObjectInput
    $images: [S3ObjectInput!]
    $gstin: String
    $fssai: String!
    $street_address: String!
    $building: String
    $locality: String!
    $landmark: String
    $city: String!
    $state: String!
    $country: String!
    $pin_code: String!
    $location: LatLngInput
    $store_timings: StoreTimingsInput
    $category: String
    $subcategory: String
    $delivery_types: [DELIVERY_TYPE_FALCON]
    $store_status: String
    $minimum_order_size: Int
    $website: String
    $serving_radius: ServingRadiusInput
    $delivery_support_required: Boolean
    $delivery_charges: [DeliveryChargesInput]
    $gst_liable_items: String
    $gst_liable_packaging: String
    $owner_contact: String!
    $owner_name: String!
    $owner_email: String!
    $delivery_contact: String!
    $business_email: String!
    $brand_name: String
    $payment: FalconPaymentInput!
    $merchantId: String
  ) {
    updateFalconStore(
      input: {
        domain: $domain
        typeValue: $typeValue
        siteId: $siteId
        name: $name
        description: $description
        logo: $logo
        images: $images
        gstin: $gstin
        fssai: $fssai
        street_address: $street_address
        building: $building
        locality: $locality
        landmark: $landmark
        city: $city
        state: $state
        country: $country
        pin_code: $pin_code
        location: $location
        store_timings: $store_timings
        category: $category
        subcategory: $subcategory
        delivery_types: $delivery_types
        store_status: $store_status
        minimum_order_size: $minimum_order_size
        website: $website
        serving_radius: $serving_radius
        delivery_support_required: $delivery_support_required
        delivery_charges: $delivery_charges
        gst_liable_items: $gst_liable_items
        gst_liable_packaging: $gst_liable_packaging
        owner_contact: $owner_contact
        owner_name: $owner_name
        owner_email: $owner_email
        delivery_contact: $delivery_contact
        business_email: $business_email
        brand_name: $brand_name
        payment: $payment
        merchantId: $merchantId
      }
    ) {
      id
      domain
      typeValue
      siteId
    }
  }
`;

//payment qr code
