import gql from 'graphql-tag'
export default gql`
mutation subscribeSitePlanByAdminV2(
    $siteId: String!
    $planName: String!
    $status: SERVICE_STATUS
    $masterService: String!
    $platform: SITE_OPTIONS
  	$siteType: SITE_TYPE
    $amount: Float!
  	$chargingModel: CHARGING_MODEL
    $services: [String!]!
    $description: String
    $paymentGateway: PAYMENT_GATEWAY
		$curr: CURRENCY_CODE
){
  subscribeSitePlanByAdminV2(input:{
    siteId: $siteId
    planName: $planName
    status: $status
    masterService: $masterService
    platform: $platform
    siteType: $siteType
    amount: $amount
    chargingModel: $chargingModel
    description: $description
    services: $services
    paymentGateway: $paymentGateway
    curr: $curr
  })
}`


// {
//       "siteId": "b148b379-a018-44e3-ae54-644bac405262",
//       "planName": "Restaurant Beginner",
//       "status": "ACTIVE",
//       "masterService": "SITE",
//       "platform": "BOOKING_RESTAURANTS",
//       "siteType": "O2OCommerce",
//       "amount": 79900,
//       "chargingModel": "MONTHLY",
//       "description": "This package for beginer",
//       "paymentGateway": "MANUAL",
//       "curr": "INR",
//       "services": [  
//        "18ee369d-4281-4f3d-beac-72da7c014ca2",
//         "9bc503ac-11d5-4790-91d7-9e12ab2bf9bb",
//         "d93fe907-084b-4b50-8ef8-419302503742"
//       ]
//     }
    
