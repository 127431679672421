import React from 'react'
import { Form, Input, Button, Col,Row,Select, message,} from 'antd';
import {  withApollo } from 'react-apollo';
import slugify from 'slugify';
import createSiteConfigServices from '../../mutation/createSiteConfigServices'
import App from '../../App'
const FormItem = Form.Item;
const Option = Select.Option;
class CreateService extends React.Component{
    constructor(props) {
    super(props)
    this.state={
        serviceData: []
    }
    }


    handleSubmit = () =>{
        this.props.form.validateFields(async(err, values) => {
          console.log(" values ::",values)
            if(!err && values.names.length >=3){
              console.log(" values ::",values)
              let serviceName = values.serviceName
            let flag = await this.CreateService(serviceName)
            console.log(" flag ::",flag)
            if(flag == true){
              for(let i =0; i<values.names.length; i++){
                  await this.createCharges(values.names[i],serviceName)
              }
              message.success("Service Created Successfully");
            }
            }

        })
  

    }
    CreateService=(serviceName)=>{
     const slugServiceName = slugify(serviceName, {
      lower: true
    })
     console.log("values In CreateService::",slugServiceName)

      return new Promise((resolve, reject) => {
        this.props.client.mutate({
          mutation: createSiteConfigServices,
          variables: {
            domain:'recaho',
            typeValue: slugServiceName,
            serviceName: serviceName,
          }
      }).then(({ data }) => {
        console.log("Resp Service ::",data)
        resolve(true)

      })

      })
    }
    createCharges=(chargesArr,serviceName)=>{
      console.log("createCharges ::",chargesArr,serviceName)
      const slugServiceName = slugify(serviceName, {
        lower: true
    })
      const slugTypeNme = slugify(chargesArr.typeValue, {
        lower: true
    })
   
      return new Promise((resolve, reject) => {
       this.props.client.mutate({
          mutation: createSiteConfigServices,
          variables: {
            domain:slugServiceName,
            typeValue: slugTypeNme,
            serviceName: chargesArr.name,
            charges: parseInt(chargesArr.rate)*100
          }
      }).then(({ data }) => {
        console.log("Resp Charges ::",data)
        resolve(true)

      })
     


      })
    }
    handleclick=()=>{
      this.props.history.push('/service-list');
  }
    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        
    
        let charges = [{
            name: "PAY PER USE",
            typeValue: "PAY_PER_USE",
            rate: null
        },{
            name: "MONTHLY",
            typeValue: "MONTHLY",
            rate: null
        },{
            name: "YEARLY",
            typeValue: "YEARLY",
            rate: null
        }]
      getFieldDecorator('keys', { initialValue: charges });
      const keys = getFieldValue('keys');
      const formItems = keys.map((k, index) => (
        <Form.Item
        //   {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Charges' : ''}
          required={false}
          key={k}
          className="menuFlex"
          // style={{ display: 'flex'}}
        >
          {getFieldDecorator(`names[${index}]name`, {
           initialValue: k.name,
            rules: [
              {
                required: false,
                message: "Please input passenger's name or delete this field.",
              },
            ],
          })(<Input placeholder="passenger name" disabled style={{ width: '30%', marginRight: 8 }} />)}
            {getFieldDecorator(`names[${index}]rate`, {
            // validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                
                required: false,
                message: "Please input Rate",
              }
            ],
          })(<Input placeholder="Rate"  style={{ width: '30%', marginRight: 8 }} />)}
           {getFieldDecorator(`names[${index}]typeValue`, {
            // validateTrigger: ['onChange', 'onBlur'],
            initialValue: k.typeValue,
            rules: [
              {
                required: false,
                message: "Please input Rate",
              },
            ],
          })(<Input placeholder="Rate" style={{ width: '30%', marginRight: 8, display: "none" }} />)}
        </Form.Item>
      ));
        return(
            <App header={'Create Service'}>
      <Form onSubmit={this.handleSubmit} style={{marginTop: '10px'}}>
                    <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   normalize: (input) => input.toUpperCase(),
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" type="text" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row> 
                    <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:18}} xl={{span:18}} >
                   {formItems}
                    </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        )
    }
}
const WrappedCreateService = Form.create({ name: "Services_form" })(
    CreateService
  );
export default withApollo(WrappedCreateService)