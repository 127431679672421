import React from 'react'
import { Table, Input,Divider, Button, Popconfirm,Radio, Select,DatePicker,Modal,Form,Row,Col,message } from 'antd';
import {  withApollo } from 'react-apollo';
import moment from 'moment'

import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import getSiteConfig from '../../queries/getSiteConfig'
import getSiteWalletById from '../../queries/getSiteWalletById'
import createSiteConfig from '../../mutation/createSiteConfig'
import updateSiteConfig from '../../mutation/updateSiteConfig'
import siteWalletRechargeByAdmin from '../../mutation/siteWalletRechargeByAdmin'
import subscribeSitePlanByAdmin from '../../mutation/subscribeSitePlanByAdmin'

import AddExtraService from './addExtraServiceToSite'



const FormItem = Form.Item;
const { Option } = Select;
class NewSiteSP extends React.Component{
    constructor(props){
    super(props)
    this.state={
        sPlan:[],
        selectedPL: undefined,
        selectedMS: this.props.ms ? this.props.ms : [],
        serviceData: undefined,
        fData: [],
        miCheck: false,
        recData: null,
        isEdit: '',
        visibleM: false,
        paymentM: false,
        paymentLoading: false,
        rate:undefined,
        dataSource: [],
        count: 1,
    }
}

componentDidMount(){
    this.getAllSP()
    this.getSiteConfig()
    this.getSiteSP()
}
showModal = () => {
  this.setState({
    visibleM: true
  })
}
modalChange=()=>{
  this.setState({
    paymentM: true,
  })
}
getSiteConfig = () => {
  this.props.client.query({
      query: getSiteConfig,
      variables: {
          domain: this.props.siteResp.fqdn
      },
      fetchPolicy: 'network-only'
  })
      .then(({ data }) => {
          console.log('dataa', data)
          this.setState({
              serviceData: data.getSiteConfig,
              loading: false
          })
      })
      .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
      })
}
  handleOk = (e) => {
    // console.log("on ok")  
    e.preventDefault();
    this.props.form.validateFields(["amount","note"],(err, values) => {
      console.log("handleOk",values)
      // return
      if (!err) {
        this.setState({
          paymentLoading: true
        })
        this.props.client.mutate({
          mutation: siteWalletRechargeByAdmin,
          variables: {
            siteId: this.props.siteResp.siteId,
            amt: parseInt(values.amount * 100),
            currency: this.props.siteResp.curr,
            note: values.note ? values.note : undefined,
            rechargeBy: "Admin",
            paymentState: "PAID_OFFLINE"
          }
        }).then(({ data }) => {
          // console.log("data response", data)
          message.success("Recharge successfull");
          this.props.form.resetFields(['amount'])
          this.props.form.resetFields(['note'])
          this.getRecharge()
          this.setState({
            paymentLoading: false
          })
        })
      }
    })
  }
handleSubmit = () =>{
  this.props.form.validateFields( ["planId","chargeModal","rate","startDate","endDate","status"],async(err, values) => {
      if(!err){
        console.log("values",values)
        console.log("values",this.state.selectedPL)
        let plan = this.state.selectedPL
        let newData = {
          planId: plan.planId,
          planName: plan.planName,
          masterService: plan.masterService,
          platform: plan.platform,
          siteType: plan.siteType,
          services: plan.services,
          description: plan.description,
          paymentGateway: 'MANUAL',
          curr: plan.curr,
          amount: parseInt(values.rate*100),
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          endDate: moment(values.endDate).format("YYYY-MM-DD"),
          status: values.status,
          chargingModel: values.chargeModal,
      };
      let rep = await this.createRecurringSer(newData)
      let spRep =await this.createSP(newData)
      this.props.form.resetFields();
      let arr = this.state.dataSource
      arr.push(newData)
      let arr1 = this.state.selectedMS
            arr1.push(plan.masterService)
      this.setState({
        dataSource: arr,
        selectedMS:arr1,
        visibleM: false,
        rate: undefined
      })
      
      }
    })
    }
    createRecurringSer=(records)=>{
      return new Promise((resolve,reject)=>{
        console.log("records In createRecurringSer ::",records)
        // resolve(true)
        // return
        let ids = this.props.siteResp
        let date = new Date()
        let epo = Math.floor(date/1000)
        let TempArr = []
        let pConfig = this.state.serviceData != undefined ? this.state.serviceData.siteServices : []
       pConfig.map((val)=>{
          delete val.__typename
          TempArr.push(val)
        })
        let obj = {
          charges:parseInt(records.amount),
          chargingModel: records.chargingModel,
          masterStatus: records.status,
          serviceName: records.masterService,
          startedAt: epo,
          status: records.status,
          masterStatus: true,
          startDate: records.startDate ?  records.startDate : undefined,
          endDate: records.endDate ? records.endDate : undefined,
          updatedAt: epo
         }
         console.log("obj::",obj)
         TempArr.push(obj)
         this.props.client.mutate({
          mutation: this.state.serviceData != null ? updateSiteConfig : createSiteConfig,
          variables: {
              domain: ids.fqdn,
              siteId: ids.siteId,
              enabled: true,
              siteServices: TempArr
          }
      }).then(({ data }) => {
          console.log("createSiteConfig response ::", data)
        message.success("Service Created Successfully");
        this.getSiteConfig()
        resolve(true)
      })
      })
    }
    createSP=(val)=>{
      return new Promise((resolve,reject)=>{
        let ids = this.props.siteResp
        console.log("createSP ::",val)
        // resolve(true)
        // return
        this.props.client.mutate({
          mutation: subscribeSitePlanByAdmin ,
          variables: {
            siteId: ids.siteId,
          planName: val.planName,
          status: val.status,
          masterService: val.masterService,
          platform: val.platform,
          siteType: val.siteType,
          amount: val.amount,
          chargingModel: val.chargingModel,
          description: val.description,
          services: val.services,
          paymentGateway: val.paymentGateway,
          curr: val.curr
          }
      }).then(({ data }) => {
        console.log("subscribeSitePlanByAdmin::",data)
        resolve(true)
      })
      })
    }
getAllSP=()=>{
    this.props.client.query({
      query: getSubscriptionPlansBySiteId,
      variables: {
          siteId: "master"
      },
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        let siteType = this.props.siteResp.siteType
        let platform = this.props.siteResp.siteOptions
        let arr  = data.getSubscriptionPlansBySiteId
                  let sort = arr.filter( function(hero){
                    return hero.siteType == siteType && hero.platform == platform
                  })
                  sort.sort((a,b)=>a.planName.localeCompare(b.planName))
        this.setState({
          sPlan:sort,
          loading: false
        })
      })
  }
  getSiteSP=()=>{
    this.props.client.query({
      query: getSubscriptionPlansBySiteId,
      variables: {
          siteId: this.props.siteResp.siteId
      },
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        console.log('dataa', data)
        let ms= this.state.selectedMS
        this.setState({
          dataSource:data.getSubscriptionPlansBySiteId,
          miCheck:data.getSubscriptionPlansBySiteId.length > 0 ? true : false, 
          // selectedMS: data.getSubscriptionPlansBySiteId.length > 0 ? ms : [],
          loading: false
        })
      })
  }
  getRecharge=()=>{
    this.props.client.query({
      query: getSiteWalletById,
      variables: {
          siteId: this.props.siteResp.siteId
      },
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        // console.log('dataa', data)
        this.setState({
          currentB: data.getSiteWalletById,
          loading: false
        })
      })
  }

  handleSPChange=(index)=>{
    // console.log("index",index)
    let plan = this.state.sPlan.find(u => u.planId === index);
    let recData = this.props.recData != null ?  this.props.recData : []
    let pr = ''
        pr = recData.find(u => u.serviceName === plan.masterService);
        console.log("pr",pr)
    console.log("plan",plan)
    this.setState({
        recData: pr ? pr: null ,
        selectedPL:plan,
    })
  }
  handleChargeChange=(index)=>{
    // console.log("handleChargeChange ::",index)
      let rate = this.state.selectedPL.priceList.find(u => u.chargingModel === index);
    console.log("handleChargeChange ::",rate)

      this.setState({
        rate:rate
      })
  }
  handleStartDate=(data,index)=>{
    // console.log("handleStartDate ::",data,index)
    let newData = data
    newData['startDate'] = moment(index).format("YYYY-MM-DD")
      this.setState({
        fData:[ newData],
      })
  }
  handleEndDate=(data,index)=>{
    // console.log("handleEndDate ::",data,index)
    let newData = data
    newData['endDate'] = moment(index).format("YYYY-MM-DD")
    //   console.log("newData",newData)
      this.setState({
        fData:[ newData],
      })
  }
  handleStatusChange=(data,index)=>{
    // console.log("handleStatusChange ::",data,index)
      let newData = data
    newData['status'] = index
    //   console.log("newData",newData)
      this.setState({
        fData:[ newData],
      })
  }
  handlePriceChange = (data,index)=>{
    // console.log("handlePriceChange ::",index.target.value)
      let newData = data
    newData['price'] = index.target.value
      console.log("newData",newData)
      this.setState({
        fData:[ newData],
      })
  }
  handleEdit = (key) => {
    // const dataSource = [...this.state.dataSource];
    // this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    this.setState({
      isEdit: key
    })
  };
  handleSave =(record)=>{
      console.log("record ::",record)
        this.setState({
            isEdit:'',
          })
  }
  handleCancel=(key)=>{
    this.setState({
      isEdit:'',
    })
  }
  handlePMCancel=()=>{
    this.setState({
      paymentM:false,
    })
  }
  onRadioChange = e => {
    this.setState({
      miCheck: e.target.value,
    });
  };
  
render(){
  // console.log("miCheck",this.state.selectedMS, this.state.miCheck)
  const { getFieldDecorator, getFieldValue } = this.props.form;
  let bb=  this.state.currentB && this.state.currentB.balance ? this.state.currentB.balance/100 : "0.00"
  let balane =  bb.toLocaleString(`en-${this.props.siteResp.curr == 'INR' ? 'IN' : this.props.siteResp.curr == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: this.props.siteResp.curr })
    const { dataSource } = this.state;
    const columns = [
        {
          title: 'Plan Name',
          width: '20%',
          render:(data)=>{
              return(
                <>
                {/* {this.state.isEdit == data.planId ? 
                <Select style={{ width: '100%' }} onChange={(e) => this.handleSPChange( e)} placeholder="Select Service" defaultValue={data.name} >
                {this.state.sPlan.map((val,i)=>{
                    return(
                    <Option value={val.planId} key={i} 
                    >{val.planName}</Option>
                    )
                })}
          </Select>
          : */}
          <span>{data.planName}</span>
              {/* } */}
          </>
              )
          }
        },
        {
          title: 'Charge Model',
         render:(data)=>{
             return(
              <>
              {this.state.isEdit == data.planId ? 
                <Select style={{ width: '100%' }} onChange={(e) => this.handleChargeChange(data, e)} placeholder="Select Service" defaultValue={data.chargingModel}>
                {this.state.selectedPL ? this.state.selectedPL.priceList.map((val,i)=>{
                    // console.log("val",val)
                    return(
                    <Option value={val.chargingModel}>{val.chargingModel}</Option>
                    )
                }) : ''}
          </Select>
          : 
          <span>{data.chargingModel}</span>
              }
              </>
             )
         }
        },
        {
          title: 'Start Date',
          width: '13%',
          render:(data)=>{
            return(
              <>
              {this.state.isEdit == data.planId ? 
                <DatePicker defaultValue={moment(data.startDate)} format="YYYY-MM-DD" onChange={(e)=> this.handleStartDate(data,e)}></DatePicker>
            : 
            <span>{data.startDate}</span>
                  }
                      </>
                )
        }
        },
        {
          title: 'End Date',
          width: '13%',
          render:(data)=>{
            return(
              <>
              {this.state.isEdit == data.planId ? 
                <DatePicker  defaultValue={moment(data.endDate)} format="YYYY-MM-DD" onChange={(e)=> this.handleEndDate(data,e)}></DatePicker>
                : 
                <span>{data.endDate}</span>
              }
                  </>
                )
        }
        },
        {
          title: 'Rate',
          width: '10%',
          render:(data)=>{
            return(
                <>
                  {this.state.isEdit == data.planId ? 
                        <Input value={data.amount ? data.amount : ''} onChange={(e) => this.handleamountChange(data, e)} type="Number" />
                        :
                        <span>{data.amount ? data.amount/100 : ''}</span>
                    }
                </>
            )
        }
        },
        {
          title: 'Billing Status',
          width: '13%',
          render:(data)=>{
            return(
              <>
               {this.state.isEdit == data.planId ? 
                <Select style={{ width: '90px' }} defaultValue={data.status} onChange={(e)=> this.handleStatusChange(data, e)} >
                <Option value={"ACTIVE"}>Active</Option>
                <Option value={"TRIAL"}>Trial</Option>
                <Option value={"FREE"}>Free</Option>
              </Select>
              :
              <span>{data.status}</span>}
              </>
            )
        }
        },
        {
          title: 'Action',
          width: '10%',
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
                <>
                {this.state.isEdit != record.planId ? 
                <Button icon='edit' onClick={() => this.handleEdit(record.planId)}></Button>
              :
              <>
               <Popconfirm title="Sure to Save?" onConfirm={() => this.handleSave(record)}>
                <Button icon='save' style={{marginRight: '5px'}}></Button>
              </Popconfirm> 
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.handleCancel(record)}>
               <Button icon="close" type= "danger"  style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000", color:"#fff" }}/>
              </Popconfirm>
              </>
              }
              </>
            ) : null,
        },
      ];
    const columns1 =  [
      {
        title: 'Plan Name',
        width: '20%',
        dataIndex: 'planName'
      },
      {
        title: 'Charge Model',
        dataIndex: 'chargingModel'
      },
      {
        title: 'Master Service',
        dataIndex: 'masterService'
      },
      {
        title: 'Rate',
        width: '10%',
        render:(data)=>{
          return(
              <span>{data.amount ? data.amount/100 : ''}</span>
          )
      }
      },
      {
        title: 'Payment Gateway',
        dataIndex: 'paymentGateway'
      },
      {
        title: 'Billing Status',
        width: '13%',
        dataIndex: 'status'
      },
     
    ];
    return(
        <div>
          {!this.props.isEdit &&
          <p style={{ textAlign: 'end' }}>
                                <Button type="dashed" style={{  marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                   <span>Balance: <b>{balane}</b></span>
                                </Button>
             
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} onClick={()=>this.modalChange()} >
                                   Re-Charge
                                </Button>
                        </p>
          }
        <Button onClick={this.showModal} type="primary" style={{ marginBottom: 16 }}>
        Add Subscription Plan
        </Button>
        <Radio.Group disabled onChange={this.onRadioChange} value={this.state.miCheck}>
        <Radio value={true}>A</Radio>
        <Radio value={false}>B</Radio>
      </Radio.Group>
        <Table
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ dataSource}
          columns={this.props.isEdit ? columns1 : columns}
        />
        {!this.props.isEdit &&
       ( 
         <>
        <Divider style={{ fontSize: "20px" }}>One Time Services</Divider>
        <AddExtraService siteData={this.props.siteResp} getRecharge={()=>this.getRecharge()}/>
        </>
       )
      }
        <Modal
          title={"Add Subscription Plan"}
          visible={this.state.visibleM}
          onOk={() => { this.setState({ visibleM: false }) }}
          onCancel={() => { this.setState({ visibleM: false }) }}
          footer={null}
          width={1300}
        >
                <Form style={{ marginTop: '10px' }}>
                  <Row gutter={16}>
                    {this.props.isEdit ?

                  
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem
                                label="Select Plan"
                            >
                                {getFieldDecorator(`planId`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Plan Name",
                                    }]
                                })(
                                  <Select style={{ width: '100%' }}  onChange={(e) => this.handleSPChange( e)}  placeholder="Select Service" >
                                  {this.state.sPlan.map((val,i)=>{
                  
                                      return(
                                      <Option value={val.planId} key={i} 
                                      disabled={ !this.state.miCheck ? !this.state.selectedMS.includes(val.masterService) :  this.state.selectedMS.includes(val.masterService)}
                                      >{val.planName}</Option>
                                      )
                                  })}
                            </Select>
                                )}
                            </FormItem>
                        </Col>
                        : 
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem
                                label="Select Plan"
                            >
                                {getFieldDecorator(`planId`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Plan Name",
                                    }]
                                })(
                                  <Select style={{ width: '100%' }}  onChange={(e) => this.handleSPChange( e)}  placeholder="Select Service" >
                                  {this.state.sPlan.map((val,i)=>{
                  
                                      return(
                                      <Option value={val.planId} key={i} 
                                      disabled={ this.state.selectedMS.includes(val.masterService) }
                                      >{val.planName}</Option>
                                      )
                                  })}
                            </Select>
                                )}
                            </FormItem>
                        </Col>
                      }
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem
                                label="Select Charge Model"
                            >
                                {getFieldDecorator(`chargeModal`, {
                                   initialValue: this.state.recData ? this.state.recData.chargingModel : null,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Plan Name",
                                    }]
                                })(
                                  <Select style={{ width: '100%' }} onChange={(e) => this.handleChargeChange(e)} placeholder="Charge Model" >
                                    {this.state.selectedPL ? this.state.selectedPL.priceList.map((val, i) => {
                                      // console.log("val",val)
                                      return (
                                        <Option value={val.chargingModel}>{val.chargingModel}</Option>
                                      )
                                    }) : ''}
                                  </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label="Rate">
                                {getFieldDecorator(`rate`, {
                                   initialValue: this.state.recData ? this.state.recData.charges/100 : this.state.rate ? this.state.rate.rate/100 : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter rate",
                                    }]
                                })(<Input placeholder="Rate" style={{  marginRight: 8 }} />)}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label="Start Date">
                                {getFieldDecorator(`startDate`, {
                                   initialValue: this.state.recData && this.state.recData.startedAt ?  moment.unix(this.state.recData.startedAt)  : null,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Start Date",
                                    }]
                                })(<DatePicker   format="YYYY-MM-DD"></DatePicker>)}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label="End Date">
                                {getFieldDecorator(`endDate`, {
                                   initialValue: this.state.recData && this.state.recData.endDate ?  moment.unix(this.state.recData.endDate)  : null,
                                    rules: [{
                                        required: false,
                                        message: "Please enter End Date",
                                    }]
                                })(<DatePicker  format="YYYY-MM-DD"></DatePicker>)}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label="Billing Status">
                                {getFieldDecorator(`status`, {
                                  initialValue: this.state.recData ? this.state.recData.status : null,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Billing Status",
                                    }]
                                })(<Select >
                                <Option value={"ACTIVE"}>Active</Option>
                                <Option value={"TRIAL"}>Trial</Option>
                                <Option value={"FREE"}>Free</Option>
                              </Select>)}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                  </Form>

        </Modal>
        <Modal
                    title="Wallet Recharge"
                    visible={this.state.paymentM}
               
                    onCancel={this.handlePMCancel}
                    footer={null}
                  >
                    <Row>
                    <Form style={{marginTop: '10px'}}>
                    <FormItem  label="Recharge Amount">
                                {getFieldDecorator(`amount`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Recharge Amount between 100 To 100000",
                                    },
                                    ,
                                    {
                                      validator: (rule, value, cb) => {
                                          if (value <1) {
                                                  cb('Please enter Recharge Amount Grater than 100')
                                          }
                                          cb()
                                      }
                                  }
                                   ]
                                })(
                                  <Input   type= "number" placeholder="Enter Recharge Amount" style={{width:"50%"}} onChange={this.rAmount}></Input>
                                )}
                            </FormItem>
                    </Form>
                    <FormItem label='Notes' >
                                    {getFieldDecorator('note', {
                                       
                                        rules: [{
                                            required: true,
                                            message: "note",
                                        }],
                                    })(
                                        <Input placeholder="Note" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                    </Row>
                    <Row>
                      <Button type="primary" onClick={this.handleOk} style={{marginTop: "20px"}} loading={this.state.paymentLoading}>Submit</Button>
                    </Row>
                  </Modal>
        </div>
    )
}
}
const WrappedNewSiteSP = Form.create({ name: "SP" })(
  NewSiteSP
);
export default withApollo(WrappedNewSiteSP)