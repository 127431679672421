import gql from 'graphql-tag'

export default gql`
mutation createGroupAdmin(
    $email: String!
    $mobile: String!
    $userName: String!
    $custName: String
    $companyName: String
      $address: GenericAddressInput
      $outlets: [OutletInput!]
      $enabled: Boolean
  ){
    createGroupAdmin(input:{
      email: $email
      mobile: $mobile
      userName: $userName
      custName: $custName
      companyName: $companyName
      enabled: $enabled
      address: $address
      outlets: $outlets
    }){
      groupId
      typeValue
      email
      mobile
      userName
      custName
      companyName
      enabled
      address{
        city
        country
      }
    }
  }
`
//  {
//        "email": "sohelkhan@amonex.in",
//        "mobile": "8459832342",
//        "userName": "outlet_test",
//        "custName": "Sohel",
//        "companyName": "recaho",
//        "enabled": true,
//        "address": {
//          "city": "pune",
//          "country": "IND"
//        },
//        "outlets": [
//          {
//            "siteId": "43b035b0-0aa0-4a17-a8d8-0c51069f2b74",
//            "typeValue": "fqdn::www.prince.com"
//          },
//          {
//            "siteId": "41597544-1520-4888-95d3-e776e67f0b39",
//            "typeValue": "fqdn::testqrcode.inmyarea.in"
//          }
//        ]
// }
