import React, { Component } from 'react';
import moment from 'moment'
import {  withApollo } from 'react-apollo';
import { Button,  Icon, Input, Row,Col,Table,message,Select,Form, } from 'antd';
import SiteActionEventQuery from '../../funComponent/SiteActionEventQuery'
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const { TextArea } = Input;
const FormItem = Form.Item;
class SiteLogs extends Component {
    constructor(props){
        super(props)
        this.state={
            notesList:[],
            notesgetLoading: false,
            selectedCategory: undefined
        }
    }
    componentDidMount(){
        let searchData = JSON.stringify({ siteId:  this.props.siteDetails.siteId,  })
        let param = {
          "searchValue": searchData,
          "skips": 0,
          "limits": 100
        }
        this.getNotes(param)
    }
    getNotes=async(param)=>{
        // console.log("param ::",param)
        this.setState({
          notesgetLoading: true
        })
        let notesList = await SiteActionEventQuery(this.props,param)
        let arr = notesList && notesList.getSiteActionEventsFilter ? notesList.getSiteActionEventsFilter :[]
        arr.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1 })
        this.setState({
          notesList: arr,
          notesgetLoading: false
        })
      }
      onCategoryChange=(value)=>{
        this.setState({
          selectedCategory: value
        })
      }
      textAreaClick=()=>{
        this.setState({
            saveButton: true
        })
      }
      textAreaChnages=(e)=>{
          this.setState({
              notes: e.target.value
          })
      }
      ented=()=>{
          this.setState({
              saveButton: false
          })
      }
      handleSubmit = async()=> {
        if (this.state.notes && this.state.notes != '' && this.state.selectedCategory) {
          this.setState({
            notesLoading: true
          })
          let obj={
            siteId: this.props.siteDetails.siteId,
            eventName: this.state.selectedCategory,
            eventType: 'MANUAL',
            note: this.state.notes
          }
          let resp = await SiteActionEvent(this.props,obj)
          if(resp){
            message.success("Notes added successfully")
            // console.log("resp :::",resp.createSiteActionEvent)
            let allNotes = this.state.notesList
            allNotes.push(resp.createSiteActionEvent)
            allNotes.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1 })
            this.setState({
              notesLoading: false,
              notesList: allNotes,
              selectedCategory: undefined,
              notes: undefined
            })
          }else{
            message.error("something went wrong")
            this.setState({
              notesLoading: false
            })
          }
        }else{
          if(this.state.selectedCategory){
            message.error("Please Enter Notes")
          }else{
          message.error("Please Select Notes Category")
        }
        }
      }
    render() {
        const { getFieldDecorator } = this.props.form;
        const columnsNotes=[
            {
              title: 'Created Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              width: 150,
              sorter: (a, b) => {
                return a.createdAt -  b.createdAt
              },
              render:(data)=>{
                return(
                  <div>
                 {data != null && 
                 moment.unix(data).format("DD/MM/YYYY HH:mm")
                 }
                 {data == null && <span>--</span>}
                 </div>
                )
              }
            },
            {
              title: 'Category',
              dataIndex: 'eventName',
              width: 100,
            },
            {
              title: 'Reason',
              dataIndex: 'reason',
              width: 100,
            },
            {
              title:'User',
              dataIndex:'createdBy',
              width: 200,
            },
            {
              title:'Note',
              dataIndex:'note'
            }
          ]
        return (
            <Form>
            <Row gutter={16}>
             
            <div className='planCardSection' >
                <p class='planCardSectionText' style={{fontWeight: 700}}>History</p>
                <Table
                    className='sitetable'
                    columns={columnsNotes}
                    bordered
                    dataSource={this.state.notesList}
                    loading={this.state.notesgetLoading}
                    size="middle"
                    pagination={{ pageSize: 5 }}
                />
                <p class='planCardSectionText' style={{fontWeight: 700}}>Add New Notes</p>
                 <Select
                placeholder={"Select Category"}
                onChange={this.onCategoryChange}
                value={this.state.selectedCategory}
                >
                  <Select.Option key="Billing" value="Billing">Billing</Select.Option>
                  <Select.Option key="Complaint" value="Complaint">Complaint</Select.Option>
                  <Select.Option key="Customer Requirement" value="Customer Requirement">Customer Requirement</Select.Option>
                  <Select.Option key="Onboarding" value="Onboarding">Onboarding</Select.Option>
                  <Select.Option key="Setup" value="Setup">Setup</Select.Option>
                  <Select.Option key="Support" value="Support">Support</Select.Option>
                  <Select.Option key="Renewal Notes" value="Renewal Notes">Renewal Notes</Select.Option>

              </Select>
            <FormItem label='' style={{marginTop: '5px'}}>
            {getFieldDecorator('note', {
                    rules: [{
                        required: false,
                        message: "Please enter note",
                    }],
                })(
                    <div style={{ border:this.state.saveButton ? '1px solid' : 'none',borderColor: this.state.saveButton ? '#0170fe' : 'none', boxShadow: this.state.saveButton && '0 0 4px #1c9dfb80' }}>
                    <TextArea value={this.state.notes} placeholder='Add a note' onFocus={this.textAreaClick} onChange={this.textAreaChnages} rows={4} style={{border: this.state.saveButton && 'none' }}/>
                    {this.state.saveButton &&
                        <div style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={this.handleSubmit} loading={this.state.notesLoading} style={{ marginRight: '5px',height: '25px'}} >SAVE</Button>
                        </div>
                     }
                    </div>
                )}
            </FormItem>
            </div>
            </Row> 
            </Form>
        );
    }
}
const WrapSiteLogs = Form.create()(SiteLogs);
export default withApollo(WrapSiteLogs);