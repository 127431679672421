import gql from 'graphql-tag'
export default gql`
mutation addPlatformService(
  $siteId: String!
  $slugServiceName: String!
  $serviceName: String!
  $serviceType: String
  $status: SERVICE_STATUS
  $masterService: String!
  $platform: SITE_OPTIONS
  $siteType: SITE_TYPE
){
addPlatformService(input: {
  siteId: $siteId
  serviceName: $serviceName
  slugServiceName: $slugServiceName
  status: $status
  serviceType: $serviceType
  masterService: $masterService
  platform: $platform
  siteType: $siteType
}){
  siteId
  serviceId
  serviceName
  slugServiceName
  status
  serviceType
  masterService
  platform
  siteType
  createdAt
}
}
`
//  {
//        "siteId": "master",
//        "slugServiceName": "test",
//        "serviceName": "test",
//        "status": "ACTIVE",
//        "masterService": "SITE",
//        "platform": "BOOKING_SALOON"
//      }
    