import React from 'react';
import { Form, Input,  Button, Col,Row,message,DatePicker } from 'antd';
import { withApollo } from "react-apollo";
import moment from 'moment';
import generateSiteInvoice from '../../mutation/generateSiteInvoice'
import sendWhatsApp from '../../mutation/sendWhatsApp'

const FormItem = Form.Item;
class Invoice extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loading: false
        }
    }
    handleSubmit = (e,flag) =>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
           if (!err) {
            this.enterLoding()
            // console.log("values ::",e,flag)
            // return
            this.props.client.mutate({
                mutation: generateSiteInvoice,
                variables: {
                    siteId: this.props.siteData.siteId,
                    transactionId: this.props.record.transactionId,
                    date: moment(values.date).format("YYYY-MM-DD"),
                    billTo: values.billTo,
                    custMob: values.custMob,
                    custName: values.custName,
                    serviceName: values.serviceName,
                    amt: values.amt,
                    total: values.amt
                }
            }).then(({ data }) => {
                console.log("data response",data)
                if(data.generateSiteInvoice){
                    if(flag){
                        let file = data.generateSiteInvoice.invoicePdf
                        let link = `https://${file.bucket}.s3.amazonaws.com/${file.key}`
                        this.sendWhatsAppFunction(link,values.custMob,this.props.siteData.curr)
                    }
                    this.setState({
                        loading: false
                    })
                }
                    
                })
           }
        })
    }
    enterLoding=()=>{
        this.setState({
            loading: true
        })
    }
    sendWhatsAppFunction=(link,custMob,curr)=>{
        let mobile = curr == 'INR' ? `+91${custMob}` : curr == 'GBP' ? `+44${custMob}` : `+91${custMob}`
        this.props.client.mutate({
            mutation: sendWhatsApp,
            variables: {
                type: "MEDIA",
                toNumber: mobile,
                content: "Please find attached your invoice for the payment made. Thank you, and we look forward to working with you",
                imageLink: link,

            }
        }).then(({ data }) => {
            if (data.sendWhatsApp) {
                message.success("Invoice Sent Successfully")
            }
        })
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let dateT = moment().format('YYYY-MM-DD');
        const siteData = this.props.siteData
        const trData = this.props.record
        return (
            <Form  style={{marginTop: '10px'}}>
               <Row gutter={16}>
               <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Company Name"
                            >
                                {getFieldDecorator(`billTo`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: siteData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Company Name",
                                    }]
                                })(
                                    <Input placeholder="Company Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Customer Name"
                            >
                                {getFieldDecorator(`custName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: siteData.custName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Customer Name",
                                    }]
                                })(
                                    <Input placeholder="Customer Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Customer Mobile"
                            >
                                {getFieldDecorator(`custMob`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: siteData.contactNumber,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Customer Mobile",
                                    }]
                                })(
                                    <Input placeholder="Customer Mobile" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: trData.note,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Amount"
                            >
                                {getFieldDecorator(`amt`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: trData.amount/100,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Amount",
                                    }]
                                })(
                                    <Input placeholder="Amount" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Date"
                            >
                                {getFieldDecorator(`date`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: moment(dateT),
                                    rules: [{
                                        required: true,
                                        message: "Please enter Date",
                                    }]
                                })(
                                    <DatePicker />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit(e,false)} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginRight:"20px" }} >
                            Generate Invoice
                            </Button>
                            <Button type="primary" onClick={(e)=>this.handleSubmit(e,true)} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginRight:"20px" }} >
                            Generate & Send
                            </Button>
                        </Col>    
                    </Row>
            </Form>
        );
    }
}
const WrappedInvoice = Form.create({ name: "JInvoice_form" })(
    Invoice
  );
export default withApollo(WrappedInvoice);