import React from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
// import Editor from "@monaco-editor/react";
import { Form, Button, Row,Col , Input, message,Radio,Popover,Select,Icon,Upload,Modal} from 'antd';
import Resizer from "react-image-file-resizer";
import uuidv1 from 'uuid/v1';
import slugify from 'slugify';
import CreateSitePages from '../../mutation/createStaticPages'
import FroalaEditor from '../../funComponent/froalaEditor'
import App from '../../App'
const RadioGroup = Radio.Group;
const FormItem = Form.Item
const bucket = process.env.REACT_APP_BUCKET
const S3_REGION = process.env.REACT_APP_S3_REGION



  

class CreateStaticPage extends React.Component {
    constructor(props){
        super(props)

        this.state = {
          loading:false,
          iconImg: {},
          removedIconImg: false,
          resizedIcon: [],
          modelNew: '',
          imgObj: {},
          showOnFooter:true,
          onHome:false,
          value: "BOTH",
          pageType: "FROALA",
          tags: [],
          tagsArray:[
            { value:'CONTACTS', label:'Contacts'},
            { value:'CONTENTS', label:'Contents'},
            { value:'CUSTOM', label:'Custom'},
            { value:'FEATURES', label:'Features'},
            { value:'FOOTERS', label:'Footers'},
            { value:'FORM', label:'Form'},
            { value:'HEADERS', label:'Headers'},
            { value:'PRICING', label:'Pricing'},
            { value:'TEAMS', label:'Teams'},
            { value:'TESTIMONIALS', label:'Testimonials'},
            { value:'CallToAction', label:'Call to Action'},
            { value:'COVERS', label:'Covers'},
            { value:'BLOG', label:'Blog'},
            { value:'ECOMMERCE', label:'E-commerce'},
            { value:'MAGAZINE', label:'Magazine'},
            { value:'PROJECTS', label:'Projects'},
            { value:'COUNTERS', label:'Counters'},
            { value:'DOWNLOAD', label:'Download'},
            { value:'EXPLORE', label:'Explore'},
            { value:'FAQ', label:'FAQ'},
            { value:'GALLERY', label:'Gallery'},
            { value:'MAPS', label:'Maps'},
            { value:'CUSTOMERS', label:'Customers'},
            { value:'PORTFOLIO', label:'Portfolio'},
            { value:'STEPS', label:'Steps'},
            { value:'SERVICE', label:'Service'},
            { value:'SIGNUP', label:'Sign up'},
            { value:'SUBSCRIBE', label:'Subscribe'},
            { value:'VIDEO', label:'Video'},
            { value:'ADMIN', label:'Admin'},
            { value:'INDUSTRY', label:'Industry'},
            { value:'POLICIES', label:'Policies'},
          { value:'LANDING_PAGE', label:'Landing Page'},
          { value:'TEMPLATES', label:'Templates'},
          { value:'PLUGINS', label:'Plugins'},
          { value:'COMPONENTS', label:'Component'},
          { value:'SINGLE_PAGE_SITE', label:'Single Page Site'},
          { value:'DATA', label:'Data'},




          ]
        }
    }


    
  componentDidMount(){
    let arr = this.state.tagsArray
    arr.sort(function (a, b) {
        var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
        if (nameA < nameB)
            return -1;
        if (nameA > nameB)
            return 1;
        return 0;
    });
    this.setState({
        tagsArray: arr
    })
  }



    enterLoading = () =>{
        this.setState({loading:true})
    }

    message = () =>{
        this.props.history.push('/site-pages')
    }
    onChangePageType = e =>{
      // console.log("pagetype seleted",e.target.value)
      this.setState({
        pageType: e.target.value
      })
    }
    onChange = e => {
      // console.log('radio checked', e.target.value);
      this.setState({
        value: e.target.value,
      });
    }
    handleChangetags=(value)=>{
      this.setState({
        tags:value
      })
    }
   
    handleSubmit = (e) =>{
        e.preventDefault()
        this.props.form.validateFields((err,values)=>{
          let iconImage = {}
          values.description = this.state.modelNew ? this.state.modelNew : values.descriptionHTML;
            if(!err){
              if (values.description == '') {
                this.props.form.setFields({
                  description: {
                    value: values.description,
                    errors: [new Error('Please input  Content ')]
                  }
                });
                return true;
              } else {
                this.props.form.setFields({
                  description: {
                    value: values.description,
                    errors: ''
                  }
                });
              }
              if(Object.keys(this.state.iconImg).length !== 0){
                iconImage = {
                    bucket:bucket,
                    region:S3_REGION,
                    localUri: this.state.resizedIcon[0].url,
                    key:`internalAdmin/${uuidv1()}`,                
                    mimeType: this.state.resizedIcon[0].type
                }
            }else{
                iconImage =null
            }
                console.log("values",iconImage)
                // return false
                this.enterLoading()
                const pageName = slugify(values.pageTitle, {
                  lower: true
              })
                this.props.createSitePages({
                    variables:{
                        // siteId:this.props.customSiteId,
                        pageName:pageName,
                        displayName: values.pageTitle,
                        value: values.description,
                        onFooter:this.state.showOnFooter,
                        onHome:this.state.onHome,
                        pageFor: this.state.value,
                        pageType: this.state.pageType,
                        image: iconImage,
                        tags: this.state.tags.length >0 ? this.state.tags : undefined
                      }
                }).then(({data})=>{
                    if(data.createSitePage){
                        message.success('Page Created Successfully',this.message)
                    }
                   
                })
            }
        })

    }

  
  showOnFooter = (e)=>{
    this.setState({showOnFooter:e.target.value})
  }

  showOnHome = (e)=>{
    this.setState({onHome:e.target.value})
  }
  handleMainImageRemove = (file) => {
    this.setState({ iconImg: {}, removedIconImg: true })
    return true;
}
  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
      this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
      });
  }
  handleMainImage = ({ file }) => {
    this.setState((prevState, props) => {
        if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
            return{
                iconImg: file,
                removedIconImg: false
            }
        }
        else{
            return{
                // iconImg: {},
                removedIconImg: false
            }
        }
        
    },this.imageResize1(file));
    
}

imageResize1 = (img) => {
    let obj = {};
    let arr = []
    // console.log("Inresize",img)
    // return
    Resizer.imageFileResizer(
      img.originFileObj,
      1000,
      1000,
      "WEBP",
      80,
      0,
      (uri) => {
        // console.log("Aft resize",uri)
        obj={
          name: img.name,
          size: uri.size,
          type: uri.type,
          uid: img.uid,
          url: uri,
        }
       // console.log("After Resize", uri,obj);
        arr.push(obj)
        this.setState({
          // Img: uri,
        //   originFileObj: uri,
          resizedIcon: arr,
        });
      },
      "blob"
    );
    //  }
  };
  config = {
    key:process.env.froalaKey,
    // placeholder: 'Edit sdfdfMe',
    height:270,
    // width: 950,
    events: {
      // 'froalaEditor.focus' : function(e, editor) {
      //   console.log(editor.selection.get());
      // },
      // 'froalaEditor.image.uploadedToS3' : function(e, editor, link, key, response){
      //   console.log(e,response,'in')
      // },
      // 'froalaEditor.image.beforeUpload' : function(e, editor, images){
      //   console.log(e,editor,'in')
      // },
    },
    imageUploadToS3: this.props.location.state.imgData,
    toolbarButtons: {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
        'align': 'right',
        'buttonsVisible': 2
      }
    },
  
    // Change buttons for XS screen.
    toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']]
    // toolbarButtonsXS: ['undo', 'redo' , 'bold', 'italic', 'underline','strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html','insertImage','insertLink','insertVideo','uploadFile','Emoticons','special characters','code view','Print','Help','Quote','Font Family','Font Size','colors','Inline class','inline style','paragraph style','Line Height','paragraph format']
  };
  handleModelChange = modelNew => {
    this.setState({ modelNew: modelNew });
    // this.props.updateState(this.state.modelNew);
  };

  onPreviewChange =(value)=>{
    // console.log("f val",value)
    this.setState({
      finalVal:value
    })
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const { previewVisible, previewImage, iconImg } = this.state;
    const uploadButton = (
      <div>
          <Icon type="plus" />
          <div>Upload</div>
      </div>
      
      );
    const googleAppID = (
      <div>
          <p>1. Go to  <a href="https://froala.com/pages/demo/" target="_blank">
          https://froala.com/pages/demo/</a></p>
          <p>2. Select and drag the design (one or many) on the design board on the left side </p>
          <p>3. Edit to update your details and replaces images if required </p>
          <p>4. Click on Download html and save file  on your desktop </p>
          <p>5. Click on "3 dots" on the Froala editor </p>
          <p>6. Click on &#60; &gt; called as Code View </p>
          <p>7. Open your html file in notepad </p>
          <p>8. Press Ctrl+A and Ctrl+C to copy all the contents</p>
          <p>9. Press Ctrl+P to past it in the froala window </p>
          <p>10. Press of &#60; &gt; again</p>
          <p>11. The design should show on your froala page </p>
          <p>12. You can edit the design from here as well.</p>
          <p>13. You are now all set to add it to home page or a new Page.</p>
      </div>
    )
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 18 },
    }

   

    return (
        <App header={"Static Pages"}>
        <Form layout='vertical' onSubmit={this.handleSubmit}>
          <Row gutter={24}>
          <Col  xs={{span:24}} sm={{span:24}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
              <FormItem label="Page Name" >
                          {/* <Cascader options={this.state.menus} placeholder="Menu category" changeOnSelect={true} onChange={this.onChange} style={{width:"100%"}} /> */}
                          {getFieldDecorator('pageTitle', {
                              trigger: 'onBlur',
                              valuePropName: 'defaultValue',
                              rules: [{
                                  required: true,
                                  message: "Please input page title ",
                              }],
                          })(
                            <Input placeholder="Page Title" />
                          )}

              </FormItem>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:4}} xl={{span:4}}>
        
                    <FormItem label='Show on Footer' >
                     <RadioGroup onChange={this.showOnFooter} value={this.state.showOnFooter}>
                     <Radio value={true}>Yes</Radio>
                     <Radio value={false}>No</Radio>
                   </RadioGroup>
                    </FormItem>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
        
                    <FormItem label='Add to Home Page' >
                     <RadioGroup onChange={this.showOnHome} value={this.state.onHome}>
                     <Radio value={true}>Yes</Radio>
                     <Radio value={false}>No</Radio>
                      </RadioGroup>
                    </FormItem>
            </Col>
            <Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

        <FormItem label='Page For' >
        {getFieldDecorator('pageFor', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{
                      required: false,
                      message: "Please Selete Page For ",
                  }],
              })(
         <RadioGroup  onChange={this.onChange} value={this.state.value}>
         <Radio value={"MOB"}>Mobile</Radio>
         <Radio value={"WEB"}>Web</Radio>
         <Radio value={"BOTH"}>Both</Radio>
          </RadioGroup>
           )}
        </FormItem>
        
</Col>
            </Row>
            <Row gutter={24}>



<Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

        <FormItem label='Page Editor' >
        {getFieldDecorator('pageType', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{
                      required: false,
                      message: "Please Selete Page Type ",
                  }],
              })(
                <>
                
         <RadioGroup  onChange={this.onChangePageType} value={this.state.pageType}>
         <Radio value={"FROALA"}>Froala</Radio>
         <Radio value={"HTML"}>HTML</Radio>
         {/* <Radio value={"BOTH"}>Both</Radio> */}
          </RadioGroup>
          </>
           )}
        </FormItem>
        
</Col>
<Col xs={{span:8}} sm={{span:8}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

        <FormItem label='Select Tag' >
        {getFieldDecorator('tags', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{
                      required: false,
                      message: "Please Selete Select Tag ",
                  }],
              })(
                <Select  
                optionFilterProp="children"
                mode="multiple"
                onChange={this.handleChangetags}
                placeholder="Please select">
                {this.state.tagsArray.map((c)=>{
                    return(
                        <Select.Option  key={c.value} value={c.value} >{c.label}</Select.Option>
                    )
                })}
                </Select>
           )}
        </FormItem>
        
</Col>
<Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Photo"  extra="Add square image.">
                            {getFieldDecorator('image', {
                                // validateTrigger: ['onChange','onBlur'],
                                rules: [{
                                    required: false,
                                    message: "Photo is required",
                                }],
                            })(
                                <Upload
                                    listType="picture-card"
                                    data={iconImg}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleMainImage}
                                    onRemove={this.handleMainImageRemove}
                                >
                                    {Object.keys(iconImg).length != 0 ? null : uploadButton}
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormItem>
                      </Col>
</Row>

<Row gutter={16}>                        
                    
                    {this.state.pageType == "FROALA" ? 
                    <Col span={24}>
                          <Popover content={googleAppID} 
                          placement="top"
                          style={{marginBottom:'25px'}}
                          title="Steps to Froala Page">
                          <span style={{marginBottom:'25px',marginLeft: '105px'}}>Select from 160+ ready designs from Froala. Click here to know how to implement</span>
                        </Popover>
                  <FormItem label="Content:" {...formItemLayout}>
              
                  {getFieldDecorator('description', {
                    validateTrigger: ['onChange', 'onBlur']
                  })(
                    <FroalaEditor
                      //  heightMin= "400px"
                      //  heightMax= "600px"
                      config={this.config}
                      onModelChange={this.handleModelChange}
                      model={this.state.modelNew}
                    />
                  )}
                </FormItem>
                </Col>
                :
                <> 
                <Col md={{span:20}}>
                <FormItem label="Content:" {...formItemLayout}>
                {getFieldDecorator('descriptionHTML', {
                  validateTrigger: ['onChange', 'onBlur']
                })(
                  // <TextArea rows={12} />
                  <FroalaEditor
                    height="70vh"
                    width="130vh"
                    defaultLanguage="html"
                    defaultValue="<!--some comment-->"
                    onChange={this.handleEditorChange}
                    theme="vs-dark"
                  />
                )}
              </FormItem>
              </Col>
              <Col md={{span:1}} >
   
                <Button type="danger" onClick={()=>this.onPreviewChange(this.state.priContent)}>Preview</Button>
             
                </Col>
             
              </>
            }
            </Row>

          <Col span={24}>
            <Button type="primary" style={{cursor:"pointer", color:"#fff"}} loading={this.state.loading} htmlType="submit">Submit</Button>
            <Button type="default" style={{background:"#f44336", color:"#fff",marginLeft:"20px"}} onClick={this.message}>Cancel</Button>

          </Col>
         </Form>
      </App>
    );
  }
}
const WrapCreateStaticPage = Form.create()(CreateStaticPage);
const wrapsite = compose(
    withApollo,
    graphql(CreateSitePages,{
        name:'createSitePages'
    }),
)(WrapCreateStaticPage)
export default wrapsite
