import React from 'react'
import {  withApollo } from 'react-apollo';
import { Button,  Table, } from 'antd';
import getSiteConfigServices from '../../queries/getSiteConfigServices'


class OneTimeSerList extends React.Component{
    constructor(props){
        super(props)
        this.state={
            oneTimeService: []
        }
    }
    componentDidMount(){
        this.getOneTimeService()
    }
    getOneTimeService=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "extraService"
            },
            fetchPolicy: 'network-only'
          })
            .then(({ data }) => {
              console.log('oneTimeService', data)
              this.setState({
                oneTimeService: data.getSiteConfigServices,
                  loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    render(){
        const columns = [
            {
              title: 'Service Name',
              dataIndex: 'serviceName',
              key: 'typeName',
            },
            {
                title: 'Price',
                dataIndex: 'charges',
                render: (data) =>{
                    return(
                        <span>{data/100}</span>
                    )
                }
            },
            {
                title: 'Action',
                render: (data) => {
                    // console.log("data ::",data)
                      return (
                          <span >
                            <Button  type= "danger"  style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000 ", color:"#fff"}}>Edit</Button>
                          </span>
                      )
              }
            }
          ];
        return(
            <Table
                        bordered
                        dataSource={this.state.oneTimeService}
                        columns={columns}
                        />        
                        )
    }
}

export default withApollo(OneTimeSerList)