import gql from 'graphql-tag'

export default gql`
mutation generateTransactionId($id: String!){
    generateTransactionId(id: $id)
  }
  `

//  {
//      "id": "61b2f0d3f5f37c006c8ab78d"
//    }
  
  