import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { message, Table,Button,Popconfirm,Input} from 'antd';


import getPlatformServicesByPlatform from '../../queries/getPlatformServicesByPlatform'
// import createPlatformService from '../../mutation/createPlatformService' //mutation changes from sohel req
import addPlatformService from '../../mutation/addPlatformService'
import updateSubscriptionPlan from '../../mutation/updateSubscriptionPlan'
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const { TextArea } = Input;

class AddServiceToSitePlan extends Component {
    constructor(props){
        super(props)
        this.state={
            data: [],
            btnLoading: false,
            selectedSev: null,
            notes: undefined,
            currentSer : this.props.currentSer
        }
    }


    addService=(record)=>{
        // console.log("record ::",record)
        this.setState({
            btnLoading: true,
            selectedSev: record.slugServiceName
        })
        // return
        if(this.state.notes){
        this.props.client.mutate({
            mutation: addPlatformService,
            variables: {
                siteId: this.props.siteId,
                serviceName: record.serviceName,
                slugServiceName: record.slugServiceName,
                siteType: record.siteType,
                serviceType: record.serviceType,
                status: record.status,
                masterService: record.masterService,
                platform: record.platform
            }
        }).then(({ data }) => {
          // console.log("Resp Charges ::",data)
          if(data.addPlatformService){
            this.updatePlan(data.addPlatformService)
            this.createLogs(record.serviceName)
          }
        })
      }else{
        message.error("Please Enter Notes")
        this.setState({
          btnLoading: false,
      })
      }
    }
    updatePlan=(values)=>{
        let planData = this.props.planData
        let serv = planData.services
        serv.push(values.serviceId)
        // console.log("serv ::",serv,planData)
        // return
        this.props.client.mutate({
            mutation: updateSubscriptionPlan,
            variables: {
                siteId: planData.siteId,
                planId: planData.planId,
                planName: planData.planName,
                status: planData.status,
                masterService: planData.masterService,
                platform: planData.platform,
                curr: planData.currency,
                priceList: planData.priceList,
                description: planData.description,
                services:serv
            }
        }).then(({ data }) => {
          console.log("updateSubscriptionPlan ::",data)
          let ser = this.state.currentSer
          ser.push(values.slugServiceName)
          this.setState({
            btnLoading: false,
            currentSer:ser
        })
          message.success("Subscription Plan Updated Successfully");
          // this.props.onCancel()
        })
    }
    notesChange=(e)=>{
      this.setState({
        notes: e.target.value
      })
    }
    createLogs=async(name)=>{
      let obj = {
          siteId: this.props.siteId,
          eventName: `${name} Service added`,
          eventType: 'MANUAL',
          // reason: this.state.selectedReason,
          note: this.state.notes
      }
      let resp = await SiteActionEvent(this.props,obj)
      if(resp){
        this.setState({
          notes: undefined
        })
      }
   
  }
    render() {
        let serviceData = this.props.services
        // console.log("Props::",serviceData)

        const columns = [
            {
                title: 'Service Name',
                dataIndex: 'serviceName'
              },
            {
                title: 'Master Service',
                dataIndex: 'masterService'
              },
            {
                title: 'Platform',
                dataIndex: 'platform'
              },
            {
                title: 'Service Type',
                dataIndex: 'serviceType'
              },
              {
                title: 'Action',
                width: '10%',
                render:(data)=>{
                  return(
                    <Popconfirm  disabled={this.state.currentSer.includes(data.slugServiceName)}    onConfirm={() => this.addService(data)}  title={        <TextArea    rows={2}  value={this.state.notes}    placeholder="Notes" onChange={this.notesChange}  />} okText="Save" >
                      <Button 
                      loading={data.slugServiceName === this.state.selectedSev && this.state.btnLoading}
                          style={{ background: this.state.currentSer.includes(data.slugServiceName) ? "gray" : "#389e0d", color: "#fff",width: "115px" }}
                          disabled={this.state.currentSer.includes(data.slugServiceName)} >
                       Add
                      </Button>
                      </Popconfirm>
                  )
              }
              },
        ]
        return (
            <Table
            bordered
            dataSource={ serviceData}
            columns={ columns}
          />
            // < Row gutter={16}>
            //      {serviceData.length>0 ? serviceData.map((item,i)=>{
            //          console.log("item ::",item.serviceId)
            //        return(
                   
            //             <Col md={{ span: 6 }}>
            //             <Card style={{marginTop:'10px',textAlign:'center'}} className='certiCard' disabled={ sd.includes(item.slugServiceName)}>
            //                 {/* <Col xs={{ span: 18 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>{item.serviceName}</Col> */}
            //                 <p>{item.serviceName}</p>
            //                     <Button key={i} onClick={()=> this.downloadCer(item)} loading={item.email === this.state.userData && this.state.downloadL } style={{background:"#389e0d", color:"#fff",width:'115px', }}
            //                           disabled={ sd.includes(item.slugServiceName)}
            //                           >
            //                         <span style= {{ fontWeight: '400', fontFamily:'sans-serif'}}>Add</span></Button>
            //             </Card>
            //             </Col>
                        
            //        )
            //     })
            // : ''}
            // </Row>
        );
    }
}

export default withApollo(AddServiceToSitePlan);