import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon,Spin, Tooltip,Popconfirm,Input, Table, message,Modal } from 'antd';
import {  withApollo } from 'react-apollo';
import Highlighter from 'react-highlight-words';
import getPlatformgetPlatform from '../../queries/getPlatformServicesBySiteId'
import deletePlatformService from '../../mutation/deletePlatformService'
import Clone from './clone'

import App from '../../App'
const Delete  = <span>Delete</span>;

class PlatformService extends Component {
    constructor(props){
    super(props)
    this.state={
        data:[],
        loading: false,
        visibleM: false
    }
}
    componentDidMount(){
        this.getPlatform()
    }
    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    
    getPlatform=()=>{
        this.enterLoading()
        this.props.client.query({
            query: getPlatformgetPlatform,
            variables: {
                siteId: "master"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('data', data)
              this.setState({
                data: data.getPlatformServicesBySiteId,
                loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    delete=(data1)=>{
        this.props.client.mutate({
          mutation: deletePlatformService,
          variables: {
            siteId: data1.siteId,
            serviceId: data1.serviceId
          }
      }).then(({ data }) => {
          if(data.deletePlatformService){
            this.getPlatform()
            // let arr = this.state.data;
            // let i = arr.findIndex(x => x.serviceId == data.serviceId)
            // arr.splice(i,1)
            // this.setState({
            //   data:arr
            // })
            message.success("Successfully Subscription Deleted")
          }
        })
        .catch(res => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
        });
      }
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      clone=(data)=>{
        this.setState({
          modalData: data,
          visibleM: true
        })
      }
      handleCancel=()=>{
        this.setState({
          visibleM: false
        })
      }
    

    render() {
        const columns = [
            {
              title: 'Service Name',
              dataIndex: 'serviceName',
              key: 'serviceName',
              ...this.getColumnSearchProps('serviceName'),
            },
            {
              title: 'Slug Name',
              dataIndex: 'slugServiceName',
              key: 'slugServiceName',
            },
            {
              title: 'Master Service',
              dataIndex: 'masterService',
              key: 'masterService',
              ...this.getColumnSearchProps('masterService'),
            },
            {
              title: 'Site Type',
              dataIndex: 'siteType',
              key: 'siteType',
            },
            {
              title: 'Site Option',
              dataIndex: 'platform',
              key: 'platform',
              ...this.getColumnSearchProps('platform'),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
                title: 'Action',
                render: (data) => {
                    // console.log("data ::",data)
                      return (
                          <span >
                            <Link to={{pathname:"/edit-platform",state:{pfData:data}}}>
                            <Button  type= "danger" icon='edit'  style={{marginLeft:"5px",cursor: "pointer", background:"#008000", color:"#fff"}}></Button>
                         </Link>
                          <Tooltip placement="top" title={Delete}>
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(data)}>
                              <Button icon="delete" type="danger" style={{ marginLeft: "5px", cursor: "pointer", background: "#ff0000", color: "#fff" }} />
                            </Popconfirm>
                          </Tooltip>
                          <Button  type= "primary" icon='copy' onClick={()=> this.clone(data)}  style={{marginLeft:"5px",cursor: "pointer"}}></Button>
                          </span>
                      )
              }
            }
          ];
        return (
            <App header={'Platform Service'}>
               <p style={{ textAlign: 'end' }}>
                            <Link to="/create-platform">
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                Create Platform Service
                                </Button>
                            </Link>
                      
                        </p>
                        {this.state.loading ? 
                         <div style={{textAlign:"center"}}>
                         <Spin size="large"/>
                     </div> :
                     <> 
                     <Table
                     bordered
                     dataSource={this.state.data}
                     columns={columns}
                     pagination={{ pageSize: 50 }}
                     />
                     <Modal
                    title={"Clone"}
                    visible={this.state.visibleM}
                    onCancel={this.handleCancel}
                    footer={null}
                    width="70%"
                  >
                    <Clone pfData={this.state.modalData} getPlatform={this.getPlatform} handleclick={this.handleCancel}/>
                  </Modal>
                  </>
                    }
                
            </App>
        );
    }
}

export default withApollo(PlatformService);