import gql from 'graphql-tag';

export default gql`
mutation createSitePage(
  $pageName: String! 
  $displayName: String
  $value: String 
  $onFooter:Boolean 
  $onHome:Boolean 
  $pageFor: PAGE_FOR 
  $pageType: PAGE_TYPE 
  $tags: [String!]  
  $image: S3ObjectInput
  ){
    createSitePage(input:{
      pageName: $pageName
      displayName: $displayName
      value:  $value
      onFooter:$onFooter
      onHome:$onHome
      tags: $tags
      pageFor: $pageFor
      pageType: $pageType
      image: $image
    }
    ){
      pageName
      value
      onHome
      tags
      pageFor
      pageType
      image{
        key
        bucket
        region
      }
    }
  }
`;