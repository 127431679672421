import gql from 'graphql-tag'

export default gql`
query getSiteTransactionsByCreatedAt($siteId: String! $type: String $limits: Int $skips: Int ){
    getSiteTransactionsBySite(siteId: $siteId type:$type limits: $limits skips: $skips){
      id
      domain
      siteId
      balance
      currency
      transactionId
      createdAt
      amount
      service
      note
      transactionType
      rechargeBy
      paymentState
      attachedInvoice{
        invoiceId
        transactionId
        invoicePdf{
          bucket
          region
          key
        }
      }
    }
  }`

//    {
//        "siteId": "b148b379-a018-44e3-ae54-644bac405262",
//        "type": "CREDIT",
//        "limits": 10,
//        "skips": 0
//      }