let isError = false;

const queryFunction = (query, client, variables, fetchPolicy) => {
  return new Promise((resolve, reject) => {
    client.query({
      query: query,
      variables: variables,
      fetchPolicy: fetchPolicy
    }).then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
        isError = true;
        resolve([])

        console.log(`Catch: handleFormSubmit: error: ${err}`)
      })
  })
}

const callFetch = (query, client, variables, fetchPolicy, dataName) => {
  return new Promise(async(resolve, reject) => {
    let nextToken = '', aggregatedData = []
    // variables = nextToken;
    // console.log("variables :", variables)

    do{
      // console.log("nextToken--",nextToken)

      let tempData = await queryFunction(query, client, {...variables, nextToken}, fetchPolicy, dataName)
      if(tempData && tempData[`${dataName}`] && tempData[`${dataName}`].items){
        aggregatedData = [...tempData[`${dataName}`].items, ...aggregatedData]
        // console.log("aggregatedData ::", aggregatedData)
        nextToken = tempData[`${dataName}`].nextToken;
        nextToken = nextToken;
      } else {
        nextToken = null
      }
    } while(nextToken != null && isError === false)

    resolve(aggregatedData)
  })
}


const gerenricQueryFunction = (query, client, variables, fetchPolicy, dataName) => {
  // console.log("isError ::", isError)
  return new Promise(async(resolve, reject) => {
    const data = await callFetch(query, client, variables, fetchPolicy, dataName)
    resolve({data, isError});
  })
  
};

export {
    gerenricQueryFunction
};