import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Button, Popconfirm, Input, Icon } from 'antd';
import AddEmployeeModal from './AddEmployeEdit';
const EditCpEmployee = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    creatData()
  }, [])

  
  const creatData = () => {
   
    let employee = props.userData && props.userData.empsDetails && props.userData.empsDetails
    let Data = []
    if (employee && employee.length > 0) {

      employee.map((emp, i) => {
        let obj = {};
        obj.key = i
        obj.name = emp.name
        obj.mobile = emp.mobile
        obj.email = emp.email
        obj.designated = emp.designated
        Data.push(obj)
      })
     
    }
    setDataSource(Data)
    props.setCpEmployeeDta(Data)
  }

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = (key) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => key === item.key);
    if (index > -1) {
      setDataSource(newData);
      props.setCpEmployeeDta(newData);
      setEditingKey('');
    }
  };

  const columns = [
    {
      title: ` Name`,
      dataIndex: "name",
      key: "name",

      align: "center",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={text}
            onChange={e => {
              const newData = [...dataSource];
              const index = newData.findIndex(item => record.key === item.key);
              if (index > -1) {
                newData[index].name = e.target.value;
                setDataSource(newData);
              }
            }}
          />
        ) : text;
      },
    },
    {
      title: " Email ",
      dataIndex: "email",
      key: "email",

      align: "center",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={text}
            onChange={e => {
              const newData = [...dataSource];
              const index = newData.findIndex(item => record.key === item.key);
              if (index > -1) {
                newData[index].email = e.target.value;
                setDataSource(newData);
              }
            }}
          />
        ) : text;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",

      align: "center",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={text}
            onChange={e => {
              const newData = [...dataSource];
              const index = newData.findIndex(item => record.key === item.key);
              if (index > -1) {
                newData[index].mobile = e.target.value;
                setDataSource(newData);
              }
            }}
          />
        ) : text;
      },
    },
    {
      title: "Designation",
      dataIndex: "designated",
      key: "designated",
      align: "center",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            value={text}
            onChange={e => {
              const newData = [...dataSource];
              const index = newData.findIndex(item => record.key === item.key);
            
              if (index > -1) {
              
                newData[index].designated = e.target.value;
                setDataSource(newData);
              }
            }}
          />
        ) : text;
      },
    },
    {
      title: "Action",
      key: "action",

      align: "center",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Icon type="check-circle" theme="twoTone" onClick={() => save(record.key)} style={{ marginRight: 8 }} />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              {/* Close icon for cancel */}
              <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
            </Popconfirm>
            {/* <Button type="primary" onClick={() => save(record.key)}>Save</Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="default" style={{marginLeft: 8}}>Cancel</Button>
            </Popconfirm> */}
          </span>
        ) : (
          <Tooltip placement="top" title="Edit Item">
            <Button
              icon="edit"
              type="default"
              style={{
                cursor: "pointer",
                background: "#389e0d",
                color: "#fff",
              }}
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = (values) => {
    setModalVisible(false);
   
   let arr = [...dataSource, { key: dataSource.length, ...values }]
    setDataSource(arr);
   
    props.setCpEmployeeDta(arr)
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <> 
      <Button type="dashed" onClick={showModal} style={{ width: '30%' }}>
        <Icon type="plus" /> Add Employee
      </Button>
      <Table
        title={() => <h3 style={{ textAlign: "center" }}>Employee</h3>}
        dataSource={dataSource}
        columns={columns}
        rowKey={"editEMployeTable"}
        rowClassName={(record) => isEditing(record) ? 'editing-row' : ''}
      />

      <AddEmployeeModal
        visible={modalVisible}
        onCreate={handleModalOk}
        onCancel={handleModalCancel}
      />
    </>
  );
};

export default EditCpEmployee;
