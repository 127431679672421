import gql from "graphql-tag";

export default gql`
  {
    getSitesAdmin {
      id
      domain
      subdomain
      fqdn
      siteId
      enabled
      siteType
    }
  }
`;
