import gql from 'graphql-tag'

export default gql`
query getSiteCustomLabelByDomain($domain: String!){
    getSiteCustomLabelByDomain(domain: $domain){
      domain
      typeValue
      customLabels{
        defaultName
        displayName
      }
    }
  }
  `