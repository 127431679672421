import React from "react";
import { Table, Button, Switch,Input, message,Popconfirm} from 'antd';
import { withApollo } from "react-apollo";

import updateServiceType from '../../mutation/updateServiceType'
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const { TextArea } = Input;
class SubServiceType extends React.Component {
    constructor(props){
        super(props)
        this.state={
          data: this.props.data,
          loading: false,
          notes: undefined,
        }
    }

    enterLoading=()=>{
      this.setState({
          loading: true
      })
  }
  notesChange=(e)=>{
    this.setState({
      notes: e.target.value
    })
  }
    submitSubMut=(value)=>{
      // console.log("value",value)
     this.enterLoading()
    //  return
    let image = value.image;
    if(image && image.key){
      delete image.__typename
    }
    if(this.state.notes){
     this.props.client.mutate({
         mutation: updateServiceType,
         variables: {
             siteId: value.siteId,
             slugName:  value.slugName,
             typeName: value.typeName,
             typeDes: value.typeDes ? value.typeDes : undefined,
             image: image,
             typeStatus: !value.typeStatus,
             displayOrder: value.displayOrder
         }
     }).then(({ data }) => {
         console.log("data response",data.updateServiceType)
         if(data && data.updateServiceType){
          let arr = this.state.data;
          let i = arr.findIndex(x => x.slugName == data.updateServiceType.slugName)
          arr.splice(i,1)
          arr.push(data.updateServiceType)
        //  console.log("arr",arr)
           this.setState({
            data:arr,
            loading: false
        })
        message.success('Menu Successfully Updated');
        this.createLogs(value.typeName,value.siteId)

         }
        
       })
       .catch(res => {
         console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
       });
      }else{
        if(this.state.notes == undefined){
          message.error("Please Enter Notes")
          this.setState({
            loading: false
          })
        }
      }

 }

    onEditPress = (record) => {
      // console.log("record :::", record)
      if(this.props.handleEditPressFunction && this.props.modalRecord && record){
        this.props.handleEditPressFunction(this.props.modalRecord, record, "UPDATE_SUB_MENU");
      }
    }
    createLogs=async(name,siteId)=>{
      let id = siteId.split("::")
      let obj = {
          siteId: id[1],
          eventName: `Setup`,
          eventType: 'MANUAL',
          // reason: this.state.selectedReason,
          note: this.state.notes
      }
      let resp = await SiteActionEvent(this.props,obj)
      if(resp){
        this.setState({
          notes: undefined
        })
      }
    }
    render(){
      // console.log("record :::", this.state.data, this.props.menuType)
        const columns = [
            {
              title: 'Sub Menu Name',
              dataIndex: 'typeName',
              key: 'typeName',
            },
            {
              title: 'Description',
              dataIndex: 'typeDes',
              key: 'typeDes',
            },
            {
              title: 'Status',
              render:(data)=>{
                return(
                     <div style={{ marginTop: 10 }}>
                        <Popconfirm     onConfirm={(e)=>this.submitSubMut(data)}  title={        <TextArea    rows={2}  value={this.state.notes}    placeholder="Notes" onChange={this.notesChange}  />} okText="Save" >
                        <Switch loading={this.state.loading} 
                        // onChange={(e)=>this.submitSubMut(e,data)}
                         checked={data.typeStatus} />
                        </Popconfirm>
                    </div>
                )
    
            }
            },
            {
              title: 'Action',
              render:(data)=>{
                return(
                  <>
                  <Button
                      icon="edit"
                      type="default"
                      style={{
                        cursor: "pointer",
                        background: "#389e0d",
                        color: "#fff",
                      }}
                      onClick={() => this.onEditPress(data)}
                    />
                  </>
                )
              }
            }
            
          ];

        // if(this.props.menuType === "online-menu"){
        //   columns.push({
        //     title: 'Action',
        //     render:(data)=>{
        //       return(
        //         <>
        //         <Button
        //             icon="edit"
        //             type="default"
        //             style={{
        //               cursor: "pointer",
        //               background: "#389e0d",
        //               color: "#fff",
        //             }}
        //             onClick={() => this.onEditPress(data)}
        //           />
        //         </>
        //       )
        //     }
        //   })
        // }
        return(
            <Table
            loading={this.state.loading}
            dataSource={this.state.data}
            columns={columns}
            pagination={false}
            />
        )
    }
}

export default withApollo(SubServiceType)