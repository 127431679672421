import gql from 'graphql-tag'
export default gql`
mutation deleteSubscriptionPlan( $siteId: String! $planId: String!){
  deleteSubscriptionPlan(siteId: $siteId planId: $planId){
    id
    siteId
    planId
    planName
  }
}`

// {
//       "siteId": "master",
//       "planId": "50da507e-f710-4315-9cfc-e738a8c5a6ef"
//     }
    
