// export default {
//   graphqlEndpoint:
//     "https://m3l6z6xjunfazjir4txp7dl6lu.appsync-api.us-east-1.amazonaws.com/graphql",
//   region: "us-east-1",
//   authenticationType: "API_KEY",
//   apiKey: "da2-3n5gwasgtncolptbz7uhbq3saa"
// };

export default {
  graphqlEndpoint:"https://kob5nacb3fgw3d7jdhz7g6jlpe.appsync-api.ap-south-1.amazonaws.com/graphql",
  region: "ap-south-1",
  apiKey: "da2-nyfnwjld65b6bmyzza4damoocq",
  authenticationType: "AMAZON_COGNITO_USER_POOLS",
};
