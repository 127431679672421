import gql from 'graphql-tag'
export default gql`
mutation updateServiceTypeSetting(
    $domain: String!
  $typeValue: String!
  $siteId: String
  $customFeilds: [CustomFormFeildsInput!]
  $availableFeilds: [AvailableFeildsInput!]
  $formSchemas: [FormSchemasInput!]
  $customCharges: [CustomChargesInput!]
  $showOnlyMenu: Boolean
  $showMenu: String
  $quantityReq: Boolean
  $orderText: String
  $orderButtonColor: String
  $minOrderValue: Float
  $orderResp: String
  $whatsAppText: String
  $feedbackMsg: String
  $reasonReject: [String]
    $hoursOfOperation:  [HoursOfOperationInput!]
     $whatsAppNumber: [String]
  $regardsName: String
  $orderMessageTemplate: String
  $showPaymentOption: Boolean
  $themeDesign: THEME_DESIGN
  $fixfooter: Boolean
  $footerFields: [String]
  $gallery: Boolean
  $bookingStaticPageWeb: String
  $bookingStaticPageMob: String
  $staticPageAtTop: Boolean
  $slotReq: Boolean
  $qrcode: S3ObjectInput
  $paymentLink: String
  $bookingDelay: Int
  $formTitle: String
  $taxMethod: String
  $bankDetails: [BankDetailsInput!]
 
){
updateServiceTypeSetting(input:{
  domain: $domain
  typeValue: $typeValue
  siteId: $siteId
  showOnlyMenu: $showOnlyMenu
  showMenu: $showMenu
  quantityReq: $quantityReq
  orderText: $orderText
  orderButtonColor: $orderButtonColor
  minOrderValue: $minOrderValue
  orderResp: $orderResp
  whatsAppText: $whatsAppText
  feedbackMsg: $feedbackMsg
  reasonReject: $reasonReject
  customFeilds: $customFeilds
  availableFeilds: $availableFeilds
  customCharges: $customCharges
  hoursOfOperation: $hoursOfOperation
  whatsAppNumber: $whatsAppNumber
  regardsName: $regardsName
  orderMessageTemplate: $orderMessageTemplate
  showPaymentOption: $showPaymentOption
  themeDesign: $themeDesign
  fixfooter:  $fixfooter
  footerFields:$footerFields
  gallery:  $gallery
  bookingStaticPageWeb: $bookingStaticPageWeb
  bookingStaticPageMob: $bookingStaticPageMob
  staticPageAtTop:$staticPageAtTop
  slotReq:  $slotReq
  qrcode:  $qrcode
  paymentLink: $paymentLink
  bookingDelay:$bookingDelay
  formTitle: $formTitle
  taxMethod: $taxMethod
  bankDetails: $bankDetails
  formSchemas: $formSchemas
}) {
  id
  domain
  typeValue
  bankDetails{
      accountNum
      bankName
      accountHolder
      ifscCode
      defaultSelected
    }
}
}


`
  // {
//       "domain": "dinetest.recaho.com",
//       "typeValue": "dine-in::setting",
//       "siteId": "b148b379-a018-44e3-ae54-644bac405262",
//       "showOnlyMenu": true,
//       "showMenu": null,
//       "quantityReq": null,
//       "orderText": null,
//       "orderButtonColor": "//0693E3",
//       "minOrderValue": null,
//       "orderResp": null,
//       "whatsAppText": "Thank you for your Order. We are have started working on it and looking forward to serving you.",
//       "feedbackMsg": "no",
//   		"whatsAppNumber": "8459832342",
//   		"regardsName": "Sohel Company",
// 			"orderMessageTemplate": "Dear Customer",
//  			"showPaymentOption": true,
//       "reasonReject": [
//         "Soerry order not available"
//       ],
// 		  "hoursOfOperation": [
//         {
//           "day": "MON",
//           "status": true,
//           "start": "10:00 AM",
//           "end": "9:00 PM"
//         },
//         {
//           "day": "TUE",
//           "status": true,
//           "start": "10:00 AM",
//           "end": "9:00 PM"
//         }
//       ],
//       "customCharges": null,
//       "customFeilds": [
//         {
//           "customType": "QRCODE",
//           "customName": "generate Qr Code",
//           "customValue": null,
//           "customListValue": [
//             "//00d084"
//           ],
//           "menuAttached": [
//             "online-menu",
//             "parcel",
//             "dine-in"
//           ],
//           "required": true,
//           "length": null
//         }
//       ],
//       "availableFeilds": [
//         {
//           "availableType": "SEARCH",
//           "displayName": "Enter Item Name to Search",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "ac",
//             "non-ac",
//             "parcel",
//             "online-menu"
//           ],
//           "required": true
//         },
//         {
//           "availableType": "PRODUCT",
//           "displayName": "Select Items from Menu Below",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "ac",
//             "non-ac",
//             "online-menu",
//             "parcel"
//           ],
//           "required": true
//         },
//         {
//           "availableType": "NAME",
//           "displayName": "Full Name",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "parcel",
//             "online-menu",
//             "ac",
//             "non-ac"
//           ],
//           "required": true
//         },
//         {
//           "availableType": "EMAIL",
//           "displayName": "Email Id",
//           "display": false,
//           "menuAttached": [
//             "dine-in",
//             "online-menu",
//             "parcel",
//             "ac",
//             "non-ac"
//           ],
//           "required": false
//         },
//         {
//           "availableType": "MOBILE",
//           "displayName": "Mobile Number",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "online-menu",
//             "parcel",
//             "ac",
//             "non-ac"
//           ],
//           "required": true
//         },
//         {
//           "availableType": "DATE",
//           "displayName": "Select Date",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "ac",
//             "non-ac",
//             "parcel",
//             "online-menu"
//           ],
//           "required": true
//         },
//         {
//           "availableType": "SLOTS",
//           "displayName": "Select from Available Slots",
//           "display": true,
//           "menuAttached": [
//             "dine-in",
//             "ac",
//             "non-ac",
//             "parcel",
//             "online-menu"
//           ],
//           "required": true
//         }
//       ]
  
//   }
