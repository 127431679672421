import gql from 'graphql-tag'

export default gql`
mutation sendWhatsApp(
    $type: MESSAGE_TYPE!
  $toNumber: String!
  $content: String
  $imageLink: String
  ){
    sendWhatsApp(input:{
        type: $type
  toNumber: $toNumber
  content: $content
  imageLink: $imageLink
    })
  }
  
`