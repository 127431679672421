import React, { Component } from 'react';
import {  Row, Col, Form, Button,message,Input,Upload,Icon  } from 'antd';
import uuidv1 from 'uuid/v1';
import { withApollo } from 'react-apollo';
import App from '../../App'
import slugify from 'slugify';
import createTempleteByAdmin from '../../mutation/createTempleteByAdmin'
const FormItem = Form.Item;
const { TextArea } = Input;

const bucket = process.env.REACT_APP_BUCKET
const S3_REGION = process.env.REACT_APP_S3_REGION


class AddMTemplates extends Component {
    constructor(props){
        super(props)
        this.state={
            img:[],
            loading: false,
            removedimage: false,
            content: ''
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err){
                // console.log("values ::",values,this.state.img)
                if (Object.keys(this.state.img).length != 0) {
                    if (!this.state.img.hasOwnProperty("key")) {
                        values.img = {
                            bucket: bucket,
                            region: S3_REGION,
                            localUri: this.state.img.originFileObj,
                            key: `global/templates/whatsapp${uuidv1()}`,
                            mimeType: this.state.img.type
                        };

                    } else {
                        const { uid, url, bucket, key, region } = this.state.img
                        values.img = {
                            bucket,
                            key,
                            region
                        }
                    }
                } else {
                    values.img = null
                }
                // console.log("values ::",this.props.location.type)
                // return
                this.enterLoading()
                const slugName = slugify(values.tempName, {
                    lower: true
                })
                this.props.client.mutate({
                    mutation: createTempleteByAdmin,
                    variables: {
                        siteId: 'global',
                        slugName: slugName,
                        tempName: values.tempName,
                        tempSubject: values.tempSubject ? values.tempSubject : values.tempName,
                        text: this.state.content,
                        type: this.props.location.type,
                        image: values.img
                    }
                }).then(({ data }) => {
                    // console.log("data response",data)
                    message.success("Template saved successfully")
                    this.setState({
                        loading: true
                    },()=>{
                        this.props.history.push('/global-template')
                    })
                })
            }
        })
    }
    handleimage = ({fileList }) => {
        if(fileList.length >0){
          this.setState({
            img:fileList[0],
            removedimage:false
          })
        }
        
    }
    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    handleRemoveImg=({fileList})=>{
        // console.log('remove')
        this.setState({
           img:[],
          removedimage:true
        })
      }
      onCantentChange=(e)=>{
        this.setState({
            content: e.target.value
        })
    }


      addName=()=>{
        let text = this.state.content
        let ftext = text +'_name_'
        this.setState({
            content: ftext
        })
    }
    addBalAmt=()=>{
        let text = this.state.content
        let ftext = text +'_Balance_Amount_'
        this.setState({
            content: ftext
        })
    }
    addSpentAmt=()=>{
        let text = this.state.content
        let ftext = text +'_Spent_Amount_'
        this.setState({
            content: ftext
        })
    }
    addOrder=()=>{
        let text = this.state.content
        let ftext = text +'_No_of_Orders_'
        this.setState({
            content: ftext
        })
    }
    addLastVisit=()=>{
        let text = this.state.content
        let ftext = text +'_Last_Visit_'
        this.setState({
            content: ftext
        })
    }
    addDomain=()=>{
        let text = this.state.content
        let ftext = text +'_domain_'
        this.setState({
            content: ftext
        })
    }


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
    
            </div>
          );
        return (
            <App header={'Add Template'}>
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16} >
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Template Name' className="booking-label">
                                {getFieldDecorator('tempName', {
                                    rules: [{
                                        required: true,
                                        message: "Please Enter Template Name",
                                    }],
                                })(
                                    <Input placeholder="Template Name" style={{ width: '80%', marginRight: 8 }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Image' className="booking-label">
                            {getFieldDecorator('img',{
                                rules: [
                                {
                                    required: false,
                                    message: 'Please Upload Image!',
                                }],
                            })
                            (<Upload
                            listType="picture-card"
                            onChange={this.handleimage}
                            onRemove={this.handleRemoveImg}>
                            {Object.keys(this.state.img).length!=0 ? null : uploadButton}
                            </Upload>)}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <FormItem label='Content' className="booking-label">
                                {getFieldDecorator('text', {
                                    rules: [{
                                        required: false,
                                        message: "Please Enter Content",
                                    }],
                                })(
                                    <>
                                    <Button type='dashed' onClick={this.addName} style={{marginRight: '10px'}}>Name</Button>
                                    <Button type='dashed' onClick={this.addBalAmt} style={{marginRight: '10px'}}>Balance Amount</Button>
                                    <Button type='dashed' onClick={this.addSpentAmt} style={{marginRight: '10px'}}>Spent Amount</Button>
                                    <Button type='dashed' onClick={this.addOrder} style={{marginRight: '10px'}}>No of Orders</Button>
                                    <Button type='dashed' onClick={this.addLastVisit} style={{marginRight: '10px'}}>Last Visit</Button>
                                    <Button type='dashed' onClick={this.addDomain} style={{marginRight: '10px'}}>Domain</Button>

                                    <br/>
                                    <TextArea style={{width:'80%'}} rows={20} placeholder="Content" value={this.state.content} onChange={this.onCantentChange} />
                                    </>
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16} style={{width: '60%'}}>
                            <Button type="primary" loading={this.state.loading} style={{ background: "#389e0d", color: "#fff", marginBottom: 16 }} htmlType="submit" >Save</Button>
                            <Button loading={this.state.loading} style={{ marginBottom: 16,marginLeft: 10 }} onClick={()=>{ this.props.history.push('/global-template')}} >Cancel</Button>

                        </Row>
                        </Form>
            </App>
        );
    }
}
const WrappAddMTemplates= Form.create()(AddMTemplates);
export default withApollo(WrappAddMTemplates);