
const handleBeforeUpload = (file) => {
    // console.log('file handlebefore upload:::', file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64String = event.target.result;
        // console.log('base64String::::', base64String);
        // resolve(base64String);
        resolve(base64String.split(',')[1])
      };
  
      reader.onerror = (error) => {
        console.error('FileReader error:', error);
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  };
  
  const convertFileToBase64 =  (fileList) => {
    return new Promise(async(resolve, reject)=> {
        
    // console.log('fileList:::', fileList);

    for(let i=0; i < fileList.length; i++) {

        fileList[i].content = await handleBeforeUpload(fileList[i].localUri)
        delete fileList[i].localUri
        delete fileList[i].bucket
        delete fileList[i].region
        delete fileList[i].localUri

    }
    resolve(fileList)

})

    // const promises = fileList.map((file) => handleBeforeUpload(file.originFileObj));
    // console.log('promises:::', promises);
    // Promise.all(promises)
    //   .then(async(base64Images) => {
    //     console.log('Base64 images:', base64Images);
    // //    await uploadbase64Files(client)
    //   })
    //   .catch((error) => {
    //     console.error('Error processing images:', error);
    //   });
  };
  
  export default convertFileToBase64;
  