import gql from 'graphql-tag'

export default gql`
mutation updateSiteConfig(
    $domain: String!
    $siteId: String!
    $siteServices: [SiteServiceInput!]!
    $enabled: Boolean
  
  ){
    updateSiteConfig(input:{
      domain: $domain
      siteId: $siteId
      enabled: $enabled
      siteServices: $siteServices
    }){
      domain
      typeValue
      siteId
      enabled
      siteServices{
        serviceName
        chargingModel
        charges
        status
        masterStatus
        startedAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }`
//   {
//       "domain": "testsite.recaho.com",
//       "siteId": "e6e15d4e-8a5a-44c9-a737-3f2e6d14796b",
//       "enabled": true,
//       "siteServices": [
//         {
//           "serviceName": "site",
//           "chargingModel": "MONTHLY",
//           "charges": 50,
//           "status": true,
//           "masterStatus": true,
//           "startedAt": 1630662794,
//           "updatedAt": 1630662794
//         },
//         {
//           "serviceName": "WHATSAPP",
//           "chargingModel": "MONTHLY",
//           "charges": 10,
//           "status": true,
//           "masterStatus": true,
//           "startedAt": 1630662794,
//           "updatedAt": 1630662794
//         }
//       ]
//     }