import gql from 'graphql-tag'

export default gql`
query getSitesBySiteType($siteType: String! $limit: Int $nextToken: String) {
    getSitesBySiteType(siteType: $siteType, limit: $limit nextToken: $nextToken) {
        items{
      id
      siteId
      domain
      createdAt
      subdomain
      fqdn
      internalName
      enabled
      siteType
      siteOptions
      custName
      userName
      basicSiteSetting{
        companyName
        contactNumber
        curr
      }
      planDetails {
        planName
      }
    }
    nextToken
    }
  }
`