import React, { Component } from 'react';
import { Alert,Button } from 'antd';
import {withApollo} from 'react-apollo';
import { Link } from 'react-router-dom';
class SuccessComp extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    onOk=()=>{
        this.props.history.push('/campaign-history')
    }
    render() {
        return (
            <>
                 {/* <p style={{textAlign: 'center',fontSize: '24px'}}>{`Your request to send ${this.props.count} messages has been queued. It will be delivered in the next 1 hr`}</p> */}
                 <p  style={{textAlign: 'center',fontSize: '24px'}}><img alt="suss" src='https://btoc-ecom-prod.s3.amazonaws.com/a78a56fb-5492-47e0-92ab-4284202c5556/HTMLPage/successTickMark-removebg-preview.png'style={{ height: 'auto', width: '10%'}}></img> </p>
                <Alert style={{textAlign: 'center',fontSize: '24px'}} message={`Congratulation! Your request to send ${this.props.count} messages has been queued. It will be delivered within the next 30 minutes.`} type="success"/>
                <Link to={{ pathname :"/campaign-history"}}>
                <div style={{textAlign: 'center'}}>
                    <Button type='primary' style={{ marginTop: '10px'}} >Ok</Button>
                </div>
                </Link>
             </>
        );
    }
}

export default withApollo(SuccessComp);