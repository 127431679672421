import gql from 'graphql-tag'

export default gql`
mutation createSiteActionEvent(
    $siteId: String!
      $eventName: String!
      $eventType: String!
      $note: String
      $reason: String
  ){
    createSiteActionEvent(input:{
      siteId: $siteId
      eventName: $eventName
      eventType: $eventType
      note: $note
      reason: $reason
    }){
      siteId
      eventName
      eventType
      reason
      note
      createdAt
      createdBy
    }
  }`
//   { 
//       "siteId": "32323",
//       "eventName": "Site Update",
//       "eventType": "SITE_DETAILS",
//       "note": "test"
//     }