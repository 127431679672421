import gql from 'graphql-tag'
export default gql`
mutation deletePlatformService( $siteId: String! $serviceId: String!){
    deletePlatformService(siteId: $siteId serviceId: $serviceId){
      id
      siteId
      serviceId
      serviceName
    }
  }
`
//  {
    //    "siteId": "master",
    //    "serviceId": "0d4be8b3-54f3-4911-828a-87895df71259"
    //  }
    