import React, { Fragment } from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom";

import CreateSite from '../src/components/sites/createSite'
import CreateStaticPage from '../src/components/staticPages/createStaticPages'
import CustomerList from '../src/components/EmailCampaign/getSignature'
import EditSite from '../src/components/sites/editSite'
import EditStaticPage from '../src/components/staticPages/editStaticPages'
import EmailCamp from '../src/components/EmailCampaign/emailUser'
import SiteList from '../src/components/sites/siteList'
import StaticPage from '../src/components/staticPages/index'
import ServiceList from '../src/components/chargesModal/ServiceList'
import CreateService from '../src/components/chargesModal/createService'
import ExtraService from '../src/components/chargesModal/extraService'
import AddExtraService from '../src/components/sites/addExtraServiceToSite'
import PlatformService from '../src/components/platformService/index'
import CreatePlatform from '../src/components/platformService/createPlatform'
import EditPlatform from '../src/components/platformService/editPlatform'
import SubscriptionPlan from '../src/components/SubscriptionPlan/index'
import CreateSP from '../src/components/SubscriptionPlan/createSP'
import EditSP from '../src/components/SubscriptionPlan/editSP'
import ServiceTypeList from '../src/components/siteServiceType/serviceTypeList'
import AddServiceType from '../src/components/siteServiceType/add-serviceType'
import TemplateList from './components/whatsappCampaign/templates';
import GlobalTemplateList from './components/GlobalCampaign/templatesList';
import AddGTemplates from './components/GlobalCampaign/addGTemplates';
import EditGTemplate from './components/GlobalCampaign/editGTemplate'
import AddMTemplates from './components/whatsappCampaign/addTemplates';
import SelectTemplate from './components/whatsappCampaign/selectTemplate'
import CampaignHistory from './components/whatsappCampaign/CampaignHistory'
import EditMTemplate from './components/whatsappCampaign/editTemplate'
import CpDashboardForUser from './components/channelPartner/cpDashboardForUser';
import UserIndex from '../src/components/manageUser/index'
import CreateUser from '../src/components/manageUser/createUser'
import IndexList from '../src/components/deploymentDashboard/IndexList'
import ViewDashboard from './components/channelPartner/viewDashboard';
import OutletsIndex from './components/outlets/index'
import CreateOulets from './components/outlets/createOulets'
import {UserContext} from './components/context/userConfigContext'
import ProfileDetails from './components/profile/profileDetails';
import RenewalDashboard from './components/renewalDashboard/renewalDashboard'
import MyDashboard from './components/channelPartner/myDashboard';
import HOC from './utils/HOC'
import NonUsageDashboard from './components/nonUsageDashboard/nonUsageDashboard';
import OutstandingDashboard from './components/outstandingDashboard/outstandingDashboard';
const WithUserContext = (Component) => {
    return (props) => {
      return <UserContext.Consumer>
            {permissions => {
           return <Component {...props} userData={permissions} />
          }
    }
        </UserContext.Consumer>
    }
  }

  const SelecteComp = (props) =>{
      if(props.userData.userRole == "CP"){
       return <Route exact path="/" component={WithUserContext(MyDashboard)} />
      }else{
    return   <Route exact path="/" component={WithUserContext(HOC(SiteList,'site','view_site_list'))} />
      }
  } 

const Routes = (props) => (

   <> 
    <Router>
        <Fragment>

            {SelecteComp(props)}
            <Route exact path="/create-site" component={WithUserContext(HOC(CreateSite,'site','create_new_site'))} />
            <Route exact path="/edit-site" component={WithUserContext(HOC(EditSite,'site','edit_site'))}/>
            <Route exact path="/site-pages" component={WithUserContext(HOC(StaticPage,'staticPage','static_pages'))}/>
            <Route exact path="/create-site-pages" component={WithUserContext(HOC(CreateStaticPage,'staticPage','create_new_page'))}/>
            <Route exact path="/edit-site-pages" component={WithUserContext(HOC(EditStaticPage,'staticPage','edit_page'))}/>
            <Route exact path="/customer-list" component={CustomerList}/>
            <Route exact path="/email-Customer" component={EmailCamp}/>
            <Route exact path="/service-list" component={WithUserContext(HOC(ServiceList,'root'))}/> 
            <Route exact path="/create-service" component={WithUserContext(HOC(CreateService,'root'))}/> 
            <Route exact path="/create-extra-service" component={WithUserContext(HOC(ExtraService,'root'))}/> 
            <Route exact path="/add-extra-service" component={WithUserContext(HOC(AddExtraService,'root'))}/>  
            <Route exact path="/platform-servicelist" component={WithUserContext(HOC(PlatformService,'root'))}/>              
            <Route exact path="/create-platform" component={WithUserContext(HOC(CreatePlatform,'root'))}/>              
            <Route exact path="/edit-platform" component={WithUserContext(HOC(EditPlatform,'root'))}/>              
            <Route exact path="/subscription-plan" component={WithUserContext(HOC(SubscriptionPlan,'root'))}/>              
            <Route exact path="/create-subscription" component={WithUserContext(HOC(CreateSP,'root'))}/>              
            <Route exact path="/edit-subscription" component={WithUserContext(HOC(EditSP,'root'))}/>              
            <Route exact path="/service-typelist" component={WithUserContext(HOC(ServiceTypeList,'manageMenu','view_Menu'))}/>              
            <Route exact path="/add-serviceType" component={WithUserContext(HOC(AddServiceType,'manageMenu','add_menu'))}/>              
            <Route exact path="/template-list" component={WithUserContext(HOC(TemplateList,'campaigns','campaigns_templates'))}/>     
            <Route exact path="/global-template" component={WithUserContext(HOC(GlobalTemplateList,'globalTemplates','view_global_templates'))}/>       
            <Route exact path="/addg-templates" component={WithUserContext(HOC(AddGTemplates,'globalTemplates','create_global_templates'))}/>     
            <Route exact path="/editg-templates" component={WithUserContext(HOC(EditGTemplate,'globalTemplates','edit_global_templates'))}/>              
            <Route exact path="/addm-templates" component={WithUserContext(HOC(AddMTemplates,'campaigns','campaigns_templates'))}/> 
            <Route exact path="/marketing-index" component={WithUserContext(HOC(SelectTemplate,'campaigns','run_campaigns'))}/>              
            <Route exact path="/campaign-history" component={WithUserContext(HOC(CampaignHistory,'campaigns','run_campaigns'))}/>              
            <Route exact path="/editm-templates" component={WithUserContext(HOC(EditMTemplate,'campaigns','campaigns_templates'))}/>              
            <Route exact path="/oulets-index" component={OutletsIndex}/>              
            <Route exact path="/create-oulets" component={CreateOulets}/>   

            <Route exact path="/deployment-dashboard" component={WithUserContext(HOC(IndexList,'onboarding_dashboard','onboarding_dashboard'))} />
            <Route exact path="/renewal-dashboard" component={WithUserContext(HOC(RenewalDashboard,'renewal_dashboard','renewal_dashboard'))} />
            <Route exact path="/nonusage-dashboard" component={WithUserContext(HOC(NonUsageDashboard,'nonusage_dashboard','nonusage_dashboard'))} /> 
            <Route exact path="/outstanding-dashboard" component={WithUserContext(HOC(OutstandingDashboard,'outstanding_dashboard','outstanding_dashboard'))} /> 
            <Route exact path="/my-dashboard" component={WithUserContext(MyDashboard)} />
            <Route exact path="/select-cp" component={WithUserContext(HOC(ViewDashboard,'cp_dashboard','cp_dashboard'))} />
            <Route exact path="/cp-dashboard" component={WithUserContext(HOC(CpDashboardForUser,'cp_dashboard','cp_dashboard'))} />
            <Route exact path="/my-profile" component={WithUserContext(ProfileDetails)} />
            <Route exact path="/manage-user" component={WithUserContext(HOC(UserIndex,'root'))}/>             
            <Route exact path="/create-user" component={WithUserContext(HOC(CreateUser,'root'))}/>             
        </Fragment>
    </Router>
    </>
);
export default WithUserContext(Routes)