import React, { Component } from 'react';
import { Button, Input, Row, Table, Icon,Modal} from 'antd'
import {Link} from 'react-router-dom';
import {  withApollo } from 'react-apollo';
import getInternalUsers from '../../queries/getInternalUsers'
import Highlighter from 'react-highlight-words';
import EditUser from './editUser'
import {externalPermissions,mainPermissions} from '../../utils/permissionsObj'
import App from '../../App'
class UserIndex extends Component {
    constructor(props){
        super(props)
        this.state={
            loading: false,
            userData: [],
            selectedUser:undefined
        }
    }
    componentDidMount() {
        this.getUserList()
    }
    getUserList=()=>{
      this.enterLoading()
      this.props.client.query({
          query: getInternalUsers,
          fetchPolicy: 'no-cache'
      }).then(({ data }) => {
              this.setState({
                  userData: data.getInternalUsers,
                  loading: false
              })
          })
    }
    enterLoading = () => {
        this.setState({ loading: true })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      handleCancel=()=>{
        this.setState({
          selectedUser: undefined,
          edit: false
        },()=>{
          this.getUserList()
        })
      }
      editUser=(record)=>{
        // console.log("editUser ::",record)
        const selectedOptionsTemp = record.permissions 
        let finalPermissions = [];
        let rootMenu = []
        if(selectedOptionsTemp && selectedOptionsTemp.length && selectedOptionsTemp.length > 0){
            selectedOptionsTemp.map((option) => {
              mainPermissions.map((root)=>{
                if(option.name == root.value){
                    if(option.operations && option.operations.length > 0){
                    finalPermissions.push(option.name)
                        option.operations.map((operation) => {
                            finalPermissions.push(`${option.name}::${operation}`)
                        })
                    }
                } 
                else {
                  rootMenu.push(option.name)
                }
              })
            })
        }
        // console.log("finalPermissions ::",finalPermissions,rootMenu)
        record.permissions = finalPermissions
        this.setState({
          selectedUser: record,
          edit: true
        })
      }
    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                ...this.getColumnSearchProps('mobile'),
            },
            {
                title: 'Role',
                dataIndex: 'userRole',
                key: 'userRole',
                render:(data)=>{
                    let role = data
                    return(
                        <span>{role.replaceAll("_", " ")}</span>
                    )
                }
            },
            {
                title: 'User Name',
                dataIndex: 'username',
                key: 'username',
                ...this.getColumnSearchProps('username'),
            },
            {
                title: 'Status',
                dataIndex: 'enabled',
                key: 'enabled',
                render:(status)=>{
                  return(
                    <span>{status ? 'Active' : 'Inactive'}</span>
                  )
                }
            },
            {
                title: 'Action',
                key: 'action',
                  render: (record) => (
                    <Button onClick={() => {
                        this.editUser(record)
                    }} type="edit" icon="edit" style={{ cursor: "pointer", background: "green", color: "#fff", marginLeft: '5px' }} />
                  ),
            },
        ];
        return (
            <App header={'Manage User'}>
                 <Row>
                <Link  to={{pathname:"/create-user"}}> 
                <Button id="submit" type="primary"  htmlType="submit" style={{ float: "right", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                    Create User
                 </Button>
                 </Link>
                 </Row>
                 <Row>
                 <Table
                    columns={columns}
                    dataSource={this.state.userData}
                    loading={this.state.loading}
                />
              <Modal visible={this.state.edit} footer={null} width={"55%"} onCancel={this.handleCancel} destroyOnClose={true}>
                <EditUser userData={this.state.selectedUser} cancel={this.handleCancel}/>
              </Modal>
                </Row>
            </App>
        );
    }
}

export default withApollo(UserIndex);