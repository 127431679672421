import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { Table, Button } from 'antd';

import App from '../../App'
import getCampaignHistoryByCreatedAtByAdmin from '../../queries/getCampaignHistoryByCreatedAtByAdmin'


class CampaignHistory extends Component {
    constructor(props){
        super(props)
        this.state={
            loading: false,
            data: []
        }
    }
    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    componentDidMount(){
        this.enterLoading()
        this.getList()
    }
    getList=()=>{
        this.enterLoading()
        this.props.client.query({
            query: getCampaignHistoryByCreatedAtByAdmin,
            variables:{
                siteId: 'master'
            },
            fetchPolicy: 'network-only',
        })
            .then(({ data }) => {
                console.log("getCampaignHistoryByCreatedAtByAdmin ::",data)
                this.setState({
                    data: data.getCampaignHistoryByCreatedAtByAdmin.items,
                    // allData: data.getCampaignHistoryByCreatedAtByAdmin,
                    loading: false
                })
            })
    }
    render() {
        const columns=[
            {
                title: 'Date',
                key: 'createdAt',
                width: '10%',
                render:(data)=>{
                    let dateString = moment.unix(data.createdAt).format("YYYY-MM-DD HH:mm");
                         return (
                        <span>{dateString}</span>
                    )
                }
            },
            {
                title: 'Type',
                dataIndex: 'queType',
                key: 'queType',
                width: '10%',
                render:(data)=>{
                    return(
                        <span>{data == 'WHATSAPP' ? 'Whatsapp' : 'Email'}</span>
                    )
                }
            },
            {
                title: 'Template',
                dataIndex: 'template',
                key: 'template',
                width: '10%',
                // render:(data)=>{
                //     return(
                //         <span>{data}</span>
                //     )
                // }
            },
            {
                title: 'Sent',
                dataIndex: 'sent',
                key: 'sent',
                width: '10%',
                render:(data)=>{
                    return(
                        <span style={{ color: 'green', fontWeight: '700'}}>{data}</span>
                    )
                }
            },
            {
                title: 'Failed',
                dataIndex: 'failed',
                key: 'failed',
                width: '10%',
                render:(data)=>{
                    return(
                        <span style={{ color: 'red', fontWeight: '700'}}>{data}</span>
                    )
                }
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                width: '10%',
            },
            {
                title: 'Status',
                dataIndex: 'sendingStatus',
                key: 'total',
                width: '10%',
                render:(data)=>{
                    return(
                        <span style={{color: data == 'PENDING' ? 'red' : data == 'COMPLETED' ? 'green' : 'orange', fontWeight: '700'}}>{data}</span>
                    )
                }
            },
            
        ]
        return (
            <App header={'Campaign History'}>
                <p style={{ textAlign: 'right'}}>
                    <Button type='primary' onClick={this.getList}>Refresh</Button>
                </p>
             <Table
                    dataSource={this.state.data}
                    columns={columns}
                    loading={this.state.loading}
                    pagination={{ pageSize: 10 }} 
                />   
            </App>
        );
    }
}

export default withApollo(CampaignHistory);