import gql from 'graphql-tag'

export default gql`
mutation generateSiteInvoice(
  $siteId: String!
  $transactionId: String!
  $date: String
  $billTo: String
  $custMob: String
  $custName: String
  $serviceName: String
  $amt: Float
  $total: Float
) {
generateSiteInvoice(input:{
  siteId: $siteId
  transactionId: $transactionId
  date: $date
  billTo: $billTo
  custMob: $custMob
  custName: $custName
  serviceName: $serviceName
  amt: $amt
  total: $total
}){
  invoiceId
  transactionId
  invoicePdf{
    bucket
    region
    key
  }
}
}

`
//  {
//        "siteId": "b148b379-a018-44e3-ae54-644bac405262",
//        "transactionId": "l6lzgmrjbogn76jf5rv",
//        "date": "2022-08-09",
//        "billTo": "prince resto",
//        "custMob": "8459832342",
//        "custName": "Sohel Khan",
//        "serviceName": "Online ordering",
//        "amt": 5000,
//        "total": 5500
//      }