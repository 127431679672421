import React , {useState,useRef} from 'react'
import { returnSymbolCurrFormate } from '../../utils/returnSymbol'
import {Input, Button, Table,Icon } from 'antd';
import moment from "moment";
import Highlighter from 'react-highlight-words';

function siteDetails(props) {
     const [searchText,setSearchText] = useState('')
     const searchInputRef = useRef(null);
    const {siteDetails} = props

   const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0] )
    }

   const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
   }

   const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInputRef}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInputRef.current.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      })

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'companyName',
            align: 'left',
            key: 'companyName',
            ...getColumnSearchProps('companyName'),
            width: 100,
            render: (data, record, index) => {
                    return (<p>{data}</p>)
            }
        },
        {
            title: 'Domain',
            dataIndex: 'fqdn',
            key: 'fqdn',
            align: 'left',
            width: 100,
            render: (data, i, index) => {
                    return (
                        <p>{data}</p>
                    )
                }
        },
        {
            title: 'Suuport Account Manager',
            dataIndex: 'supportAccManager',
            key: 'supportAccManager',
            align: 'left',
            width: 100,
            render: (data, i, index) => {
                    return (
                        <p >{data}</p>
                    )
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => {
                return a.createdAt - b.createdAt
              },
            align: 'left',
            width: 100,
            render: (data, i, index) => {
                    return (
                        <p >{ moment.unix(data).format("DD-MM-YYYY")}</p>
                    )
            }
        },
        {
            title: 'Pending balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'center',
            width: 100,
            render: (data) => {
                return (
                    <p style={{ fontWeight: "bold", textAlign: "right", marginRight: "10px" }}>{returnSymbolCurrFormate(Math.round(data), "INR")}</p>
                )
            }
        }
    ]

  return (
    siteDetails ?   
    <div style={{marginTop:"10px"}}>
          <p style={{fontSize:"16px", fontWeight:"bold", background:"rgb(230 230 230)",padding:"10px",borderRadius:"10px"}}> Sales Person: {siteDetails.name}</p>
        <Table
                    columns={columns}
                    className='sitetable'
                    bordered
                    dataSource={siteDetails.sites}
                    // loading={loading}
                    size="middle"
                    rowKey="id"
                    scroll={{ y: 800, }}
                    pagination={{ pageSize: 50 }}
             />
    </div> : <></>
  )
}

export default siteDetails;
