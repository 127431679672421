import gql from 'graphql-tag'

export default gql`
mutation createSiteWallet(
  $siteId: String!
  $balance: Int! 
  $currency: CURRENCY_CODE
  $domain: String!
){
createSiteWallet(input:{
  siteId: $siteId
  balance: $balance
  currency: $currency
  domain: $domain
})
}`
//  {
    //  "siteId": "2de3524-c60f-41fb-8b4c-42fea01e844d",
    //  "balance": 0,
    //  "currency": "INR",
    //  "domain": "test.sohel.com"
  //  }