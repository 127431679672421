import gql from 'graphql-tag'

export default gql`
query getSiteConfig( $domain: String!){
    getSiteConfig( domain: $domain){
      domain
      typeValue
      siteId
      enabled
      siteServices{
        serviceName
        chargingModel
        charges
        status
        masterStatus
        startedAt
        updatedAt
        startDate
        endDate
        notes
      }
      createdAt
      updatedAt
    }
  }
`  