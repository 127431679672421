// import { withApollo } from "react-apollo"

import uploadFile from "../mutation/uploadFile"

const uploadbase64Files=(client, files)=>{
    return new Promise((resolve,reject)=>{
    
    try {
        

    client.mutate({
        mutation: uploadFile,
        variables:{
            "files": files
        },
        // fetchPolicy: 'network-only',
       
    })
        .then(({ data }) => {
            resolve(data.uploadFile)
        })
        .catch(err => {
            console.log(`Error : ${JSON.stringify(err)}`)

        })
        
        } catch (error) {
               console.log("In catch ::", error) 
        }
})
}

export default uploadbase64Files