import gql from 'graphql-tag'
export default gql`
mutation updateInternalUser(
    $siteId: String!
    $username: String!
    $userRole: String
    $emailId: String!
    $mobile: String!
    $name: String
    $enabled: Boolean
    $permissions: [UserPermissionsInput]
    $address: GenericAddressInput
    $companyName: String
    $gstNo: String
    $panNo: String
    $onboardedBy: String
    $supportNumber: String
    $empsDetails: [EmpsDetailInput!]
){
  updateInternalUser(input:{
    siteId: $siteId
    username: $username
    userRole: $userRole
    emailId: $emailId
    mobile: $mobile
    name: $name
    enabled: $enabled
    permissions: $permissions
    address: $address
    companyName: $companyName
    gstNo: $gstNo
    panNo: $panNo
    onboardedBy: $onboardedBy
    supportNumber: $supportNumber
    empsDetails: $empsDetails
  }){
    siteId
    username
    userRole
    emailId
    mobile
    name
    enabled
    createdAt
    permissions{
      name
      operations
    }
    supportNumber
     empsDetails{
        name 
        mobile
        email
        designated
      }
    companyName
    gstNo
    panNo
    onboardedBy
  }
}`

//  {
//        "siteId": "master",
//        "username": "internal1",
//        "userRole": "ACCOUNTANT",
//        "emailId": "sohelkhan@amonex.in",
//        "mobile": "8459832342",
//        "name": "Sohel",
//        "enabled": true,
//        "permissions":[{
//          "name": "test1",
//          "operations": ["fs","fdsf", "xyz","Fsdf"]
//        }]
//      }