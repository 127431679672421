import gql from 'graphql-tag'

export default gql`
mutation createSiteExtraService(
    $siteId: String!
      $amt: Float!
      $currency: CURRENCY_CODE
      $serviceName: String!
      $note: String
  ){
    createSiteExtraService(input:{
      siteId: $siteId
      amt: $amt
      currency: $currency
      serviceName: $serviceName
      note: $note
    })
  }`

//    {
//        "siteId": "e6e15d4e-8a5a-44c9-a737-3f2e6d14796b",
//        "amt": 701,
//        "currency": "INR",
//        "serviceName": "Plan basic",
//        "note": "test"
//     }