import gql from 'graphql-tag'
export default gql`
mutation createServiceType(
    $siteId: String!
    $slugName: String!
	$typeName: String!
	$typeDes: String
    $masterServiceType: String
	$image: S3ObjectInput
	$typeStatus: Boolean
	$displayOrder: Int
    $aggregatorMerchantId: String
    $isAggregatorEnabled: Boolean
    $aggregatorSystem: String
    $reservationType: String
 ){
    createServiceType(input: {
        siteId: $siteId
        slugName:  $slugName
        typeName: $typeName
        typeDes: $typeDes
        masterServiceType: $masterServiceType
        image: $image
        typeStatus: $typeStatus
        displayOrder: $displayOrder
        aggregatorMerchantId: $aggregatorMerchantId
        isAggregatorEnabled: $isAggregatorEnabled
        aggregatorSystem: $aggregatorSystem
        reservationType: $reservationType
    }){
        slugName
        typeName
        masterServiceType
        typeDes
        aggregatorMerchantId
        isAggregatorEnabled
        aggregatorSystem
        reservationType
        image {
            bucket
            region
            key
        }
    }
   
  }
  
`  