import gql from 'graphql-tag'

export default gql`
mutation updateCustomWhatsappConfig(
    $productId: String
    $phoneId: String
    $token: String
    $apiKey: String
    $system: String 
    $domain: String!
  ){
    updateCustomWhatsappConfig(input:{
      productId: $productId
      phoneId: $phoneId
      token: $token
      apiKey: $apiKey
      system: $system
      domain: $domain
    }){
      domain
      typeValue
      productId
      phoneId
      token
      apiKey
      system
      updatedAt
      domain
    }
  }
`