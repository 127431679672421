import gql from 'graphql-tag'

export default gql`
query getRenewalCount($fromDate: Int!, $toDate: String!, $pastDate: Int!){
    getRenewalCount(fromDate: $fromDate, toDate: $toDate, pastDate: $pastDate)
}`

// {
//     "fromDate": 1707383939,
//   	"toDate": "2024-05-08",
//    	"pastDate": 1691745508,
// 		"currentDate": "2024-02-08"
//  }