import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Table, Row, Button } from 'antd';
import { Link } from 'react-router-dom';

import App from '../../App'
import getGroupAdmins from '../../queries/getGroupAdmins'

class OutletsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            groupData: []
        };
    }
    componentDidMount() {
        this.enterLoading()
        this.props.client.query({
            query: getGroupAdmins,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
                console.log('dataa', data)
                this.setState({
                    groupData: data.getGroupAdmins,
                    loading: false
                })
            })
    }
    enterLoading = () => {
        this.setState({ loading: true })
    }
    render() {
        const columns = [
            {
                title: 'Comapany Name',
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: 'Customer Name',
                dataIndex: 'custName',
                key: 'custName',
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: 'User Name',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: 'Action',
                key: 'action',
                //   render: (text, record) => (
                //     <span>
                //       <a>Invite {record.name}</a>
                //       <a>Delete</a>
                //     </span>
                //   ),
            },
        ];
        return (
            <App header={"Outlets"}>
                <Row>
                    <Link to="/create-oulets">
                        <Button type="primary" style={{ float: "right", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Create New Outlets
                        </Button>
                    </Link>
                </Row>
                <Table
                    columns={columns}
                    dataSource={this.state.groupData}
                    loading={this.state.loading}
                />
            </App>
        );
    }
}

export default withApollo(OutletsIndex);