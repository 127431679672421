import gql from 'graphql-tag'
export default gql`
mutation updateTempleteByAdmin(
  $siteId: String
    $slugName: String!
  $tempName: String
  $tempSubject: String
  $text: String
  $type: String
  $image: S3ObjectInput
) {
updateTempleteByAdmin(input:{
  siteId: $siteId
  slugName: $slugName
  tempName: $tempName
  tempSubject: $tempSubject
  text: $text
  type: $type
  image: $image
}){
  siteId
  slugName
  tempName
  tempSubject
  text
  type
  image{
    bucket
    region
    key
  }
  createdAt
  updatedAt
}
}`
//  {
    //    "slugName": "test",
    //    "tempName": "Test",
    //    "tempSubject": "Hello Test",
    //    "text": "Hello Ajju...",
    //    "type": "WHATSAPP",
    //    "image": {
        //  "bucket": "fsdf",
        //  "region": "das",
        //  "key": "dsdsf"
    //    }
    //  }
