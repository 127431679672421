import gql from 'graphql-tag'
export default gql`
query getTempleteByUpdatedAtBySiteId($siteId: String!){
    getTempleteByUpdatedAtBySiteId(siteId: $siteId){
      items {
        id siteId
        slugName
        tempName
        tempSubject
        text
        type
        image{
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }`