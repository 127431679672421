import React from "react";
import { Form, Input, Icon, Button, Col,Row,Select,message,Radio, Upload,Popconfirm } from 'antd';
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import uuidv1 from 'uuid/v1';
import slugify from 'slugify';
import App from '../../App'
import createServiceType from '../../mutation/createServiceType'
import getServiceTypesBySiteId from '../../queries/getServiceTypesBySiteId'
import getServiceBySiteId from '../../queries/getServiceBySiteId'
import updateServiceInBulkV2 from '../../mutation/updateServiceInBulkV2'
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
class AddServiceType extends React.Component{
    constructor(props){
        super(props)
        this.state={
            value: true,
            loading: false,
            menuData: [],
            imageUpload: {},
            uploadSizeError: false,
            notes: undefined,
        }
    }

    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    componentDidMount(){
        this.getServiceType()
    }
    bucket = process.env.REACT_APP_BUCKET
    s3_Region = process.env.REACT_APP_S3_REGION

    notesChange=(e)=>{
      this.setState({
        notes: e.target.value
      })
    }
    handleSubmit =()=>{
        this.props.form.validateFields((err, values) => {
           if (!err && this.state.notes) {
              //  console.log("form values",values)
               const slugName = slugify(values.menuName, {
                lower: true
            })
            // this.updateAllservice(values.menu,slugName)
              //  return

            if(this.state.uploadSizeError && this.state.imageUpload && Object.keys(this.state.imageUpload).length > 0){
                message.error("Please Upload Image of correct file size.")
                return;
            }

            const siteId = this.props.location.state.siteData.siteId

            if(this.state.imageUpload){
                if (Object.keys(this.state.imageUpload).length != 0) {
                  if (!this.state.imageUpload.hasOwnProperty("key")) {
                    values.imageUpload = {
                      bucket: this.bucket,
                      region: this.s3_Region,
                      localUri: this.state.imageUpload.originFileObj,
                      key: `${siteId}/menu/${uuidv1()}`,
                      mimeType: this.state.imageUpload.type
                    }
                    console.log("if :::")
                  } else {
                    const { bucket, key, region } = this.state.imageUpload
                    values.imageUpload = {
                      bucket,
                      key,
                      region
                    }
                    console.log("else :::")
                  }
                } else {
                  values.imageUpload = null;
                }
              }
              
              const variable = {
                siteId: 'serviceType::'+siteId,
                slugName:  slugName,
                typeName: values.menuName,
                typeDes: values.menuDes ? values.menuDes : undefined,
                typeStatus: values.menuStatus,
                displayOrder: null,
                image: values.imageUpload,
            }

            // console.log("variable  ",variable, this.s3_Region)
            // return;
            
            this.enterLoading()
            this.props.client.mutate({
                mutation: createServiceType,
                variables: variable
            }).then(({ data }) => {
                console.log("data response",data)
                // if(data && data.createSiteMenuItem.id){
                //   message.success('Menu Successfully Created');
                // }
                // this.setState({
                //     loading: false
                // })
                this.updateAllservice(values.menu,slugName)
                this.createLogs()

              })
              .catch(res => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
              });
           }else{
            if(this.state.notes == undefined){
              message.error("Please Enter Notes")
            }
            this.setState({
              loading: false
            })
           }
        })
    }
    updateAllservice=async(serviceType,slugName)=>{
        console.log("In updateAllservice::",serviceType)
      let serData = await  this.getServiceByServiceType()
      console.log("Service Data",serData)
        let finalData = []
      serData.map((val)=>{
          val.serviceRates.map(val => {
            delete val.__typename;
          });
        //  console.log(val.serviceRates)
        val.addons &&
        val.addons != [] &&
        val.addons.length > 0 &&
        val.addons.map((val) => {
          delete val.__typename;
        });

         let newMenu = {
            serviceType: slugName,
            rate: val.serviceRates[0] ? val.serviceRates[0].rate : null,
            status: val.serviceRates[0] ? val.serviceRates[0].status : null,
            listRate: val.serviceRates[0] ? val.serviceRates[0].listRate : null
         } 
         val.serviceRates.push(newMenu)
        //  console.log("Final Arr",val.serviceRates)
        val.image && delete  val.image.__typename;

      val.additionalImages &&
      val.additionalImages != [] &&
      val.additionalImages.length > 0 &&
      val.additionalImages.map((ele) => {
        delete ele.__typename;
      });

        let  obj={
            slugName:val.slugName,
            serviceName: val.serviceName,
            serviceDes: val.serviceDes,
            serviceRates:val.serviceRates,
            serviceStatus: val.serviceStatus,
            attachedCat:val.attachedCat,
            station: val.station,
            addons: val.addons,
            image: val.image,
            additionalImages: val.additionalImages
            } 
            finalData.push(obj)
      })
      console.log("Final All Data",finalData)

    //   return
      let arr = []
      let res=15
      for(let i=0;i<finalData.length;i++){
                  arr.push(finalData[i])
                  if(i == res ){
                     let ress = await this.sendDataEdit(arr)
                      if(ress == true){
                          res=res+15
                          arr=[]
                      }
                  }
              
      }
      this.sendDataEdit(arr)
                message.success('Menu Successfully Created');
                this.setState({
                    loading: false
                })
    }
    sendDataEdit=(data)=>{
        console.log("Bulk Data",data)
        return new Promise((resolve, reject) => {
        this.props.client.mutate({
          mutation: updateServiceInBulkV2,
          variables: {
            input: data,
          }
      }).then(({ data }) => {
          console.log("Bulk response",data)
          resolve(true)
          // message.success("Successfully Services Created")
        })
      })
      }
      getServiceByServiceType=()=>{
        return new Promise((resolve, reject)=>{
             this.props.client.query({
                query: getServiceBySiteId,
                variables: {
                  siteId: this.props.location.state.siteData.siteId
                },
                fetchPolicy: 'network-only'
              }).then(({ data }) => {
                  console.log("getServiceBySiteId ::",data)
                    resolve(data.getServiceBySiteId)
                })
                .catch(err => {
                    console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
                  })
        })
    }
    createLogs=async()=>{
      let obj = {
          siteId: this.props.location.state.siteData.siteId,
          eventName: "Setup",
          eventType: 'MANUAL',
          // reason: this.state.selectedReason,
          note: this.state.notes
      }
      let resp = await SiteActionEvent(this.props,obj)
      if(resp){
        this.setState({
          notes: undefined
        })
      }
   
  }
    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
      getServiceType = ()=>{

        this.props.client.query({
             query: getServiceTypesBySiteId,
             variables:{
                 siteId: this.props.location.state.siteData.siteId
             },
             fetchPolicy: 'no-cache'
           })
             .then(({ data }) => {
               console.log('Menu', data)
               this.setState({
                   menuData: data.getServiceTypesBySiteId,
                   loading: false
               })
             })
             .catch(err => {
               console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
             })
     
      }

      handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
          if (
            Object.keys(prevState.imageUpload).length === 0 &&
            prevState.removedIconImg === false
          ) {
            return {
              imageUpload: file,
              removedIconImg: false,
            };
          } else {
            return {
              removedIconImg: false,
            };
          }
        });
      };
    
      handleMainImageRemove = (file) => {
        this.setState({ imageUpload: {}, removedIconImg: true });
        this.props.form.resetFields(['imageUpload'])
        return true;
      };
    
      handlePreview = (file) => {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
        });
      };

      beforeUpload = (file) => {
        var fr = new FileReader;
        fr.onload = () => { // file is loaded
            var img = new Image;
            img.onload = () => {
  
                if (img.width != 267 && img.height != 267) {
                  this.setState({
                    uploadSizeError: true
                  })
                  this.props.form.setFields({
                    imageUpload:{
                      value:this.state.siteLogo,
                      errors:[new Error('Please Upload image with Height and Width of 267px dimensions.')]
                    }
                  })
                  return false
                }else{
                  this.setState({
                    uploadSizeError: false
                  })
                }
              };
  
            img.src = fr.result; // is the data URL because called with readAsDataURL
          
        };
        fr.readAsDataURL(file); // I'm using a <input type="file"> for demonstrating
      }

    render(){
        // console.log("props::",this.props.location.state.siteData)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const imageUpload = this.state.imageUpload    
        const uploadButton = (
            <div>
              <Button>
                <Icon type="upload" /> Click to Upload
              </Button>
            </div>
        );
        return(
            <App header={"Create Menu"}>
            <Form style={{marginTop: '10px'}}>
                    <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Menu Name"
                            >
                                {getFieldDecorator(`menuName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Menu Name",
                                    }]
                                })(
                                    <Input placeholder="Menu Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Menu Description"
                            >
                                {getFieldDecorator(`menuDes`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Menu Description",
                                    }]
                                })(
                                    <Input placeholder="Menu Description" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} style={{display:'flex'}}>
                          <span className='status' style={{marginTop:'2vw',marginRight:'2vw'}}>Status</span>
                      <FormItem  className="status-lable">
                      {getFieldDecorator(`menuStatus`, {
                                //    trigger: 'onBlur',
                                //    valuePropName: 'defaultValue',
                                   initialValue: this.state.value,
                                    rules: [{
                                        required: true,
                                        message: "Please Select Menu Status",
                                    }]
                                })(
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                             )}
                      </FormItem>
                      </Col>
                      </Row>
                    <Row gutter={16}>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Menu image"
                            >
                                {getFieldDecorator(`imageUpload`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Menu Description",
                                    }]
                                })(
                                    <Upload
                                        name = 'file'
                                        // listType="picture-card"
                                        data={imageUpload}
                                        // onPreview={this.handlePreview}
                                        onChange={this.handleMainImage}
                                        onRemove={this.handleMainImageRemove}
                                        beforeUpload={this.beforeUpload}
                                    >
                                    {Object.keys(imageUpload).length != 0
                                        ? null
                                        : uploadButton}
                                    </Upload>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem
                                label="Copy Menu From"
                            >
                                {getFieldDecorator(`menu`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: false,
                                        message: "Please Select Menu",
                                    }]
                                })(
                                    <Select placeholder="Select Menu"
                                        
                                            showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%' }}
                                    >
                                        {this.state.menuData != "" && this.state.menuData.map((temp, index) => {
                                            return (
                                                <Option value={temp.slugName} key={temp.slugName}>{temp.typeName}</Option>

                                            )
                                        })
                                        }
                                    </Select>

                                  
                                )}
                            </FormItem>
                        </Col>
                    </Row> 
                    <Row gutter={16}>
                        <Col span={24}>
                        <Popconfirm     onConfirm={this.handleSubmit}  title={        <TextArea    rows={2}  value={this.state.notes}    placeholder="Notes" onChange={this.notesChange}  />} okText="Save" >
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            </Popconfirm>
                            <Link to="/">
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                            </Link>
                        </Col>    
                    </Row>
                </Form>
            </App>
        )
    }
}

const WrappedAddServiceType = Form.create({ name: "JMenu_form" })(
    AddServiceType
  );
export default withApollo(WrappedAddServiceType)