import gql from "graphql-tag";

export default gql`
  mutation deleteTemplete($siteId: String!, $slugName: String!) {
    deleteTemplete(siteId: $siteId, slugName: $slugName) {
        id
        siteId
        slugName
    }
  }
`;