import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button,Spin, Input,Popconfirm, Tooltip,Table, message,Modal,Icon} from 'antd';
import {  withApollo } from 'react-apollo';
import Highlighter from 'react-highlight-words';
import App from '../../App'
import DetailsView from './detailsView'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId' 
import deleteSubscriptionPlan from '../../mutation/deleteSubscriptionPlan' 
const Delete  = <span>Delete</span>;
class SubscriptionPlan extends Component {
    constructor(props){
    super(props)
    this.state={
        data: [],
        loading: false,
        visibleM: false,
        modalData:{}
    }
}
componentDidMount(){
    this.getSP()
}
enterLoading=()=>{
    this.setState({
        loading: true
    })
}

modalChange=(data)=>{
  // console.log("Data",data)
  this.setState({
    visibleM: true,
    modalData:data
  })
}
handleCancel=()=>{
  this.setState({
    visibleM: false
})
}
getSP=()=>{
    this.enterLoading()
    this.props.client.query({
        query: getSubscriptionPlansBySiteId,
        variables: {
            siteId: "master"
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          console.log('data sp', data)
          let arr = data.getSubscriptionPlansBySiteId
          let sort = arr.sort(function(a, b) {
            return b.createdAt - a.createdAt;
        });
          this.setState({
            data: sort,
            loading: false
          })
        })
        .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
        })
}

delete=(data1)=>{
console.log("Data1",data1)
  // return
  this.props.client.mutate({
    mutation: deleteSubscriptionPlan,
    variables: {
      siteId: data1.siteId,
      planId: data1.planId
    }
}).then(({ data }) => {
  console.log("Data1",data)
    if(data.deleteSubscriptionPlan){
      let arr = this.state.data;
   let i = arr.findIndex(x => x.planId == data.planId)
      arr.splice(i,1)
     console.log("arrrr1",arr)
      this.setState({
        data:arr
      })
      message.success("Successfully Subscription Deleted")
      
    }
  })
  .catch(res => {
    console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
  });
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};
    render() {
        const columns = [
            {
              title: 'Plan Name',
              dataIndex: 'planName',
              key: 'planName',
              ...this.getColumnSearchProps('planName'),
            },
            {
              title: 'Master Service',
              dataIndex: 'masterService',
              key: 'masterService',
              ...this.getColumnSearchProps('masterService'),
            },
            {
              title: ' Site Type',
              dataIndex: 'siteType',
              key: 'siteType',
              ...this.getColumnSearchProps('siteType'),
            },
            {
              title: 'Site Option',
              dataIndex: 'platform',
              key: 'platform',
              ...this.getColumnSearchProps('platform'),
            },
            {
              title: "Price List",
              dataIndex: 'priceList',
              children: [
                {
                  title: 'PAY PER USE',
                  dataIndex: 'priceList',
                  key: 'building',
                  render:(data)=>{
                    return <span>{data[0].rate/100}</span>
                  }
                },
                {
                  title: 'MONTHLY',
                  dataIndex: 'priceList',
                  key: 'building',
                  render:(data)=>{
                    return <span>{data[1].rate/100}</span>
                  }
                },
                {
                  title: 'YEARLY',
                  dataIndex: 'priceList',
                  key: 'building',
                  render:(data)=>{
                    return <span>{data[2].rate/100}</span>
                  }
                },
              ]
            },
            {
                title: 'Action',
                render: (data) => {
                    // console.log("data ::",data)
                      return (
                          <span >
                            {/* <Button  type= "danger" onClick={()=>this.modalChange(data)} icon="eye"  style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000 ", color:"#fff"}}></Button> */}
                            <Link to={{pathname:"/edit-subscription",state:{spData:data}}}>
                            <Button  type= "danger"  icon="edit"  style={{marginLeft:"5px",cursor: "pointer", background:"#ff0000 ", color:"#fff"}}></Button>
                            </Link>
                            <Tooltip placement="top" title={Delete}>
                              <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(data)}>
                                <Button icon="delete" type="danger" style={{ marginLeft: "5px", cursor: "pointer", background: "#ff0000", color: "#fff" }} />
                              </Popconfirm>
                            </Tooltip>
                          </span>
                      )
              }
            }
          ];
        return (
            <App header={'Subscription Plan'}>
                <p style={{ textAlign: 'end' }}>
                            <Link to="/create-subscription">
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                Create Subscription Plan
                                </Button>
                            </Link>
                           
                      
                        </p>
                        {this.state.loading ? 
                         <div style={{textAlign:"center"}}>
                         <Spin size="large"/>
                     </div> : 
                     <Table
                     bordered
                     dataSource={this.state.data}
                     columns={columns}
                     pagination={{ pageSize: 50 }}
                     />
                    }
                    <Modal
                    title={this.state.modalData.planName}
                    visible={this.state.visibleM}
                    onCancel={this.handleCancel}
                    footer={null}
                    width="70%"
                  >
                    <DetailsView data={this.state.modalData}/>
                  </Modal>
                
            </App>
        );
    }
}

export default withApollo(SubscriptionPlan);