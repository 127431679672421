import React, { Component } from 'react';
import { Button, Icon, Input, Row, Col, Table, message, Select, Modal, Form, Drawer } from 'antd';
import { withApollo } from 'react-apollo';
import moment from 'moment'
import App from '../../App'
// import getSiteOrdersCount from '../../queries/getSiteOrdersCount'
import getInternalUsers from '../../queries/getInternalUsers'
import SiteActionEvent from '../../funComponent/SiteActionEvent'
import SiteActionEventQuery from '../../funComponent/SiteActionEventQuery'
import getInternalDump from '../../queries/getInternalDump'
import PerformanceDashboard from './performanceDashboard';
import Axios from 'axios';


const FormItem = Form.Item;
const { TextArea } = Input;
class IndexList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteData: [],
      performanceDashboardData: [],
      allSiteData: [],
      betweenDates: [],
      InternalUserData: [],
      notesList: [],
      companyName: undefined,
      supportAccM: undefined,
      selectedCategory: undefined,
      salesPerson: undefined,
      partnerType: undefined,
      partnerName: undefined,
      loading: false,
      notesLoading: false,
      notesgetLoading: false,
      visible: false,
      siteDetails: null,
      visiblePerformanceDashboard: false,
      notUsing:undefined
    }
  }
  truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    } else {
      return str;
    }
  }
  componentDidMount() {
    this.getSites()
    if (this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == 'SUPPORT_EXECUTIVE' || this.props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD' || this.props.userData.userRole == 'SITE_CREATOR' || this.props.userData.userRole == 'SUPPORT_ACCOUNT_MANAGER')) {
      this.getUsers()
    }
  }
  getSites = async () => {
    this.setState({
      loading: true
    })
    const oneMonthagoDate = moment().subtract(3, "month");
    const currentDate = moment();
    const startDate = moment(oneMonthagoDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');
    const endDate = moment(currentDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');

    // Create an array to store the between dates
    let betweenDates = [];

    // Clone the start date for iteration
    let currentDate1 = startDate.clone();

    // Iterate through the dates and add them to the array
    while (currentDate1.isSameOrBefore(endDate, 'day')) {
      betweenDates.push(currentDate1.format('DD-MM-YYYY'));
      currentDate1.add(1, 'day');
    }
    betweenDates.reverse()


    let respDate = this.getTimeStamp(oneMonthagoDate, currentDate)
    let supportAccManager = undefined
    let salesPerson = undefined
    if (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD') {
      supportAccManager = undefined
    }
    if (this.props.userData.userRole == 'SUPPORT_ACCOUNT_MANAGER') {
      supportAccManager = this.props.userData.name
    }
    if (this.props.userData.userRole == 'SALES_PERSON') {
      salesPerson = this.props.userData.name
    }
    let dump = await this.checkDump()
    if (dump && dump.getInternalDump) {
      // if(false){
      let sdf = JSON.parse(dump.getInternalDump.dumpData)
      let filterData = [],TotalSubscriptionAmonut = 0;
      sdf.map((ele,i) => {
        let obj = {
          ...ele,
          companyNameSlug: ele.companyName.toLowerCase(),
          seqNo: i+1
        }
        filterData.push(obj)
        let amount = ele.balance ? ele.balance :  0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
      })

      let balanceAmtRecord = {
        balance : TotalSubscriptionAmonut
      }
   
       let filterDataWithTotalBalanceAmountRecord = JSON.parse(JSON.stringify(filterData)) 
       if(filterDataWithTotalBalanceAmountRecord && filterDataWithTotalBalanceAmountRecord.length > 0){
        filterDataWithTotalBalanceAmountRecord.push(balanceAmtRecord)
        }
     
      this.setState({
        betweenDates: betweenDates,
        siteData: filterDataWithTotalBalanceAmountRecord,
        performanceDashboardData: filterData,
        allSiteData: filterData,
        loading: false
      })
    } else {
      let username = undefined;
      // console.log("userData::",this.props.userData)
      if (this.props.userData.userRole != 'ADMIN') {
        username = this.props.userData.username
      }
      const obj = {
        fromDate: respDate.startTimestamp,
        toDate: respDate.endTimestamp,
        supportAccManager: supportAccManager,
        salesPerson: salesPerson,
        username: username
      }
      const data = await this.getOnboardingData(obj)
      // console.log("data.result :::", data)
      let record = data.result ? JSON.parse(data.result) : []
      // console.log("record :::", record)
      let filterData = [], TotalSubscriptionAmonut = 0
      record.map((ele,i) => {
        let obj = {
          ...ele,
          companyNameSlug: ele.companyName.toLowerCase(),
          seqNo: i+1
        }
        filterData.push(obj)
        let amount = ele.balance ? ele.balance :  0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
        
      })
      
      let balanceAmtRecord = {
        balance : TotalSubscriptionAmonut
      }
   
       let filterDataWithTotalBalanceAmountRecord = JSON.parse(JSON.stringify(filterData)) 
       if(filterDataWithTotalBalanceAmountRecord && filterDataWithTotalBalanceAmountRecord.length > 0){
        filterDataWithTotalBalanceAmountRecord.push(balanceAmtRecord)
        }
      // console.log("query filterData:::",filterData)
      
      this.setState({
        betweenDates: betweenDates,
        siteData: filterDataWithTotalBalanceAmountRecord,
        performanceDashboardData: filterData,
        allSiteData: filterData,
        loading: false
      })


      // this.props.client.query({
      //     query: getSiteOrdersCount,
      //     variables: {
      //       fromDate: respDate.startTimestamp,
      //       toDate: respDate.endTimestamp,
      //       supportAccManager: supportAccManager,
      //       salesPerson: salesPerson,
      //       username: username
      //   },
      //     fetchPolicy: 'no-cache'
      // }).then(({ data }) => {
      //   // console.log("getSiteOrdersCount",JSON.parse(data.getSiteOrdersCount))
      //   let record = JSON.parse(data.getSiteOrdersCount)? JSON.parse(data.getSiteOrdersCount) : []
      //   let filterData = []
      //   record.map((ele)=>{
      //     let obj={...ele,
      //       companyNameSlug: ele.companyName.toLowerCase(),
      //     }
      //     filterData.push(obj)
      //   })
      //         this.setState({
      //           betweenDates: betweenDates,
      //           siteData:filterData,
      //           allSiteData:filterData,
      //           loading: false
      //         })
      // })
    }
  }

  getTotalBalanceAmount = (filterData) => {
    let TotalSubscriptionAmonut = 0;
    for(let i = 0;i<filterData.length;i++){
      let amount = filterData[i].balance ? filterData[i].balance :  0;
      TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
    }
   
    let obj = {
      balance : TotalSubscriptionAmonut
    }
 
     let recordWithTotalSubAmt = JSON.parse(JSON.stringify(filterData)) 
     if(recordWithTotalSubAmt && recordWithTotalSubAmt.length > 0){
      recordWithTotalSubAmt.push(obj)
      }

      return recordWithTotalSubAmt;
  }

  getOnboardingData = (param) => {
    // console.log("param :::", param)
    return new Promise((resolve, reject) => {
      Axios({
        method: "GET",
        url: `https://analyser.mysnaptrade.com/onboarding_dashboard`,
        // url: `http://localhost:3202/onboarding_dashboard`,
        params: param,
      })
        .then(function ({ data }) {
          resolve(data)
        })
        .catch(function (error) {
          console.log("In Error :", error);
          resolve([])
        });
    })
  }
  getTimeStamp = (fromDate, toDate) => {
    //  console.log("getTimeStamp ::",fromDate, toDate)
    fromDate.set({ hour: 0, minute: 1, second: 0, millisecond: 0 });
    toDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

    const startTimestamp = Math.floor(fromDate.valueOf() / 1000);
    const endTimestamp = Math.floor(toDate.valueOf() / 1000);
    // console.log("{startTimestamp, endTimestamp} :::", {startTimestamp, endTimestamp})
    return { startTimestamp, endTimestamp };
  }
  getUsers = () => {
    this.props.client.query({
      query: getInternalUsers,
      fetchPolicy: 'cache-first'
    })
      .then(({ data }) => {
        this.setState({
          InternalUserData: data.getInternalUsers,
        })
      })
  }
  checkDump = () => {
    return new Promise((resolve, reject) => {
      const currentDate = moment().format('YYYY-MM-DD');
      // console.log("currentDate::",currentDate)
      let username = undefined;
      // console.log("userData::",this.props.userData)
      if (this.props.userData.userRole != 'ADMIN') {
        username = this.props.userData.username
      }
      this.props.client.query({
        query: getInternalDump,
        variables: {
          typeValue: username ? 'onboarding_dashboard::' + username : 'onboarding_dashboard',
          dumpDate: currentDate,
          username: username
        },
        fetchPolicy: 'cache-first'
      }).then(({ data }) => {
        // console.log("getInternalDump::",data)
        resolve(data)
      })
    })
  }
  companyNameChange = (e) => {
    this.setState({
      companyName: e.target.value
    })
  }
  supportAccMChange = (value) => {
    this.setState({
      supportAccM: value
    })
  }
  salesPersonChange = (value) => {
    this.setState({
      salesPerson: value
    })
  }
  partnerTypeChange = (value) => {
    this.setState({
      partnerType: value,
      partnerName: undefined
    })
  }
  partnerNameChange = (value) => {
    this.setState({
      partnerName: value
    })
  }

  balanceAmtChange = (value) => {
    this.setState({
      balanceAmt: true
    })
  }

  notUsingChange = (value) => {
    // console.log("value::",value)
    this.setState({
      notUsing: value
    })
  }

  isWithinPastFourDays = (date) => {
    const today = moment();
    const targetDate = moment(date, 'DD-MM-YYYY');
    return targetDate.isBetween(today.clone().subtract(4, 'days').startOf('day'), today.startOf('day'), null, '[]');
};

  handleFilter = () => {
    let filterData = this.state.allSiteData
    if (this.state.supportAccM) {
      let aar = filterData.filter((x) => x.supportAccManager == this.state.supportAccM)
      filterData = aar
    }
    if (this.state.salesPerson) {
      let aar = filterData.filter((x) => x.salesPerson == this.state.salesPerson)
      filterData = aar
    }
    if (this.state.partnerType) {
      let aar = filterData.filter((x) => x.partnerType == this.state.partnerType)
      filterData = aar
    }
    if (this.state.partnerName) {
      let aar = filterData.filter((x) => x.channelPartner == this.state.partnerName)
      filterData = aar
    }
    if (this.state.companyName) {
      let aar = filterData.filter((x) => x.companyNameSlug.includes(this.state.companyName.toLowerCase()))
      filterData = aar
    }
    if (this.state.balanceAmt) {
      let aar = filterData.filter((x) => x.balance < 0)
      filterData = aar
    }

    if(this.state.notUsing){
      let aar = []
      const todayDate = moment().format('DD-MM-YYYY')
      for(let i=0; i<filterData.length; i++){
        let recentOrders = filterData[i].ordersCount.filter(order => order.date !== todayDate && this.isWithinPastFourDays(order.date));
        let sliceLength = recentOrders.length > 4 ? -4 : -recentOrders.length;
        recentOrders = recentOrders.slice(sliceLength);
          let isUsing = recentOrders && recentOrders.length > 0 && 
          recentOrders.some(order => order.ordersCount > 0);

          if(!isUsing){
            aar.push(filterData[i])
          }
      }
      filterData = aar
    }

    let filterDataWithTotalBalanceAmountRecord = this.getTotalBalanceAmount(filterData)
    // console.log("filterData ::",filterData)
    this.setState({
      siteData: filterDataWithTotalBalanceAmountRecord,
      loading: false
    })
  }
  resetFilter = () => {
    let filterDataWithTotalBalanceAmountRecord = this.getTotalBalanceAmount(this.state.allSiteData)
    this.setState({
      siteData: filterDataWithTotalBalanceAmountRecord,
      supportAccM: undefined,
      companyName: undefined,
      salesPerson: undefined,
      partnerType: undefined,
      partnerName: undefined,
      balanceAmt: undefined,
      notUsing: undefined
    })
  }
  openNotesModal = (record) => {
    let searchData = JSON.stringify({ siteId: record.siteId, })
    let param = {
      "searchValue": searchData,
      "skips": 0,
      "limits": 100
    }
    this.getNotes(param)
    this.setState({
      visible: true,
      siteId: record.siteId,
      siteDetails: record
    })
  }
  getNotes = async (param) => {
    this.setState({
      notesgetLoading: true
    })
    let notesList = await SiteActionEventQuery(this.props, param)
    let arr = notesList && notesList.getSiteActionEventsFilter ? notesList.getSiteActionEventsFilter : []
    arr.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1 })
    console.log("Notes::", arr)
    this.setState({
      notesList: arr,
      notesgetLoading: false
    })
  }
  closeNotesModal = () => {
    this.setState({
      visible: false
    })
  }
  textAreaClick = () => {
    this.setState({
      saveButton: true
    })
  }
  textAreaChnages = (e) => {
    this.setState({
      notes: e.target.value
    })
  }
  ented = () => {
    this.setState({
      saveButton: false
    })
  }
  onCategoryChange = (value) => {
    this.setState({
      selectedCategory: value
    })
  }
  handleSubmit = async () => {
    if (this.state.notes && this.state.notes != '' && this.state.selectedCategory) {
      this.setState({
        notesLoading: true
      })
      let obj = {
        siteId: this.state.siteId,
        eventName: this.state.selectedCategory,
        eventType: 'MANUAL',
        note: this.state.notes
      }
      let resp = await SiteActionEvent(this.props, obj)
      if (resp) {
        message.success("Notes added successfully")
        // console.log("resp :::",resp.createSiteActionEvent)
        let allNotes = this.state.notesList
        allNotes.push(resp.createSiteActionEvent)
        allNotes.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1 })
        this.setState({
          notesLoading: false,
          notesList: allNotes,
          selectedCategory: undefined,
          notes: undefined
        })
      } else {
        message.error("something went wrong")
        this.setState({
          notesLoading: false
        })
      }
    } else {
      if (this.state.selectedCategory) {
        message.error("Please Enter Notes")
      } else {
        message.error("Please Select Notes Category")
      }
    }
  }

  onCloseDashboard = () => {
    this.setState({ visiblePerformanceDashboard: false })
  }

  onOpenDashboard = () => {
    this.setState({ visiblePerformanceDashboard: true })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: 'Seq No.',
        dataIndex: 'seqNo',
        key: 'seq No',
        width: 70,
        fixed: 'left',
        render: (text, record, index) => (
           record.companyName && <span>{ record.seqNo}</span>  // Adding 1 to make it 1-based index
        )
      },
      {
        title: 'Business Name',
        dataIndex: 'companyName',
        key: 'companyName',
        width: 200,
        fixed: 'left',
        // render:(data)=>{
        //   return(
        //     <span>{this.truncateString(data,40)}</span>
        //   )
        // }
      },
      {
        title: 'Sales Person',
        dataIndex: 'salesPerson',
        key: 'salesPerson',
        width: 100,
        // render:(data)=>{
        //   return(
        //     <span>{this.truncateString(data,40)}</span>
        //   )
        // }
      },
      {
        title: 'Partner Name',
        dataIndex: 'channelPartner',
        key: 'channelPartner',
        width: 100,
      },
      {
        title: 'Support Act Manager',
        dataIndex: 'supportAccManager',
        key: 'supportAccManager',
        width: 100,
      },
      {
        title: 'Balance Amt',
        dataIndex: 'balance',
        key: 'balance',
        width: 100,
        render: (data) => {
          return (
            <span>{data ? data / 100 : 0}</span>
          )
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        sorter: (a, b) => {
          return a.createdAt - b.createdAt
        },
        render: (data) => {
          return (
            <div>
              {data != null &&
                moment.unix(data).format("DD-MM-YYYY")
              }
              {data == null && <span>--</span>}
            </div>
          )
        }
      },

    ]
    const columnsNotes = [
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 150,
        sorter: (a, b) => {
          return a.createdAt - b.createdAt
        },
        render: (data) => {
          return (
            <div>
              {data != null &&
                moment.unix(data).format("DD/MM/YYYY HH:mm")
              }
              {data == null && <span>--</span>}
            </div>
          )
        }
      },
      {
        title: 'Category',
        dataIndex: 'eventName',
        width: 100,
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        width: 100,
      },
      {
        title: 'User',
        dataIndex: 'createdBy',
        width: 200,
      },
      {
        title: 'Note',
        dataIndex: 'note'
      }
    ]
    this.state.betweenDates && this.state.betweenDates.map((ele, i) => {
      let obj = {
        title: i == 0 ? 'Todays Date' : ele,
        // dataIndex: 'createdAt',
        key: ele,
        width: 75,
        align: 'center',
        render: (data) => {

          if (data && data.ordersCount && data.ordersCount.length > 0) {
            let orderCount = null
            data.ordersCount.map((val) => {
              if (val.date == ele) {
                orderCount = val.ordersCount
              }
            })

            return {
              props: {
                style: { background: orderCount ? "#afdb8b" : "#f4bfc0" }
              },
              children: <span>{orderCount ? orderCount : '0'}</span>
            }
          } else {
            return {
              props: {
                style: { background: "#f4bfc0" }
              },
              children: <span>{'0'}</span>
            }
          }
        }
      }
      columns.push(obj)
    })
    let note = {
      title: 'Notes',
      key: 'notes',
      width: 80,
      fixed: 'right',
      align: 'center',
      render: (data) => {
        return (
          <Icon type='form' onClick={() => this.openNotesModal(data)} />
        )
      }
    }
    columns.push(note)
    return (
      <App header={'Dashboard'}>
        <Row gutter={24}>
          <Col span={18}>
          <Row gutter={24}>
          {this.props && this.props.userData && (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD' || this.props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD' || this.props.userData.userRole == 'SITE_CREATOR') &&
            <>
              <Col md={{ span: 6 }}>

                <Select
                  optionFilterProp="children"
                  style={{ width: '100%', marginRight: '10px' }}
                  onChange={this.supportAccMChange}
                  value={this.state.supportAccM}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  showSearch
                  placeholder="Support Account Manager">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == 'SUPPORT_ACCOUNT_MANAGER' || ele.userRole == 'CUSTOMER_SUPPORT_LEAD') {
                        return (
                          <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                        )
                      }
                    }) : ''}
                </Select>
              </Col>
              <Col md={{ span: 6 }}>
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%', marginRight: '10px' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.salesPersonChange}
                  value={this.state.salesPerson}
                  allowClear
                  showSearch
                  placeholder="Sales Person">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == 'SALES_PERSON') {
                        return (
                          <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                        )
                      }
                    }) : ''}
                </Select>
              </Col>
              <Col md={{ span: 6 }}>
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%', marginRight: '10px' }}
                  onChange={this.partnerTypeChange}
                  value={this.state.partnerType}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  showSearch
                  placeholder="Partner Type">
                  <Select.Option key={'cp'} value={'CP'} >CP</Select.Option>
                  <Select.Option key={'reseller'} value={'RESELLER'} >Reseller</Select.Option>
                  <Select.Option key={'referral'} value={'REFERRAL'} >Referral</Select.Option>
                  <Select.Option key={'stockist'} value={'STOCKIST'} >Stockist</Select.Option>
                </Select>
              </Col>
              <Col md={{ span: 6 }}>

                <Select
                  optionFilterProp="children"
                  style={{ width: '100%', marginRight: '10px' }}
                  onChange={this.partnerNameChange}
                  value={this.state.partnerName}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  showSearch
                  placeholder="Partner Name">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == this.state.partnerType) {
                        if (this.state.partnerType == 'CP') {
                          if (ele.companyName) {
                            return (
                              <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                            )
                          }
                        } else {
                          return (
                            <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                          )
                        }
                      }
                    }) : ''}
                </Select>
              </Col>
            </>
          }
          <Col md={{ span: 6 }}>
            <Input placeholder="Business Name" value={this.state.companyName} onChange={this.companyNameChange} style={{ width: '100%', marginRight: '10px' ,marginTop:"10px"}} />
          </Col>
          <Col md={{ span: 6 }}>
            <Select
              optionFilterProp="children"
              style={{ width: '100%', marginRight: '10px',marginTop:"10px" }}
              onChange={this.balanceAmtChange}
              value={this.state.balanceAmt}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
              showSearch
              placeholder="Balance Amt">
              <Select.Option key={0} value={true} >Negative Balance</Select.Option>
            </Select>
          </Col>
          <Col md={{ span: 6 }}>
            <Select
              optionFilterProp="children"
              style={{ width: '100%', marginRight: '10px', marginTop:"10px", marginBottom: "10px" }}
              onChange={this.notUsingChange}
              value={this.state.notUsing}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
              showSearch
              placeholder="Not Using">
              <Select.Option key={0} value={true} >Not Using</Select.Option>
            </Select>
          </Col>
          </Row>
          </Col>
          <Col md={{span: 4}}>
          <Row>
          <Col md={{ span: 24 }}>
            <Button type='danger' style={{ float: "right", marginBottom: 16, cursor: "pointer", marginLeft: '10px', width: 60 }} onClick={this.resetFilter}>Reset</Button>

            <Button type='primary' style={{ float: "right", marginBottom: 16, cursor: "pointer", width: 70 }} onClick={this.handleFilter}>Search</Button>
          </Col>
          </Row>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Button onClick={this.onOpenDashboard} type='primary' disabled={this.state.performanceDashboardData.length == 0}> Performance Dashboard </Button>
        </Row>

        <Table
          className='sitetable'
          columns={columns}
          bordered
          dataSource={this.state.siteData}
          loading={this.state.loading}
          size="middle"
          rowKey="id"
          scroll={{ x: '110%' }}
          pagination={{ pageSize: 100 }}
        />
        <Modal
          // title={"Notes"}
          title={`Notes: ${this.state.siteDetails ? this.state.siteDetails.fqdn : ''}`}
          visible={this.state.visible}
          onCancel={this.closeNotesModal}
          footer={null}
          width={1200}
        >
          <Form>
            <Row gutter={16}>

              <div className='planCardSection' >
                <p class='planCardSectionText' style={{ fontWeight: 700 }}>History</p>
                <Table
                  className='sitetable'
                  columns={columnsNotes}
                  bordered
                  dataSource={this.state.notesList}
                  loading={this.state.notesgetLoading}
                  size="middle"
                  pagination={{ pageSize: 5 }}
                />
                <p class='planCardSectionText' style={{ fontWeight: 700 }}>Add New Notes</p>
                <Select
                  placeholder={"Select Category"}
                  onChange={this.onCategoryChange}
                  value={this.state.selectedCategory}
                >
                  <Select.Option key="Billing" value="Billing">Billing</Select.Option>
                  <Select.Option key="Complaint" value="Complaint">Complaint</Select.Option>
                  <Select.Option key="Customer Requirement" value="Customer Requirement">Customer Requirement</Select.Option>
                  <Select.Option key="Onboarding" value="Onboarding">Onboarding</Select.Option>
                  <Select.Option key="Setup" value="Setup">Setup</Select.Option>
                  <Select.Option key="Support" value="Support">Support</Select.Option>
                  <Select.Option key="Renewal Notes" value="Renewal Notes">Renewal Notes</Select.Option>

                </Select>
                <FormItem label='' style={{ marginTop: '5px' }}>
                  {getFieldDecorator('note', {
                    rules: [{
                      required: false,
                      message: "Please enter note",
                    }],
                  })(
                    <div style={{ border: this.state.saveButton ? '1px solid' : 'none', borderColor: this.state.saveButton ? '#0170fe' : 'none', boxShadow: this.state.saveButton && '0 0 4px #1c9dfb80' }}>
                      <TextArea value={this.state.notes} placeholder='Add a note' onFocus={this.textAreaClick} onChange={this.textAreaChnages} rows={4} style={{ border: this.state.saveButton && 'none' }} />
                      {this.state.saveButton &&
                        <div style={{ textAlign: 'right' }}>
                          <Button type="primary" onClick={this.handleSubmit} loading={this.state.notesLoading} style={{ marginRight: '5px', height: '25px' }} >SAVE</Button>
                        </div>
                      }
                    </div>
                  )}
                </FormItem>
              </div>
            </Row>
          </Form>
        </Modal>

        <Drawer
          title="Performance Dashboard"
          placement="right"
          closable={true}
          onClose={this.onCloseDashboard}
          visible={this.state.visiblePerformanceDashboard}
          width={'80%'}
        >
          <PerformanceDashboard {...this.props} siteData={this.state.performanceDashboardData} InternalUserData={this.state.InternalUserData} />
        </Drawer>

      </App>
    );
  }
}
const WrapIndexList = Form.create()(IndexList);
export default withApollo(WrapIndexList);