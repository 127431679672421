import React, { Component } from 'react';
import { Button, Checkbox, Row, Col, Form, Input, Select, message, Radio ,Divider} from 'antd'
import { withApollo } from 'react-apollo';
import { externalPermissions, mainPermissions } from '../../utils/permissionsObj'
import updateInternalUser from '../../mutation/updateInternalUser'
import { getStateByCountry, getcitiesByStateAndCountry } from "../../utils/getStateByCountry"
import getInternalUsers from '../../queries/getInternalUsers';
import EditCpEmployee from './editEmployeCp';
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const country = [
    { value: 'IND', label: 'India',key:'IN' },
    { value: 'AUS', label: 'Australia',key:'AU' },
    { value: 'USA', label: 'USA',key: 'US' },
    { value: 'UK', label: 'UK',key:'GB' },
    { value: 'UAE', label: 'UAE',key:'AE' },
    { value: 'ZMW', label: 'Zambia',key:'ZM' },
    { value: 'SAU', label: 'Saudi Arabia',key:'SA' },
    { value: 'QAT', label: 'Qatar',key:'QA' },
    { value: 'BHR', label: 'Bahrain',key:'BH' },
    { value: 'SGP', label: 'Singapore',key:'SG' },
    { value: 'NPL', label: 'Nepal',key:'NP' },
    { value: 'KWT', label: 'Kuwait',key:'KW' },
    { value: 'OMN', label: 'Oman',key:'OM' },
    { value: 'KEN', label: 'Kenya',key:'KE' },
    { value: 'MDV', label: 'Maldives',key:'MV' },
    { value: 'IDN', label: 'Indonesia',key:'ID' },
    { value: 'THA', label: 'Thailand',key:'TH' },
    { value: 'MYR', label: 'Malaysia',key:'MY' },
    { value: 'PAK', label: 'Pakistan',key:'PK' },
  ]
class EditUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedParmision: this.props && this.props.userData && this.props.userData.permissions ? this.props.userData.permissions : undefined,
            loading: false,
            selectedCountry: undefined,
            selectedState: undefined,
            selectedCity: undefined,
            allState: [],
            allCities: [],
            allUsers:null,
            cpEmployeeData: null
        }
    }


    componentDidMount = () =>{
        this.getUsers()
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            //  console.log("values",values,this.state.selectedParmision)
            //   return

            const newData = this.state.cpEmployeeData && this.state.cpEmployeeData.length > 0 && this.state.cpEmployeeData.map(({ key, ...rest }) => rest);

            // console.log("setCpEmployeeDta::",newData)

            // return
            if (!err) {
                this.setState({ loading: true })
                let finalPermissions = []
                if (values && values.permissions && values.permissions.length > 0) {
                    let entities = [], actions = []
                    values.permissions.map((permission, index) => {
                        if (values.permissions[index].includes("::")) {
                            actions.push(permission)
                        } else {
                            entities.push(permission)
                        }
                    })
                    const getopration = (actions, permission) => {
                        let op = []
                        if (actions && actions.length > 0) {
                            actions.map((action) => {
                                if (action.startsWith(`${permission}::`)) {
                                    op.push(action.split(`${permission}::`)[1])
                                }
                            })
                            return op
                        }
                    }
                    finalPermissions = entities.map((permission, index) => {
                        if (!(permission.startsWith(`${permission}::`))) {
                            return (
                                {
                                    name: entities[index],
                                    operations: actions && getopration(actions, permission)
                                }
                            )
                        }
                    })
                   
                }
                this.setState({ loading: false })
                // console.log("finalPermissions:::",finalPermissions)
                //  return
                this.props.client.mutate({
                    mutation: updateInternalUser,
                    variables: {
                        siteId: this.props.userData.siteId,
                        username: this.props.userData.username,
                        role: this.props.userData.role,
                        emailId: this.props.userData.emailId,
                        mobile: values.mobile,
                        name: values.name,
                        companyName: values.companyName,
                        gstNo: values.gstNo,
                        panNo: values.panNo,
                        onboardedBy: values.onboardedBy,
                        supportNumber: values.supportNumber,
                        enabled: values.enabled,
                        permissions: finalPermissions,
                        address: {
                            addressLineOne: values.addressLineOne ? values.addressLineOne : undefined,
                            addressLineTwo: values.addressLineTwo ? values.addressLineTwo : undefined,
                            city: values.city ? values.city : undefined,
                            state: values.state ? values.state : undefined,
                            zip: values.zip ? values.zip : undefined,
                            country: values.country ? values.country : undefined
                        },
                         empsDetails: newData && newData.length > 0 ? newData : undefined
                    }
                }).then(({ data }) => {
                    console.log("Resp SiteConfig ::", data)
                    message.success('User Updated Successfully')
                    // this.props.history.push('/manage-user')
                    if (this.props.cancel) {
                        this.props.cancel()
                    }
                })
            } else {
                this.setState({ loading: false })
            }
        })
    }
    onCheckChange = (value) => {
        this.setState({
            selectedParmision: value
        })
    }
    onCountryChange = async (value) => {
        let index = country.findIndex((x) => x.value == value)
        let response = await getStateByCountry(country[index].key)
        console.log("response ::", response)
        if (response) {
            this.setState({
                selectedCountry: value,
                allState: response
            })
        }
    }
    onStateChange = async (value) => {
        let cIndex = country.findIndex((x) => x.value == this.state.selectedCountry)
        let sIndex = this.state.allState.findIndex((x) => x.name === value)
        let cities = await getcitiesByStateAndCountry(country[cIndex].key, this.state.allState[sIndex].iso2)
        this.setState({
            selectedState: value,
            allCities: cities
        })

        // this.props.form.setFieldsValue({ state: value });
    }
    onCityChange = (value) => {
        this.setState({
            selectedCity: value
        })

        // this.props.form.setFieldsValue({ city: value }); // Update the form field value
       
    }

    changeRole = (value) => {
        // console.log("value::",value)
        // this.props.form.setFieldsValue({ role: value }); 
    }

    setCpEmployeeDta =(data)=>{
        this.setState({cpEmployeeData:data})
       
    }

    getUsers=()=>{
        this.props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
                this.setState({
                    allUsers: data.getInternalUsers
                })
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let userData = this.props.userData
       
        return (
            <Form onSubmit={this.handleSubmit} >
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Name' >
                            {getFieldDecorator('name', {
                                initialValue: userData.name,
                                rules: [{
                                    required: false,
                                    message: "Please enter Name.",
                                }],
                            })(

                                <Input placeholder="Name" style={{ width: '100%' }} />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Contact Number' >
                            {getFieldDecorator('mobile', {
                                initialValue: userData.mobile,
                                rules: [{
                                    required: false,
                                    message: "Please enter Contact Number.",
                                }],
                            })(

                                <Input placeholder="Contact Number" type='number' style={{ width: '100%' }} />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Email'
                            hasFeedback
                            help={this.state.showError && this.state.userNameCheck ? <span style={{ color: 'red' }}>This username is not available. Please try another one</span> : ""}
                        >
                            {getFieldDecorator('emailId', {
                                initialValue: userData.emailId,
                                rules: [{
                                    required: false,
                                    message: "Please enter Email.",
                                }, {

                                    validator: (rule, value, cb) => {
                                        if (value) {
                                            if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/)) {
                                                cb('Please enter valid email address')
                                            }
                                        }
                                        cb()
                                    }
                                }],
                            })(

                                <Input disabled placeholder="Email" style={{ width: '100%' }} />
                            )}
                        </FormItem>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Role' >
                            {getFieldDecorator('role', {
                                initialValue: userData.userRole,
                                rules: [{
                                    required: true,
                                    message: "Please select Role",
                                }],
                            })(
                                <Select disabled value={userData.role} style={{ width: '100%', color: "black" }} onChange={this.changeRole} placeholder="Select User Role">
                                    {/* <Option key="ADMIN" value="ADMIN">Admin</Option> */}
                                    <Option key="CUSTOMER_SUPPORT_LEAD" value="CUSTOMER_SUPPORT_LEAD">Customer Support Lead</Option>
                                    <Option key="SITE_CREATOR" value="SITE_CREATOR">Site Creator</Option>
                                    <Option key="ACCOUNTS" value="ACCOUNTS">Accounts</Option>
                                    <Option key="SUPPORT_EXECUTIVE" value="SUPPORT_EXECUTIVE">Support Executive</Option>
                                    <Option key="CP" value="CP">CP</Option>
                                    <Option key="RESELLER" value="RESELLER">Reseller</Option>
                                    <Option key="REFERRAL" value="REFERRAL">Referral</Option>
                                    <Option key="STOCKIST" value="STOCKIST">Stockist</Option>
                                    <Option key="SALES_PERSON" value="SALES_PERSON">Sales Person</Option>
                                    <Option key="SUPPORT_ACCOUNT_MANAGER" value="SUPPORT_ACCOUNT_MANAGER">Support Account Manager</Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Status' >
                            {getFieldDecorator('enabled', {
                                initialValue: userData.enabled,
                                rules: [{
                                    required: true,
                                    message: "Please select Role",
                                }],
                            })(
                                <RadioGroup >
                                    <Radio value={true} >Active</Radio>
                                    <Radio value={false}>Inactive</Radio>

                                </RadioGroup>
                            )}
                        </FormItem>
                    </Col>


                    {(userData.userRole && userData.userRole == 'CP' || userData.userRole == 'RESELLER' || userData.userRole == 'REFERRAL' || userData.userRole == 'STOCKIST') &&
                        <>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Company Name' className="booking-label">
                                {getFieldDecorator('companyName', {
                                    initialValue: userData.companyName ? userData.companyName : undefined,
                                    rules: [{
                                        required: false,
                                        message: "Please enter company Name",
                                    }],
                                })(
                                    <Input placeholder="Company Name" />
                                )}
                            </FormItem>
                        </Col>

                        {userData.userRole && userData.userRole == 'CP' &&
                            <>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Onboarded By' >
                                        {getFieldDecorator('onboardedBy', {
                                            initialValue: userData.onboardedBy,
                                            validateTrigger: ['onChange', 'onBlur'],
                                            rules: [{
                                                required: true,
                                                message: "Please enter sales person name.",
                                            }],
                                        })(
                                            <Select
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                placeholder="Please select Person Name">
                                                {this.state.allUsers && this.state.allUsers.length > 0 &&
                                                    this.state.allUsers.map((ele) => {
                                                        if (ele.userRole == 'SALES_PERSON') {
                                                            return (
                                                                <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                            )
                                                        }
                                                    })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>

                            </>

                        }

                           
                            {userData.userRole && userData.userRole == 'CP' && <>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Customer Support Number' className="booking-label">
                                    {getFieldDecorator('supportNumber', {
                                        initialValue: userData.supportNumber ? userData.supportNumber : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Customer Support Number",
                                        }],
                                    })(
                                        <Input placeholder="Customer Support Number" />
                                    )}
                                </FormItem>
                            </Col>   

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='GST Number' className="booking-label">
                                        {getFieldDecorator('gstNo', {
                                            initialValue: userData.gstNo ? userData.gstNo : undefined,
                                            rules: [{
                                                required: false,
                                                message: "Please enter GST Number",
                                            }],
                                        })(
                                            <Input placeholder="GST Number" />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='PAN Number' className="booking-label">
                                        {getFieldDecorator('panNo', {
                                            initialValue: userData.panNo ? userData.panNo : undefined,
                                            rules: [{
                                                required: false,
                                                message: "Please Enter Pan Number",
                                            }],
                                        })(
                                            <Input placeholder="PAN Number" />
                                        )}
                                    </FormItem>
                                </Col> </>}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Country' className="booking-label">
                                    {getFieldDecorator('country', {
                                        initialValue: userData.address && userData.address.country ? userData.address.country : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(

                                        <Select onChange={this.onCountryChange}>
                                            {country.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value} >{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='State' className="booking-label" >
                                    {getFieldDecorator('state', {
                                        initialValue: userData.address && userData.address.state ? userData.address.state : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter state",
                                        }],
                                    })(

                                        <Select
                                            optionFilterProp="children"
                                            style={{ width: '100%', marginRight: '10px' }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="State"
                                             value={this.state.selectedState}
                                            showSearch
                                            allowClear
                                            onChange={this.onStateChange}>
                                            {this.state.allState && this.state.allState.length > 0 && this.state.allState.map((c) => {
                                                return (
                                                    <Option key={c.iso2} value={c.name} >{c.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='City' className="booking-label">
                                    {getFieldDecorator('city', {
                                        initialValue: userData.address && userData.address.city ? userData.address.city : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter city.",
                                        }],
                                    })(

                                        <Select
                                            optionFilterProp="children"
                                            style={{ width: '100%', marginRight: '10px' }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="City"
                                            value={this.state.selectedCity}
                                            showSearch
                                            onChange={this.onCityChange}>
                                            {this.state.allCities && this.state.allCities.length > 0 && this.state.allCities.map((c) => {
                                                return (
                                                    <Option key={c.id} value={c.name} >{c.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Zip' className="booking-label">
                                    {getFieldDecorator('zip', {
                                        initialValue: userData.address && userData.address.zip ? userData.address.zip : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter zip.",
                                        }],
                                    })(
                                        <Input placeholder="Zip" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Address Line 1' className="booking-label">
                                    {getFieldDecorator('addressLineOne', {
                                        initialValue: userData.address && userData.address.addressLineOne ? userData.address.addressLineOne : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 1",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 1" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Address Line 2' className="booking-label">
                                    {getFieldDecorator('addressLineTwo', {
                                        initialValue: userData.address && userData.address.addressLineTwo ? userData.address.addressLineTwo : undefined,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 2",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 2" />
                                    )}
                                </FormItem>
                            </Col>


                        </>
                    }

                   
                </Row>

                <Row>
                    {userData.userRole && userData.userRole != 'CP' && userData.userRole != 'RESELLER' && userData.userRole != 'REFERRAL' && userData.userRole != 'STOCKIST' &&
                        <FormItem label='User Permissions' >
                            {getFieldDecorator('permissions', {
                                initialValue: userData.permissions,
                                //  valuePropName: "defaultValue",
                                rules: [{
                                    required: false,
                                    message: "Please select User Permissions",
                                }],
                            })(
                                <Checkbox.Group onChange={this.onCheckChange} >
                                    {mainPermissions.map((menu, i) => {
                                        return (
                                            <>
                                                <Row style={{ padding: "6px 0" }}>
                                                    <Checkbox value={menu.value} key={i}>{menu.label}</Checkbox>
                                                </Row>
                                                {this.state.selectedParmision && this.state.selectedParmision.length > 0 && this.state.selectedParmision.includes(menu.value) &&
                                                    <Row>
                                                        {externalPermissions[menu.value].map((action) => {
                                                            return (
                                                                <span style={{ padding: "10px 20px" }}>
                                                                    <Checkbox value={`${menu.value}::${action.value}`}>{action.label}</Checkbox>
                                                                </span>
                                                            )
                                                        })
                                                        }
                                                    </Row>
                                                }
                                            </>
                                        )
                                    })}
                                </Checkbox.Group>
                            )}
                        </FormItem>
                    }
                  <Divider/>
                  {userData.userRole && userData.userRole == 'CP' && <EditCpEmployee userData={userData} setCpEmployeeDta={this.setCpEmployeeDta}/> }
                </Row>

                <Row>
                    <Button type="default" style={{ background: "#f44336", color: "#fff", marginLeft: "20px", float: "right", }} onClick={this.props.cancel}>CANCEL</Button>
                    <Button loading={this.state.loading} disabled={this.state.loading} id="submit" type="primary" htmlType="submit" style={{ float: "right", cursor: "pointer", color: "#fff", marginLeft: '10px' }}>SUBMIT</Button>
                </Row>
            </Form>
        );
    }
}
const WrapEditUser = Form.create()(EditUser);
export default withApollo(WrapEditUser);