import gql from 'graphql-tag'

export default gql`
query{
    getEmailCampaignTemplates{
      id
      siteId
      domain
      typeValue
      tempName
      tempSubject
      text
      createdAt
    }
}
`