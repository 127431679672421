function getOperationObject(obj){
    let responseObject = {}, temp = {};
    if(obj.operations && obj.operations.length > 0){
        for(let i = 0; i < obj.operations.length; i++){
            temp = obj.operations[i]
            responseObject[temp] = temp
        }
    }
    return responseObject;
}


function UserOperation(name, operation, userPermissions){
    // console.log("name, operation, userPermissions, userRole :::", {
    //     name, operation, userPermissions, userRole
    // })
    let i, obj = {}, operationObject = {}
    if(userPermissions.userRole === "ADMIN"){
        return true
    }
    if (userPermissions) {
        for (i = 0; i < userPermissions.permissions.length; i++) {
            obj = userPermissions.permissions[i]
            if (obj.name == name) {
                let op = getOperationObject(obj);
                if(op[operation]){
                    return true
                }
            }
        }
    }

    // if(operationObject[operation]){
    //     return true
    // } else {
    //     return false
    // }
}


export default UserOperation