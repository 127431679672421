import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
const FormItem = Form.Item;
class AddEmployeeModal extends React.Component {


    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFields((err, values) => {
           
            // return
            if (!err) {
                this.props.onCreate(values);
               
            }
        })
    }

    render() {
        const { visible, onCancel } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                visible={visible}
                title="Add Employee"
                okText="Add"
                cancelText="Cancel"
                onCancel={onCancel}
                footer={null} // Hide the footer because we handle form submission manually
            >
                <Form onSubmit={this.handleSubmit} >
                    <FormItem label='Name' >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: "Please enter Name.",
                            }],
                        })(

                            <Input placeholder="Name" style={{ width: '100%' }} />
                        )}
                    </FormItem>
                    <FormItem label='Contact Number' >
                        {getFieldDecorator('mobile', {
                            rules: [{
                                required: true,
                                message: "Please enter mobile no.",
                            }],
                        })(

                            <Input placeholder="mobile" style={{ width: '100%' }} />
                        )}
                    </FormItem>
                    <FormItem label='Email' >
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: "Please enter email.",
                            }],
                        })(

                            <Input placeholder="email" style={{ width: '100%' }} />
                        )}
                    </FormItem>
                   
                    <FormItem label='designation' >
                        {getFieldDecorator('designated', {
                            rules: [{
                                required: true,
                                message: "Please enter designation.",
                            }],
                        })(

                            <Input placeholder="designation" style={{ width: '100%' }} />
                        )}
                    </FormItem>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">Add</Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
const WrapCreateUser = Form.create()(AddEmployeeModal);
export default WrapCreateUser;
