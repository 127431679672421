import gql from 'graphql-tag'
export default gql`
mutation updateSubscriptionPlan(
  $siteId: String!
  $planId: String!
  $planName: String
  $status: SERVICE_STATUS
  $masterService: String
  $platform: SITE_OPTIONS
  $siteType: SITE_TYPE
  $services: [String!]!
  $description: String
   $curr: CURRENCY_CODE
   $amount: Float
   $chargingModel: CHARGING_MODEL
  $priceList: [PlanPriceListInput!]
){
updateSubscriptionPlan(input:{
  siteId: $siteId
  planId: $planId
  planName: $planName
  status: $status
  masterService: $masterService
  platform: $platform
  siteType: $siteType
  description: $description
  services: $services
  curr: $curr
  amount: $amount
  priceList: $priceList
  chargingModel: $chargingModel
  
}){
  siteId
  planId
  planName
  status
  masterService
  platform
  siteType
  amount
  chargingModel
  description
  services
  createdAt
  paymentGateway
  subscriptionId
  curr
  priceList{
    chargingModel
    rate
  }
  
}
}
`