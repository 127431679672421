import React, { useState } from 'react';
import { Select, Input, Button, Form, Table, Popconfirm,Spin,message } from 'antd';
import 'antd/dist/antd.css';
import { withApollo } from "react-apollo";
import getSiteCustomLabelByDomain from '../../queries/getSiteCustomLabelByDomain';
import updateCustomLabels from '../../mutation/updateCustomLabels';
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const { Option } = Select;
const { TextArea } = Input;
class YourComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOption: {Station: null,
      Chef: null,
      Area: null,
      Waiter: null,
      Captain: null,
      Table: null,
      KOT: null,
      Manage_Dine_In: null,
      Counter: null,
      },
      selectedOption:null,
      inputText: '',
      editedText: null,
      data: [],
      TableData:[],
      editingKey: null,
      edit:false,
      isLoading:false,
      dataLoading:false,
      notes: undefined,
    };
  }

  componentDidMount=()=>{
        this.getLablesData() 
  }

  removeFromDefaultList=(customLabels)=>{
          //  console.log("Initial List :::",this.state.defaultOption)
         let list = JSON.parse(JSON.stringify(this.state.defaultOption))

         customLabels.forEach(item => {
          const keyToRemove = item.defaultName;
          if (list.hasOwnProperty(keyToRemove)) {
            delete list[keyToRemove];
          }
        });

        console.log("list:::",list)

        this.setState({defaultOption:list})
  }

  addRecordToDefaultList = (newData) => {
    // console.log("newData:::",newData)
    const keyToAdd = newData.defaultName;
    // Check if the key is not already present before adding
    let list = JSON.parse(JSON.stringify(this.state.defaultOption))
    if (!list.hasOwnProperty(keyToAdd)) {
      list[keyToAdd] = null;
    }
    // console.log("added list:::", list)
    this.setState({ defaultOption: list })
  } 

  getLablesData=()=>{
    this.props.client.query({
      query: getSiteCustomLabelByDomain,
      variables:{
        domain: this.props.siteData.fqdn
      },
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        //  console.log('dataa', data)
            
         if(data.getSiteCustomLabelByDomain){
          this.setState({dataLoading:true})
              let customLabels = data.getSiteCustomLabelByDomain.customLabels
              //  console.log("customLabels:::",customLabels)

               if(customLabels && customLabels.length > 0){
                    this.removeFromDefaultList(customLabels)
               }
              this.createData(customLabels)
          
         }
          
      })
      .catch(err => {
        console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
      })
  }

  notesChange=(e)=>{
    this.setState({
      notes: e.target.value
    })
  }
  createData= (customLabels) =>{
    customLabels.map((item)=>{
      delete item.__typename;
   })

  //  console.log("customLabels",customLabels)

   let transformedArray = customLabels.map((obj, index) => {
     // const key = Object.keys(obj)[0];
     // const value = obj[key];
   
     return {
       key: index, // Add 'index' key
       defaultName: obj.defaultName,
       displayName: obj.displayName
     };
   });

  //  console.log("transformedArray::",transformedArray)

   this.setState({ TableData: transformedArray, editingKey: null,edit:false,inputText:null,dataLoading:false });
  }

  isEditing = (record) => record.key === this.state.editingKey;

  isEditingValues = (index) => index == this.state.editingKey;    

  columns = [
    {
      title: 'Lables',
      dataIndex: 'defaultName',
      key: 'Lables',
    },
    {
      title: 'Values',
      dataIndex: 'displayName',
      key: 'Values',
      render:(_, data ,index)=>{ 
      const selectedRecord = this.isEditingValues(index);
        return(
          
               this.state.edit ? selectedRecord ?
               <Input defaultValue={data.displayName} onChange={this.recordEdit}/> : data.displayName : data.displayName
        )
    }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record ,index) => { 
        const editable = this.isEditing(record);
        return (
          <span>
            {editable ? (
              <span>
                <a style={{marginRight:"10px"}} onClick={() => this.save(record.key)}>Save</a>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <span>
                <a onClick={() => this.edit(record)}>Edit</a>
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a style={{ color: 'red', marginLeft: 8 }}>Delete</a>
                </Popconfirm>
              </span>
            )}
          </span>
        );
      },
    },
  ];

  handleSelectChange = (value) => {
    this.setState({ selectedOption: value });
  };

  handleInputChange = (e) => {
    this.setState({ inputText: e.target.value });
  };

  recordEdit = (e) =>{
    // console.log("e:::",e.target.value)

       this.setState({editedText:e.target.value})
  }

  handleAddButtonClick = () =>{

        // console.log("This.state.selectedOption::", this.state.selectedOption,this.state.inputText)
       let {selectedOption,inputText} = this.state

       inputText = inputText && inputText.trim()

       if(selectedOption != null && inputText != null && inputText != '' && inputText != ' '){

       let arr = [... this.state.TableData]
       let obj = { key: this.state.TableData.length, // Add 'index' key
       defaultName: selectedOption,
       displayName: inputText}
    //    obj[selectedOption] = inputText
      //  console.log("Obj:::",obj)

       arr.push(obj)

       let transformedArray = arr.map((obj, index) => {
            // const key = Object.keys(obj)[0];
            // const value = obj[key];
          
            return {
              key: index, // Add 'index' key
              defaultName: obj.defaultName,
              displayName: obj.displayName
            };
          });
      
      //  console.log("transformedArray", transformedArray);

       this.removeFromDefaultList(transformedArray)

      //  console.log("arr:::",arr)

       this.setState({TableData: transformedArray,inputText:null,selectedOption:null})

        }else{
          message.warning("please fill required details")
        }
  }

//   handleAddButtonClick = () => {
//     const { selectedOption, inputText } = this.state;
//     if (selectedOption && inputText) {
//       const newData = [
//         ...this.state.data,
//         {
//           key: Date.now(),
//           lables: selectedOption,
//           values: inputText,
//         },
//       ];
//       this.setState({ data: newData, selectedOption: null, inputText: '' });
//     }
//   };

  edit = (record) => {  
    this.setState({ editingKey: record.key, edit:true });
  };

  cancel = () => {
    this.setState({ editingKey: null, edit:false, editedText:null });
  };

  save = (key) => {  

    const newData = [...this.state.TableData];
    // console.log("newDataSave::",newData)
    const index = newData.findIndex((item) => key === item.key);
    // console.log("Index:::",index)
    if (index > -1) {
        let obj = newData[index]
        obj.displayName = this.state.editedText != null ? this.state.editedText : obj.displayName

        newData[index] = obj
        
        this.submitEditedRecord(newData)
    }
  };

  submitEditedRecord = (newData) =>{
    // console.log("submitEditedRecord::",newData)

    let customLabelsArr = newData
    customLabelsArr.forEach(item => {
    delete item.key;
});

  let variable = {
    domain: this.props.siteData.fqdn,
    typeValue: "customLabelsConfig",
    customLabels: customLabelsArr
  }
   
  // console.log("Variable:::",variable)
    //  return
  this.props.client.mutate({
    mutation: updateCustomLabels,
    variables: variable
}).then(({ data }) => {
    // console.log("data response",data)

    let customLabels = data.updateCustomLabels.customLabels

    this.createData(customLabels)
  
  })
  .catch(res => {
    console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
  });
  }

  handleDelete = (key) => { 
    let found = this.state.TableData && this.state.TableData.length > 0 && this.state.TableData.find((item) => item.key == key)
    // console.log("FOund:::",found)
    const newData = this.state.TableData.filter((item) => item.key !== key);
    // console.log("handleDelete:::")
      this.submitEditedRecord(newData)

     this.addRecordToDefaultList(found) 
    // this.setState({ TableData: newData });
  };

  submitRecord = () =>{
         this.setState({isLoading:true})
    // console.log("this.state.TableData",this.state.TableData)
      let customLabelsArr = this.state.TableData
      customLabelsArr.forEach(item => {
      delete item.key;
  });

    let variable = {
      domain: this.props.siteData.fqdn,
      typeValue: "customLabelsConfig",
      customLabels: customLabelsArr
    }
     
    // console.log("Variable:::",variable)
    // return
    if(this.state.notes){
    this.props.client.mutate({
      mutation: updateCustomLabels,
      variables: variable
  }).then(({ data }) => {
      // console.log("data response",data)
           this.setState({isLoading:false})
      let customLabels = data.updateCustomLabels.customLabels

      message.success("Saved Successfully");

      this.createData(customLabels)
      this.createLogs("Site Labels")
    
    })
    .catch(res => {
      console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
    });
  }else{
    if(this.state.notes == undefined){
      message.error("Please Enter Notes")
      this.setState({isLoading:false})
    }
  }
  }


  createLogs=async(name)=>{
    let obj = {
        siteId: this.props.siteData.siteId,
        eventName: 'Setup',
        eventType: 'MANUAL',
        // reason: this.state.selectedReason,
        note: this.state.notes
    }
    let resp = await SiteActionEvent(this.props,obj)
    if(resp){
      this.setState({
        notes: undefined
      })
    }
  }

  render() {  
    //  console.log("This.state.selectedOption",this.state.selectedOption,this.state.TableData)
    // console.log("this.props:::",this.props.siteData)
  const  options = Object.keys(this.state.defaultOption);
    return (
      <div style={{ padding: '20px' }}>
        <div style={{marginBottom:"10px"}}>
        <Form layout="inline">
          <Form.Item label="Select Option" >

          <Select value={this.state.selectedOption}  style={{ width: 200 }} onChange={this.handleSelectChange}>
        {options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
            
          </Form.Item>
          <Form.Item label="Input Text">
            <Input value={this.state.inputText} onChange={this.handleInputChange} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleAddButtonClick}>
              Add
            </Button>
          </Form.Item>
        </Form>
        </div>
       

        <div>

        { this.state.dataLoading ? <Spin  /> : this.state.TableData.length > 0 &&  <Table
          dataSource={this.state.TableData}
          columns={this.columns}
          bordered
          pagination={true}
        /> }
        </div>
          
        { this.state.TableData.length > 0 && <div style={{marginTop:"10px",marginBottom:"20px"}}>
        <Popconfirm     onConfirm={this.submitRecord}  title={        <TextArea    rows={2}  value={this.state.notes}    placeholder="Notes" onChange={this.notesChange}  />} okText="Save" >
          <Button loading={this.state.isLoading} type="default"
            style={{ float: 'right', backgroundColor: 'green', borderColor: 'green',color:"white" }} >
            Save
          </Button>
        </Popconfirm>
          </div>
        }
      </div>
    );
  }
}

export default withApollo(YourComponent);
