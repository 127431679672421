import gql from 'graphql-tag';

export default gql`
query getSiteRewardSettingByDomain($domain: String!){
    getSiteRewardSettingByDomain(domain: $domain){
      domain
      typeValue
      rewardType
      isEnabled
      updatedAt
      onGroup
      groupId
    }
  }
`