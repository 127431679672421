import gql from "graphql-tag";

export default gql`
  query getCustomWhatsappConfig($domain: String!) {
    getCustomWhatsappConfig(domain: $domain) {
      domain
      typeValue
      productId
      phoneId
      token
      updatedAt
      apiKey
      system
    }
  }
`;
