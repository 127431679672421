import React from 'react'
import App from '../../App'
import CustTab from '../EmailCampaign/index'
import GetSignature from '../../queries/getSignature'
import { Query } from 'react-apollo';
import { Spin } from 'antd'

class EmailComp extends React.Component{
    render(){
        return(
                <App header={'Email Campaign'}>
                    <Query query={GetSignature} fetchPolicy="network-only" >
                        {(
                        { loading, error, data, refetch }) => {
                            if (loading) return(
                                <div style={{ textAlign: "center" }}>
                            <Spin size="large"></Spin>
                            </div>
                        );
                        if (error) return `Error!: ${error}`;
                        if(!loading){
                            let fdata = JSON.parse(data.getFroalaSignature)

                            return(
                                <CustTab modalData={fdata} />
                            )
                            }
                        }}
                    </Query>
                </App>
        )
    }
}
export default EmailComp