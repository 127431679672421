import gql from 'graphql-tag'

export default gql`
query getCampaignHistoryByCreatedAtByAdmin($siteId: String!){
    getCampaignHistoryByCreatedAtByAdmin(siteId: $siteId){
      items{
         id
      siteId
      campaignId
      language
      ProductType
      sendingStatus
      template
      type
      queType
      total
      sent
      failed
      createdAt
      }
      nextToken
    }
  }`