import gql from 'graphql-tag'
export default gql`
mutation subscribeSitePlanByAdmin(
    $siteId: String!
    $planName: String!
    $status: SERVICE_STATUS
    $masterService: String!
    $platform: SITE_OPTIONS
  	$siteType: SITE_TYPE
    $amount: Float!
  	$chargingModel: CHARGING_MODEL
    $services: [String!]!
    $description: String
    $paymentGateway: PAYMENT_GATEWAY
		$curr: CURRENCY_CODE
){
  subscribeSitePlanByAdmin(input:{
    siteId: $siteId
    planName: $planName
    status: $status
    masterService: $masterService
    platform: $platform
    siteType: $siteType
    amount: $amount
    chargingModel: $chargingModel
    description: $description
    services: $services
    paymentGateway: $paymentGateway
    curr: $curr
  })
}`
//  {
//        "siteId": "64ad47af-a5d9-4564-aae1-2a849a50fce5",
//        "planName": "Online Dine in",
//        "status": "ACTIVE",
//        "masterService": "SITE",
//        "platform": "BOOKING_RESTAURANTS",
//        "siteType": "O2OCommerce",
//        "amount": 50000,
//        "chargingModel": "MONTHLY",
//        "description": "This package for Online Dine in",
//        "paymentGateway": "MANUAL",
//        "curr": "INR",
//        "services": [  
//          "d93fe907-084b-4b50-8ef8-419302f56742",
//          "d93fe907-084b-4b50-8ef8-419302503742",
//          "8add2f7b-b2b4-4f61-854c-a8348f878b82",
//          "bd877eec-d7d8-4a1f-a81a-daf19a882a29",
//          "45294580-8d45-470d-944e-d357e1c9033f",
//          "5861b9de-2470-4031-ab38-f0c340941e8f",
//          "667bd2d9-8dec-4109-bc14-800df3936f9b",
//          "98a159cc-4ade-4c01-9547-f29eb3b84546"
//        ]
//      }
    
