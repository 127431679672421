import gql from 'graphql-tag'
export default gql`
query getServiceBySiteId($siteId: String!){
    getServiceBySiteId(siteId: $siteId){
      serviceName
      slugName
      serviceStatus
      serviceDes
      attachedCat
      attachedAddonCat
      availableSqr
      createdAt
      goodInventory
      badInventory
      metaKeyword
      displayOrder
      pmInventory
      purchaseItemRef
      costPrice
      tax
      taxType
      tags
      station
      addons{
        categoryName
         rule
         isRequired
         customNum
         itemIds
      }
      attachedVariations{
        serviceName
        slugName
        serviceStatus
        serviceDes
        attachedCat
        availableSqr
        displayOrder
        createdAt
        goodInventory
        badInventory
        metaKeyword
        addons{
          categoryName
           rule
           isRequired
           customNum
           itemIds
        }
        tax
        taxType
        tags
        masterProdId
        pmInventory
        purchaseItemRef
        costPrice
       isInventory
        station
        image {
          bucket
          region
          key
        }
        additionalImages {
          bucket
          region
          key
        }
        serviceRates{
          serviceType
          rate
          listRate
          status
        }
      }
      serviceRates{
        serviceType
        rate
        listRate
        status
      }
      image {
        bucket
        region
        key
      }
      additionalImages {
        bucket
        region
        key
      }
    }
  }`
//    {
    //    "siteId": "58496ae2-1ed7-4888-a168-1ba69a7a61b9"
    //  }