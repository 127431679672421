import gql from 'graphql-tag';

export default gql`
mutation updateSiteRewardSettingV2(
    $isEnabled: Boolean
    $onGroup: Boolean
    $groupId: String
    $domain: String
    $siteId: String
){
updateSiteRewardSettingV2(input:{
  isEnabled: $isEnabled
  onGroup: $onGroup
  groupId: $groupId
  siteId: $siteId
  domain: $domain
}){
  rewardType
  domain
  typeValue
  isEnabled
  updatedAt
  onGroup
  groupId
}
}`




//  {
//    "expiryDuration": 20,
//    "expiryUnit": "DAY",
//    "isEnabled": true,
//    "accuralRule": {
//      "value": 200,
//      "points": 50,
//      "isEnabled": true
//    },
//    "redemptionRule": {
//      "value": 50,
//      "points": 500,
//      "isEnabled": true
//    }
//  }