import React, { Component } from 'react';
import ErrorCom from './ErrorCom'

/**
 * Higher-order component (HOC) to wrap restricted pages
 */

import { UserContext } from '../components/context/userConfigContext'

export default function(ComposedClass,serviceName,operation) {

    
 const WithUserContext = (Component) => {
    return (props) => (
        <UserContext.Consumer>
             {value =>  <Component {...props} userData={value} />}
        </UserContext.Consumer>
    )
  }

    class AuthCustom extends Component {

        constructor(props){
            super(props)
            this.state = {
              customSiteId:'',
              username:''
            }
        }
         
        
        render() {
            let services = this.props.userData
            const getOperationObject=(obj)=>{
                let responseObject = new Object(), temp = new Object();
                if(obj.operations && obj.operations.length > 0){
                    for(let i = 0; i < obj.operations.length; i++){
                        temp = obj.operations[i]
                        responseObject[temp] = temp
                    }
                }
                return responseObject;
            }
            if(services.userRole != 'ADMIN' ){
            let arr = services.permissions && services.permissions.length > 0 ? services.permissions : []
                let masterStatus = false
                 arr.filter(function (hero) {
                 if(hero.name != null && hero.name != undefined && (hero.name == serviceName )){
                    let op = getOperationObject(hero)
                    if(op[operation]){
                        masterStatus =  true 
                    }
                 }
                })
                if(!services.enabled){
                    return <ErrorCom />
                }
            if(!masterStatus ){
                return <ErrorCom />
            }else{
                 
                return <ComposedClass {...this.props} />
            }
        }else{
            return <ComposedClass {...this.props} />
        }
    }
    }
    return WithUserContext(AuthCustom)
}