import React from 'react'
import { Button,  Col, DatePicker, Divider, Form, Input,  Row, Select, message, Modal, Icon,  } from 'antd'
import {  withApollo } from 'react-apollo';
import moment from 'moment'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import AddExtraService from './addExtraServiceToSite'
import createSiteConfig from '../../mutation/createSiteConfig'
import siteWalletRechargeByAdmin from '../../mutation/siteWalletRechargeByAdmin'
import getSiteWalletById from '../../queries/getSiteWalletById'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import subscribeSitePlanByAdmin from '../../mutation/subscribeSitePlanByAdmin'





const { Option } = Select;
const FormItem = Form.Item;
let id = 1;
let dispVal = []
class OneTimeService extends React.Component{
    constructor(props){
        super(props)
        this.state={
            specTypeDisplay: [],
            specStatus:[],
            serviceData: [],
            paymentLoading: false,
            loading: false,
            visibleM: false,
            serviceButton: false,
            sPlan:[],
            rate:[],
            selectedPL: undefined,
            selectedMS: [],
            filterdPlan: [],
            sevName: [],
        }
    }

    componentDidMount(){
        this.getServiceCharges()
        this.getRecharge()
        this.getSP()
    }
    getRecharge=()=>{
      this.props.client.query({
        query: getSiteWalletById,
        variables: {
            siteId: this.props.siteResp.siteId
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          // console.log('dataa', data)
          this.setState({
            currentB: data.getSiteWalletById,
            loading: false
          })
        })
    }
    getSP=()=>{
      this.props.client.query({
        query: getSubscriptionPlansBySiteId,
        variables: {
            siteId: "master"
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          console.log('dataa', data)
          // let sort = arr.filter((val)=>{ return val.masterService == "SITE"})
          this.setState({
            sPlan:data.getSubscriptionPlansBySiteId,
            // filterdPlan: sort,
            loading: false
          })
        })
    }
    handleCancel=()=>{
        this.setState({
          visibleM: false
      })
      }

      modalChange=()=>{
        this.setState({
          visibleM: true,
        })
      }
    getServiceCharges=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              let fdata = data.getSiteConfigServices != null && data.getSiteConfigServices != undefined && data.getSiteConfigServices.length > 0 ? data.getSiteConfigServices : []
              let arr = fdata.filter( function(hero){
                return hero.serviceName !=null && hero.serviceName != undefined && hero.serviceName == "SITE"
              })
              delete arr[0].chargesModel;
              console.log("arr",arr)

              this.setState({
                serviceData: data.getSiteConfigServices,
                defaultService:arr,
                // specTypeDisplay: ["MONTHLY"],
                  loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    handleOk=(e)=>{
      // console.log("on ok")  
      e.preventDefault();
        this.props.form.validateFields((err, values) => {
          // console.log("values",values,this.props.siteResp)
          // return
            if(!err){
              this.setState({
                paymentLoading: true
              })
      this.props.client.mutate({
        mutation: siteWalletRechargeByAdmin,
        variables: {
          siteId: this.props.siteResp.siteId,
          amt: parseInt(values.amount*100),
          currency: this.props.siteResp.curr,
          rechargeBy: "Admin",
          paymentState: "PAID_OFFLINE"
        }
    }).then(({ data }) => {
        // console.log("data response", data)
        message.success("Recharge successfully");
        this.props.form.resetFields(['amount'])
        this.getRecharge()
        this.setState({
          paymentLoading: false
        })
    })
  }
  })
    }
    remove = (k,index) => {
        const { form } = this.props;
        // can use data-binding to get
        console.log("k,index",k,index)
        const keys = form.getFieldValue('keys');
        // let arr = this.state.selectedMS
        // arr.splice(index,1)
        // this.setState({
        //   selectedMS: arr
        // })
        // We need at least one passenger
        if (keys.length === 1) {
          return;
        }
    
        // can use data-binding to set
        form.setFieldsValue({
          keys: keys.filter(key => key !== k),
        });
      };
    
      add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
          keys: nextKeys,
        });
      };
      
    
      handleTypeChange = (param, type) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        let rate = this.state.selectedPL.priceList.find(u => u.chargingModel === type);
        console.log("plan",rate)   
    let arrRate = this.state.rate
        arrRate.push(rate.rate)
        if (!('value' in this.props)) {
          // this.setState({ type });
          dispVal[param] = type
          this.setState({ 
            specTypeDisplay:dispVal,
            specStatus: dispVal,
            rate:arrRate
          });
        }    
        // this.triggerChange({ type });
      }
      handleStatusChange = (param, type) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        
        if (!('value' in this.props)) {
          // this.setState({ type });
          dispVal[param] = type
          this.setState({ 
            specTypeDisplay:dispVal,
            specStatus: dispVal,
            // rate:arrRate
          });
        }    
        // this.triggerChange({ type });
      }

      handleTypeChangeService = (param, type) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        let allPl = []
        let jkk = this.state.sPlan
        if (!('value' in this.props)) {
            let plan = this.state.sPlan.find(u => u.planId === type);
            console.log("plan",plan)
            allPl = jkk.filter((val)=>  val.masterService != plan.masterService)
            console.log("allPl",allPl)
            let arr = this.state.selectedMS
            arr.push(plan.masterService)
          // this.setState({ type });
          dispVal[param] = type
          this.setState({ 
            selectedPL:plan,
            selectedMS: arr,
            // sPlan:allPl
          });
        }    
        // this.triggerChange({ type, allPl});
      }
  
      triggerChange = (changedValue,allPl) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
          onChange(Object.assign({}, this.state, changedValue));
        }
        this.setState({ 
          sPlan:allPl
        });
      }
      chargesChange=(value)=>{
        console.log("chargesChange",value)
      }

      handleSubmit = (e) => {
        // console.log('in')
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("values::",values)
            let data = values.charges
            let chargeData = data && data.length > 0 ? data : []
            let newCharge = []
            let plId=[]
            let ids = this.props.siteResp
            chargeData.map((val,i)=>{
               let obj = {
                charges:parseInt(val.charges*100),
                chargingModel: val.chargingModel,
                masterStatus: val.masterStatus,
                serviceName: this.state.selectedMS[i],
                startedAt: val.startedAt,
                status: val.status,
                startDate: val.startDate ? moment(val.startDate).format("YYYY-MM-DD") : undefined,
                endDate: val.startEnd ? moment(val.startEnd).format("YYYY-MM-DD") : undefined,
                // endDate: val.status == "ACTIVE" ? ( (val.chargingModel == "MONTHLY" || val.chargingModel == "YEARLY") ?  moment(val.startDate).format("YYYY-MM-DD") : undefined ) : moment(val.endDate).format("YYYY-MM-DD"),
                updatedAt: val.updatedAt
               }
               newCharge.push(obj)
               plId.push(val.serviceName)
            })
            console.log("newCharge",newCharge)
            let plArr = []
            let chr = values.charges
            this.state.sPlan.map(async(ele,i)=>{
              if(plId.includes(ele.planId)){
              let amt = chr.find((o)=> o.serviceName == ele.planId  )
                ele['amount'] = amt.charges*100
                ele['chargingModel'] = amt.chargingModel
                ele['paymentGateway'] = "MANUAL"
                plArr.push(ele)
                this.createSP(ele,ids.siteId)
              }
            })
            console.log("plArr",plArr)
            

            // return
            this.props.client.mutate({
              mutation: createSiteConfig,
              variables: {
                  domain: ids.fqdn,
                  siteId: ids.siteId,
                  enabled: true,
                  siteServices: newCharge
              }
          }).then(({ data }) => {
              console.log("createSiteConfig response ::", data)
            message.success("Service Created Successfully");
            this.getRecharge()
            this.setState({
              serviceButton: true
            })
          })
        })
    }
    createSP=(data,siteId)=>{
      return new Promise((resolve,reject)=>{
        console.log("data in create SP",data,siteId)
        this.props.client.mutate({
          mutation: subscribeSitePlanByAdmin,
          variables: {
            siteId: siteId,
          planName: data.planName,
          status: data.status,
          masterService: data.masterService,
          platform: data.platform,
          siteType: data.siteType,
          amount: data.amount,
          chargingModel: data.chargingModel,
          description: data.description,
          services: data.services,
          paymentGateway: data.paymentGateway,
          curr: data.curr
          }
      }).then(({ data }) => {
        console.log("subscribeSitePlanByAdmin::",data)
        resolve(true)
      })
      })
    }
    
    render(){
        // console.log("selectedPL::",this.state.sPlan)
        let bb=  this.state.currentB && this.state.currentB.balance ? this.state.currentB.balance/100 : "0.00"
        let balane =  bb.toLocaleString(`en-${this.props.siteResp.curr == 'INR' ? 'IN' : this.props.siteResp.curr == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: this.props.siteResp.curr })
        const { getFieldDecorator, getFieldValue} = this.props.form;
        let date = new Date()
        let epo = Math.floor(date/1000)
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 24 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 20 },
            },
          };
          const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 20 },
            },
          };
          let chargesEnum = [{
            name: "PAY PER USE",
            typeValue: "PAY_PER_USE",
        },{
            name: "MONTHLY",
            typeValue: "MONTHLY",
        },{
            name: "YEARLY",
            typeValue: "YEARLY",
        }]
        let ser = [{
            serviceName: "SITE",
            typeValue: "site",
            startDate: ''
        }]
        getFieldDecorator('keys', { initialValue: [] });
            const keys = getFieldValue('keys');
            const formItems = keys.map((k, index) => (
                <Row key={k} >
                <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}}>
              <Form.Item
              className="customF" 
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ?  <span style={{fontSize:'15px',fontWeight:500,}}>Plan</span>  : ''}
                required={false}
                key={index}
              >
                {getFieldDecorator(`charges[${index}]serviceName`, {
                  // initialValue: k.serviceName,
                  rules: [
                    {
                      required: this.state.walletSetting ? true : false,
                      message: "Please input select Service or delete this field.",
                    },
                  ],
                })( <Select style={{ width: '100%' }} onChange={(e) => this.handleTypeChangeService(index, e)} placeholder="Select Service" >
                    {this.state.sPlan.map((val,i)=>{
                      // if( !this.state.selectedMS.includes(val.masterService)){
                        return(
                        <Option value={val.planId} key={i} 
                        disabled={this.state.selectedMS.includes(val.masterService)}
                        >{val.planName}</Option>
                        )
                      // }
                    })}
              </Select>)}
              </Form.Item>
              </Col>
              <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}}>
              <FormItem
              className="customF" 
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Charge Model</span> : ''}
              required={false}
              // key={'customType'+k}
              >
                {getFieldDecorator(`charges[${index}]chargingModel`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  // initialValue: index === 0 ? "PAY_PER_USE" : null,
                  rules: [
                    {
                      required: this.state.walletSetting ? true : false,
                      whitespace: true,
                      message: "Please input select Service or delete this field.",
                    },
                  ],
                })( <Select style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
                    {this.state.selectedPL ? this.state.selectedPL.priceList.map((val,i)=>{
                        // console.log("val",val)
                        return(
                        <Option value={val.chargingModel}>{val.chargingModel}</Option>
                        )
                    }) : ''}
              </Select>)}
              </FormItem>
            </Col>
            {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE' || ( this.state.specTypeDisplay[index] == 'PAY_PER_USE' && this.state.specStatus[index] == 'ACTIVE') ) &&
            <>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}}>
                    <FormItem
                    className="customF" 
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Start Date</span> : ''}
                    required={false}
                    key={index}
                    >
                        {getFieldDecorator(`charges[${index}]startDate`, {
                        initialValue: k.startDate ? k.startDate : null,
                        rules: [
                            {
                            required: this.state.walletSetting ? true : false,
                            message: "Please input select Service or delete this field.",
                            },
                        ],
                        })(<DatePicker  format="YYYY-MM-DD"></DatePicker> )}
                    </FormItem>
                    </Col>
                    
            </>
             }
              {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE' || ( this.state.specTypeDisplay[index] == 'PAY_PER_USE' && this.state.specStatus[index] == 'ACTIVE') ) &&
             <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}}>
                    <FormItem
                    className="customF" 
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>End Date</span> : ''}
                    required={false}
                    key={index}
                    >
                        {getFieldDecorator(`charges[${index}]startEnd`, {
                        // initialValue: k.charges ? k.charges/100 : null,
                        rules: [
                            {
                            required: this.state.walletSetting ? true : false,
                            message: "Please input select Service or delete this field.",
                            },
                        ],
                        })(<DatePicker></DatePicker> )}
                    </FormItem>
                    </Col>
            }
            {/* {this.state.specTypeDisplay[index] == 'MONTHLY' && this.state.specStatus[index] == 'ACTIVE'} */}
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}}>
              <FormItem
              className="customF" 
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Rate</span> : ''}
              required={false}
              // key={'customType'+k}
              >
                {getFieldDecorator(`charges[${index}]charges`, {
                  initialValue: this.state.rate.length > 0 ? this.state.rate[index]/100 : '',
                  rules: [
                    {
                      required: this.state.walletSetting ? true : false,
                      message: "Please input select Service or delete this field.",
                    },
                  ],
                })(<Input placeholder="Rate" type="Number" ></Input> )}
              </FormItem>
              </Col>
              <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}}>
              <FormItem
              className="customF" 
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Billing Status</span> : ''}
              required={false}
              >
              {getFieldDecorator(`charges[${index}]status`, {
                  initialValue: 'ACTIVE',
                  rules: [
                    {
                      required: false,
                      message: "Please input select  status or delete this field.",
                    },
                  ],
                })(<Select style={{ width: '90px' }} onChange={(e)=> this.handleStatusChange(index, e)} >
                <Option value={"ACTIVE"}>Active</Option>
                {/* <Option value={"INACTIVE"}>Inactive</Option> */}
                <Option value={"TRIAL"}>Trial</Option>
                <Option value={"FREE"}>Free</Option>
              </Select> )}
                </FormItem>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:2}} lg={{span:2}}>
            <FormItem
              className="customF" 
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? <span style={{fontSize:'15px',fontWeight:500}}>Action</span> : ''}
              required={false}
              >
                {keys.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    // className="customF"
                    type="minus-circle-o"
                    onClick={() => this.remove(k,index)}
                  />
                ) : null}
                </FormItem>
                </Col>
                <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                {getFieldDecorator(`charges[${index}]startedAt`, {
                    initialValue: epo,
                  rules: [
                    {
                      required: false,
                      message: "Please input select  status or delete this field.",
                    },
                  ],
                })}
                {getFieldDecorator(`charges[${index}]masterStatus`, {
                    initialValue: true,
                  rules: [
                    {
                      required: false,
                      message: "Please input select  status or delete this field.",
                    },
                  ],
                })}
                {getFieldDecorator(`charges[${index}]updatedAt`, {
                    initialValue: epo,
                  rules: [
                    {
                      required: false,
                      message: "Please input select  status or delete this field.",
                    },
                  ],
                })}
                </Col>
                </Row>
           
            ));
        return(
            <>
            <p style={{ textAlign: 'end' }}>
                                <Button type="dashed" style={{  marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                   <span>Balance: <b>{balane}</b></span>
                                </Button>
                        {/* </p> */}
                {/* <p style={{ textAlign: 'end' }}> */}
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} onClick={()=>this.modalChange()} >
                                   Re-Charge
                                </Button>
                        </p>
            <Form onSubmit={this.handleSubmit}>
                <Divider style={{ fontSize: "20px" }}>Recurring Services</Divider>
            {formItems}
            <FormItem {...formItemLayoutWithOutLabel}>
            <Button type="dashed" onClick={this.add}  style={{ width: '60%' }}>
                <Icon type="plus" /> Add Service
            </Button>
            </FormItem>
            <Button id="submit" type="primary" loading={this.state.loading} disabled={this.state.serviceButton} htmlType="submit" style={{ float: "left", marginBottom: 16, cursor: "pointer",}}>
                  Save
                 </Button>
           
            </Form>
            <Divider style={{ fontSize: "20px" }}>One Time Services</Divider>

            
            <AddExtraService siteData={this.props.siteResp} getRecharge={()=>this.getRecharge()}/>
            <Modal
                    title="Wallet Recharge"
                    visible={this.state.visibleM}
               
                    onCancel={this.handleCancel}
                    footer={null}
                  >
                    <Row>
                    <Form onSubmit={this.handleSubmit} style={{marginTop: '10px'}}>
                    
                    <FormItem
                                label="Recharge Amount"
                            >
                                {getFieldDecorator(`amount`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Recharge Amount between 100 To 100000",
                                        // min:3,max:6
                                    },
                                    ,
                                    {
                                      validator: (rule, value, cb) => {
                                          if (value <1) {

                                              // if (value <100) {
                                                  cb('Please enter Recharge Amount Grater than 100')
                                              // }
                                          }
                                          cb()
                                      }
                                  }
                                   ]
                                })(
                                  <Input   type= "number" placeholder="Enter Recharge Amount" style={{width:"50%"}} onChange={this.rAmount}></Input>
                                )}
                            </FormItem>
                    </Form>
                    </Row>
                    <Row>
                      <Button type="primary" onClick={this.handleOk} style={{marginTop: "20px"}} loading={this.state.paymentLoading}>Submit</Button>
                    </Row>
                  </Modal>
            </>

        )
    }
}
const WrapOneTimeService = Form.create()(OneTimeService);

export default withApollo(WrapOneTimeService)