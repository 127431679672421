import gql from 'graphql-tag'

export default gql`
query getSiteWalletById( $siteId: String!){
    getSiteWalletById(siteId: $siteId){
      id
      domain
      siteId
      balance
      currency
      createdAt
      updatedAt
    }
  }`