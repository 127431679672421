import gql from 'graphql-tag'

export default gql`
query getSiteConfigServices($domain: String!){
    getSiteConfigServices(domain: $domain){
      id
      domain
      typeValue
      serviceName
      charges
      chargesModel{
      id
      domain
      typeValue
      serviceName
      charges
      
      }
    }
  }`
//    {
    //    "domain": "recaho"
    //  }