import gql from "graphql-tag";

export default gql`
  {
    getSitesAdmin {
      id
      domain
      subdomain
      fqdn
      siteId
      internalName
      enabled
      siteType
      siteOptions
      no_of_products
      no_of_photos
      custName
      companyName
      gstNo
      fromDate
      tillDate
      createdAt
      createdBy
      courierOption
      userName
      isGroupAdmin
      lastAccessAt
      groupId
      salesPerson
      supportAccManager
      channelPartner
      stockistName
      isDemoSite
      customerLang
      partnerType
    }
  }
`;
