import createSiteActionEvent from '../mutation/createSiteActionEvent'

const SiteActionEvent=(props,variable)=>{
    return new Promise((resolve,reject)=>{
            props.client.mutate({
                mutation: createSiteActionEvent,
                variables: variable
            }).then(({ data }) => {
                resolve(data)
            })
        })
}
export default SiteActionEvent