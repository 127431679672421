

const currLocaleConverter = {
  "INR": "IN",  // this  are the country code
  "USD": "US",
  "GBP": "GB",
  "AED": "AE",
  "ZMW" : "ZM",
  "SAR" : "SA",
  "QAR" : "QA",
  "BHD": "BH",
  "NPR":"NP",
  "KWD":"KW",
  "OMR":"OM"
};

const currencySymbolsAll = {
    AFN: "؋", // Afghanistan
    ALL: "L", // Albania
    DZD: "د.ج", // Algeria
    USD: "$", // United States
    EUR: "€", // Andorra (Eurozone)
    AOA: "Kz", // Angola
    XCD: "$", // Antigua and Barbuda (East Caribbean Dollar)
    ARS: "$", // Argentina
    AMD: "֏", // Armenia
    AWG: "ƒ", // Aruba
    AUD: "A$", // Australia
    AZN: "₼", // Azerbaijan
    BSD: "$", // Bahamas
    BHD: "BD", // Bahrain
    BDT: "৳", // Bangladesh
    BBD: "$", // Barbados
    BYN: "Br", // Belarus
    BZD: "$", // Belize
    XOF: "Fr", // Benin (CFA Franc BCEAO)
    BTN: "Nu.", // Bhutan
    BOB: "Bs.", // Bolivia
    BAM: "KM", // Bosnia and Herzegovina
    BWP: "P", // Botswana
    BRL: "R$", // Brazil
    BND: "$", // Brunei
    BGN: "лв", // Bulgaria
    BIF: "Fr", // Burundi
    CVE: "$", // Cape Verde
    KHR: "៛", // Cambodia
    XAF: "Fr", // Cameroon (CFA Franc BEAC)
    CAD: "CA$", // Canada
    KYD: "$", // Cayman Islands
    CLP: "$", // Chile
    CNY: "¥", // China
    COP: "$", // Colombia
    KMF: "Fr", // Comoros
    CDF: "Fr", // Congo (Democratic Republic)
    CRC: "₡", // Costa Rica
    HRK: "kn", // Croatia
    CUP: "$", // Cuba
    CZK: "Kč", // Czech Republic
    DKK: "kr", // Denmark
    DJF: "Fr", // Djibouti
    DOP: "$", // Dominican Republic
    EGP: "£", // Egypt
    ERN: "Nfk", // Eritrea
    ETB: "Br", // Ethiopia
    FJD: "$", // Fiji
    FKP: "£", // Falkland Islands
    FOK: "kr", // Faroe Islands
    GMD: "D", // Gambia
    GEL: "₾", // Georgia
    GHS: "₵", // Ghana
    GIP: "£", // Gibraltar
    GTQ: "Q", // Guatemala
    GNF: "Fr", // Guinea
    GYD: "$", // Guyana
    HTG: "G", // Haiti
    HNL: "L", // Honduras
    HKD: "HK$", // Hong Kong
    HUF: "Ft", // Hungary
    ISK: "kr", // Iceland
    IDR: "Rp", // Indonesia
    ILS: "₪", // Israel
    INR: "₹", // India
    IQD: "ع.د", // Iraq
    IRR: "﷼", // Iran
    JMD: "J$", // Jamaica
    JPY: "¥", // Japan
    JOD: "د.ا", // Jordan
    KZT: "₸", // Kazakhstan
    KES: "Sh", // Kenya
    KPW: "₩", // North Korea
    KRW: "₩", // South Korea
    KWD: "د.ك", // Kuwait
    KGS: "сом", // Kyrgyzstan
    LAK: "₭", // Laos
    LBP: "ل.ل", // Lebanon
    LSL: "L", // Lesotho
    LRD: "$", // Liberia
    LYD: "ل.د", // Libya
    MOP: "P", // Macau
    MGA: "Ar", // Madagascar
    MWK: "MK", // Malawi
    MYR: "RM", // Malaysia
    MVR: "ރ.", // Maldives
    MRU: "UM", // Mauritania
    MUR: "₨", // Mauritius
    MXN: "Mex$", // Mexico
    MDL: "L", // Moldova
    MNT: "₮", // Mongolia
    MAD: "د.م.", // Morocco
    MZN: "MT", // Mozambique
    MMK: "K", // Myanmar
    NAD: "$", // Namibia
    NPR: "₨", // Nepal
    ANG: "ƒ", // Netherlands Antillean Guilder
    NZD: "NZ$", // New Zealand
    NIO: "C$", // Nicaragua
    NGN: "₦", // Nigeria
    MKD: "ден", // North Macedonia
    NOK: "kr", // Norway
    OMR: "ر.ع.", // Oman
    PKR: "₨", // Pakistan
    PAB: "B/.", // Panama
    PGK: "K", // Papua New Guinea
    PYG: "₲", // Paraguay
    PEN: "S/", // Peru
    PHP: "₱", // Philippines
    PLN: "zł", // Poland
    QAR: "ر.ق", // Qatar
    RON: "lei", // Romania
    RUB: "₽", // Russia
    RWF: "Fr", // Rwanda
    SHP: "£", // Saint Helena
    WST: "T", // Samoa
    STN: "Db", // São Tomé and Príncipe
    SAR: "ر.س", // Saudi Arabia
    RSD: "дин.", // Serbia
    SCR: "₨", // Seychelles
    SLL: "Le", // Sierra Leone
    SGD: "S$", // Singapore
    SBD: "$", // Solomon Islands
    SOS: "Sh", // Somalia
    ZAR: "R", // South Africa
    SSP: "£", // South Sudan
    LKR: "₨", // Sri Lanka
    SDG: "ج.س.", // Sudan
    SRD: "$", // Suriname
    SZL: "L", // Swaziland
    SEK: "kr", // Sweden
    SYP: "£", // Syria
    TWD: "NT$", // Taiwan
    TJS: "ЅМ", // Tajikistan
    TZS: "Sh", // Tanzania
    THB: "฿", // Thailand
    TOP: "T$", // Tonga
    TTD: "TT$", // Trinidad and Tobago
    TND: "د.ت", // Tunisia
    TRY: "₺", // Turkey
    TMT: "m", // Turkmenistan
    UGX: "Sh", // Uganda
    UAH: "₴", // Ukraine
    AED: "د.إ", // United Arab Emirates
    UYU: "$U", // Uruguay
    UZS: "сўм", // Uzbekistan
    VUV: "Vt", // Vanuatu
    VND: "₫", // Vietnam
    YER: "﷼", // Yemen
    ZMW: "ZK", // Zambia
    ZWL: "$", // Zimbabwe
  };



const mobilePrifix ={
  'GBP': '44',
  'INR': '91',
  "QAR" : "974",
  "NPR" :'977',
  "KWD":'965',
  "OMR":'968'
}
const roundToTwo = (num) => { return +(Math.round(parseFloat(num) + "e+2") + "e-2"); }

const returnSymbol = (props, curr) => {
  
   return `${currencySymbolsAll[curr]}${roundToTwo(props || 0)}`;
};

const returnSymbolCurrFormate = (props,curr) => {
  let price = props != null
  ? props.toLocaleString(`en-${currLocaleConverter[curr]}`, {
      style: "currency",
      currency: curr,
      minimumFractionDigits:  2,
    })
  : "";
   return price
}

const returnMobilePrifix = (number, curr) => {
  return `${mobilePrifix[curr]}${number}`;
};

//  const CentsToCurConverter = (props) => {
//     let inr = props/100;
//     return inr
// }

export {
  returnSymbol,
  returnSymbolCurrFormate
};
