import axios from 'axios';

const getStateByCountry=(param)=>{
    return new Promise((resolve,reject)=>{
      axios({
        method: "get",
        url: `https://api.countrystatecity.in/v1/countries/${param}/states`,
        headers: {
            'X-CSCAPI-KEY': 'a2VlNWlWYzV6cm1Rdk1WcURCQkpBRk13eXJZWXZpNFZyUFVsd3ExQQ=='
          }
      })
        .then(function (response) {
        //   console.log("In response :", response);
        let fData = response.data.sort((a,b)=>a.name.localeCompare(b.name))
          resolve(fData)
        })
        .catch(function (error) {
          console.log("In Error :", error);
          resolve([])
        });
    })
}
const getcitiesByStateAndCountry=(country,state)=>{
    console.log('getcitiesByStateAndCountry ::',country,state)
    return new Promise((resolve,reject)=>{
      axios({
        method: "get",
        url: `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
        headers: {
            'X-CSCAPI-KEY': 'a2VlNWlWYzV6cm1Rdk1WcURCQkpBRk13eXJZWXZpNFZyUFVsd3ExQQ=='
          }
      })
        .then(function (response) {
        //   console.log("In response :", response);
        let fData = response.data.sort((a,b)=>a.name.localeCompare(b.name))
          resolve(fData)
        })
        .catch(function (error) {
          console.log("In Error :", error);
          resolve([])
        });
    })
}
export {
    getStateByCountry,
    getcitiesByStateAndCountry
};