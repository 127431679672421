import React from 'react';
import { Form, Input, Button, Col,Row,} from 'antd';

class ChargesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            loading: false
        }
        }

        handleSubmit=()=>{
            this.props.form.validateFields(async(err, values) => {
                console.log("values",values)
            })
        }
        handleclick=()=>{
            this.props.onCancel()
        }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
// console.log("Data",this.props.data.chargesModel)
let charges= this.props.data.chargesModel ? this.props.data.chargesModel : []
        getFieldDecorator('keys', { initialValue: charges });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
          <Form.Item
          //   {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
            label={index === 0 ? 'Charges' : ''}
            required={false}
            key={k}
            className="menuFlex"
            // style={{ display: 'flex'}}
          >
            {getFieldDecorator(`names[${index}]serviceName`, {
             initialValue: k.serviceName,
              rules: [
                {
                  required: false,
                  message: "Please input passenger's name or delete this field.",
                },
              ],
            })(<Input placeholder="passenger name" disabled style={{ width: '30%', marginRight: 8 }} />)}
              {getFieldDecorator(`names[${index}]rate`, {
              // validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.charges/100,
              rules: [
                {
                  
                  required: false,
                  message: "Please input Rate",
                }
              ],
            })(<Input placeholder="Rate"  style={{ width: '30%', marginRight: 8 }} />)}
             {getFieldDecorator(`names[${index}]typeValue`, {
              // validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.typeValue,
              rules: [
                {
                  required: false,
                  message: "Please input Rate",
                },
              ],
            })(<Input placeholder="Rate" style={{ width: '30%', marginRight: 8, display: "none" }} />)}
          </Form.Item>
        ));
        return (
            <div>
                 <Row gutter={16}>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:18}} xl={{span:18}} >
                   {formItems}
                    </Col>
                    </Row>
                    <Row gutter={16}>
                    <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Save
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>
                        </Row>
            </div>
        );
    }
}
const WrappedChargesModal = Form.create({ name: "Services_Edit" })(
    ChargesModal
  );
export default WrappedChargesModal;