import React from 'react'
import App from '../../App'
import getSitePagesById from '../../queries/getStaticPages';
import GetSignature from '../../queries/getflorasignature'
import { graphql, compose,withApollo } from 'react-apollo';
import { Table,Tag,Button, Row,message,Popconfirm,Modal,Input,Icon } from 'antd';
import {Link} from 'react-router-dom';
import DeletePage from '../../mutation/deleteSitePage'
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Highlighter from 'react-highlight-words';
import getSitePagesByAdmin from '../../queries/getSitePagesByAdmin'
class StaticPage extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            data:[],
            pages:[],
            modelData: '',
            fullImg: false,
            fullImage:"",
            defaultFilter:["HEADERS"],
            tags:[
                { text: 'Contacts', value: 'CONTACTS' },
                { text: 'Contents', value: 'CONTENTS' },
                { text: 'Custom', value: 'CUSTOM' },
                { text: 'Features', value: 'FEATURES' },
                { text: 'Footers', value: 'FOOTERS' },
                { text: 'Form', value: 'FORM' },
                { text: 'Headers', value: 'HEADERS' },
                { text: 'Pricing', value: 'PRICING' },
                { text: 'Teams', value: 'TEAMS', },
                { text: 'Testimonials', value: 'TESTIMONIALS' },
                { text: 'Call to Action', value: 'CallToAction' },
                { value: 'COVERS', text: 'Covers' },
                { value: 'BLOG', text: 'Blog' },
                { value: 'ECOMMERCE', text: 'E-commerce' },
                { value: 'MAGAZINE', text: 'Magazine' },
                { value: 'PROJECTS', text: 'Projects' },
                { value: 'COUNTERS', text: 'Counters' },
                { value: 'DOWNLOAD', text: 'Download' },
                { value: 'EXPLORE', text: 'Explore' },
                { value:'FAQ', text:'FAQ'},
                { value:'GALLERY', text:'Gallery'},
                { value:'MAPS', text:'Maps'},
                { value:'CUSTOMERS', text:'Customers'},
                { value:'PORTFOLIO', text:'Portfolio'},
                { value:'STEPS', text:'Steps'},
                { value:'SERVICE', text:'Service'},
                { value:'SIGNUP', text:'Sign up'},
                { value:'SUBSCRIBE', text:'Subscribe'},
                { value:'VIDEO', text:'Video'},
                { value:'ADMIN', text:'Admin'},
                { value:'INDUSTRY', text:'Industry'},
                { value:'POLICIES', text:'Policies'},
                { value:'LANDING_PAGE', text:'Landing Page'},
                { value:'TEMPLATES', text:'Templates'},
                { value:'PLUGINS', text:'Plugins'},
                { value:'COMPONENTS', text:'Component'},
                { value:'SINGLE_PAGE_SITE', text:'Single Page Site'},
                { value:'DATA', text:'Data'},

      


            ],
            data11:[],
            searched:false
        }
    }

  runQuery = (nextToken, limit) => {
    // console.log(nextToken, limit)
    this.props.client.query({
      query: getSitePagesByAdmin,
      fetchPolicy: 'network-only',
      variables: {
        nextToken: nextToken,
        limit: limit
      }
    })
      .then(({ data }) => {
        if (data && data.getSitePagesByAdmin) {
          // console.log("data...getSitePagesByAdmin", data)
          let data1 = this.state.data.concat(data.getSitePagesByAdmin.items)
          // console.log("data...full", data1)
          let nextToken1 = data.getSitePagesByAdmin.nextToken
          this.setState({
            data: data1
          }, () => {
            if (nextToken1 != null && nextToken1 != undefined && nextToken1 != '') {
              this.runQuery(nextToken1, 70)
            }
          })
        }

      })
      .catch(err => {
        console.log(`Error : ${JSON.stringify(err)}`)

      })
  }


    componentDidMount(){
      this.runQuery('',70)

        let arr = this.state.tags
        arr.sort(function (a, b) {
            var nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
        this.setState({
            tags: arr
        })



        this.props.client.query({
            query:GetSignature ,
            fetchPolicy: 'network-only' ,
            })
            .then(({ data }) => {
            this.setState({modelData:JSON.parse(data.getFroalaSignature )});
            })
            .catch(err => {
                console.log(`Error : ${JSON.stringify(err)}`)
            
            })
    }

    // static getDerivedStateFromProps(nextProps){
    //     if(nextProps.data.getSitePagesBySiteId){
            
    //         return{
    //             data:nextProps.data.getSitePagesBySiteId.items
    //         }
    //     }
    //         return null
    // }

          deletePage = (name) => {
            let array1 = this.state.searched ? this.state.data11 : this.state.data
            let i = array1.findIndex(x => x.pageName == name)
            array1.splice(i, 1);
            if (this.state.searched) {
              this.setState({
                data11: array1
              })
            }
            else {
              this.setState({
                data: array1
              })

            }


          }    
    
    handleDelete = (name) =>{
            this.props.deletePage({
                variables:{
                    // siteId: this.props.customSiteId,
                    pageName:name
                }
            })
            .then(({ data }) => {
                if(data.deleteSitePage && data.deleteSitePage.id){
                    this.deletePage(data.deleteSitePage.pageName)
                    message.success('Deleted Successfully')
                }
            })
            .catch(res => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)            
            });
      }

      tableOnchange=(pagination, filters)=>{
          this.setState({
              defaultFilter:filters.tags
          })
      }

      filter=(value,record)=>{
         let data=record.tags.find(x => x == value) 
        //  console.log(data)
      }

      
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      }
    
      handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
      }

      
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
        id="search"
        // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          ref={node => { this.searchInput = node; }}
          placeholder={`Search Page Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
        id="search"
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  onchangeSearch=(val)=>{
    if(val!='' && val!=null){
      let vall=val.toLowerCase()
      // console.log("vall..",vall)
      let arr = this.state.data.filter((data) => {
        if (data.value && data.value.toLowerCase().includes(vall) || data.pageName && data.pageName.toLowerCase().includes(vall)) {
            return data
        }
    })
    this.setState({
        data11: arr,
        searched: true
    })

    }
    else{
      this.setState({
        searched:false,
        data11:[]
      })
    }
  }

  
  
    render(){
// console.log("data...",this.state.data)
        const columns=[
            {
                title: 'Page Name',
                dataIndex: 'displayName',
                key: 'displayName',
                width:'20%',
            ...this.getColumnSearchProps('pageName'),

                // filters: this.state.tags,
                // filterSearch: true,
                // onFilter: (value, record) => {
                //     if (record.tags != null) {
                //         let data= record.tags.find((x,ii) => x == value)
                //         if(data!=undefined){
                //             return data
                //         }
                      
                        
                //     }
                // },
            },
            {
                title: 'Page Tag',
                // dataIndex: 'tags',
                key: 'tags',
                width: '10%',
                filters: this.state.tags,
                filterSearch: true,
                // filteredValue:this.state.defaultFilter || null,
                render: (data) => {
                    // console.log("data..",data)
                    return (
                        <div>
                        {data && data.tags && data.tags.length>0 ? data.tags.map((val)=>{
                            return(
                                <Tag color="green" key={val}>
                                    {val}
                                </Tag> 
                            )
                        }):
                        <span>--</span>
                        }
                        </div>
                    )
                },
                onFilter: (value, record) => {
                    if (record.tags != null) {
                        return record.tags.find(x => x == value)
                    }
                },
            },
            {
                title: 'Display',
              //   dataIndex: 'pageFor',
                key: 'pageFor',
                width: '10%',
                render: (data)=>{
                    let check = data.pageFor == null ? "BOTH" : data.pageFor
                    return(
                        <span>{check == "MOB" ? "Mobile View" : check == "WEB" ? "Web View" : check}</span>
                    )
                }
            },
            {
                title: 'Page Type',
              //   dataIndex: 'pageType',
                key:'pageType',
                width:'8%',
                render: (data)=>{
                  return(
                      <span>{data.pageType == null ? "FROALA" : data.pageType}</span>
                  )
                }
            },
            {
                title: 'Preview Image',
              //   dataIndex: 'pageType',
                key:'pageType',
                width:'40%',
                align:'center',
                render: (record) => {
                    return record.image != null ? (
                      <img
                        src={process.env.REACT_APP_S3_URL + '' + record.image.key}
                        style={{ width: '100%',cursor:'pointer' }}
                        onClick={()=>{this.setState({fullImg:true,fullImage: process.env.REACT_APP_S3_URL + '' + record.image.key})}}
                      />
                    ) : (
                      '--'
                    );
                  }
            },
            {
                title: 'Action',
                key: 'action',
                width:'12%',
                align:'center',
                render: (data) => {
                        return (
                            <span>
                                {/* <Row>
                                    <Col span={4}> */}
                                        <Link to={{pathname:"/edit-site-pages",
                                            state:{currentPage:data,imgData:this.state.modelData}
                                            }}>
                                            <Button type="primary" icon="edit" style={{ cursor: "pointer"}}/>
                                        </Link>
                                    {/* </Col>
                                    <Col span={4}> */}
                                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.pageName)}>
                                        <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
                                    </Popconfirm>
                                    {/* </Col>
                                </Row> */}
                            </span>
                        )
                    }
                }
        ]
        return(
            <App header={"Manage Pages"}>
                <Row>
                    <Link to={{pathname:"/create-site-pages",state:{imgData:this.state.modelData}}} >
                        <Button type="primary" style={{ float:"right", marginBottom: 16, cursor: "pointer", color:"#fff" }}>
                            Create New Page
                        </Button>
                    </Link>
                </Row>
                <Input onChange={(e)=>{this.onchangeSearch(e.target.value)}} style={{marginLeft:'10px',width:'25%',marginBottom:'5px',borderRadius:'5px'}} placeholder="Search for Page"/>
                <Table columns={columns}  dataSource={this.state.searched?this.state.data11: this.state.data} onChange={this.tableOnchange}
                pagination={{ defaultPageSize: 20 }}
                />
                                
                                <Modal
                                centered
                                  visible={this.state.fullImg}
                                  onOk={()=>{this.setState({fullImg: false,fullImage: "",fullImg:false})}}
                                  onCancel={()=>{this.setState({fullImg: false,fullImage: "",fullImg:false})}}
                                  footer={null}
                                  width={800}
                                >
                                  <div>
                                {this.state.fullImage != '' ?
                                 
                                <div style={{height:'550px' }}>
                                <PinchZoomPan doubleTapBehavior="zoom" position="center" 
                                // zoomButtons={false}
                                >
                                   <img src={this.state.fullImage} 
                                     //  style={{width:"100%", height:"auto"}} 
                                   />
                               </PinchZoomPan>
                             </div>
                                :
                                <h4>No Image Found !</h4>
                                }
                                  </div>
                                 </Modal>

            </App>
        )
    }
}

const WrapStaticPages = compose(
    withApollo,
    graphql(getSitePagesById,{
        options: props => ({
            fetchPolicy: "network-only"
        })
    }),
    graphql(DeletePage,{
        name:'deletePage'
    })
   
)(StaticPage)
export default WrapStaticPages